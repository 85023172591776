import React from "react";
import "./PrivacyPolicy.scss";


const PrivacyPolicy = () => {
  return (
    <div>
      <div className="PP-main">
        <div className="PP-outer">
          <div className="PP-inner">
            <div className="head">
              <h1>Privacy Policy</h1>
            </div>
            <div className="body">
              <div className="part">
                <h5>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h5>
                <p>
                  When you purchase something from our store, as part of the
                  buying and selling process, we collect the personal
                  information you give us such as your name, address and email
                  address.
                </p>
                <p>
                  When you browse our store, we also automatically receive your
                  computer’s internet protocol (IP) address in order to provide
                  us with information that helps us learn about your browser and
                  operating system.
                </p>
                <p>
                  Email marketing (if applicable): With your permission, we may
                  send you emails about our store, new products and other
                  updates.
                </p>
              </div>
              <div className="part">
                <h5>SECTION 2 - CONSENT</h5>
                <div className="subpart">
                  <h6>How do you get my consent?</h6>
                  <p>
                    When you provide us with personal information to complete a
                    transaction, verify your credit card, place an order,
                    arrange for a delivery or return a purchase, we imply that
                    you consent to our collecting it and using it for that
                    specific reason only.
                  </p>
                  <p>
                    If we ask for your personal information for a secondary
                    reason, like marketing, we will either ask you directly for
                    your expressed consent, or provide you with an opportunity
                    to say no.
                  </p>
                </div>
                <div className="subpart">
                  <h6>How do I withdraw my consent?</h6>
                  <p>
                    If after you opt-in, you change your mind, you may withdraw
                    your consent for us to contact you, for the continued
                    collection, use or disclosure of your information, at
                    anytime, by contacting us at hello@kushals.com
                  </p>
                  <p>
                    Kushal's Fashion Jewellery, #54/2 2nd Floor, Above SBI Bank,
                    Police Station Road, Basavangudi, Bangalore Karnataka IN
                    560004
                  </p>
                </div>
              </div>
              <div className="part">
                <h5>SECTION 3 - DISCLOSURE</h5>
                <p>
                  We may disclose your personal information if we are required
                  by law to do so or if you violate our Terms of Service.
                </p>
              </div>
              <div className="part">
                <h5>SECTION 4 - SHOPIFY</h5>
                <p>
                  Our store is hosted on Shopify Inc. They provide us with the
                  online e-commerce platform that allows us to sell our products
                  and services to you.
                </p>
                <p>
                  Your data is stored through Shopify’s data storage, databases
                  and the general Shopify application. They store your data on a
                  secure server behind a firewall.
                </p>
                <div className="subpart">
                  <h6>Payment:</h6>
                  <p>
                    If you choose a direct payment gateway to complete your
                    purchase, then Shopify stores your credit card data. It is
                    encrypted through the Payment Card Industry Data Security
                    Standard (PCI-DSS). Your purchase transaction data is stored
                    only as long as is necessary to complete your purchase
                    transaction. After that is complete, your purchase
                    transaction information is deleted.
                  </p>
                  <p>
                    All direct payment gateways adhere to the standards set by
                    PCI-DSS as managed by the PCI Security Standards Council,
                    which is a joint effort of brands like Visa, MasterCard,
                    American Express and Discover.
                  </p>
                  <p>
                    PCI-DSS requirements help ensure the secure handling of
                    credit card information by our store and its service
                    providers.
                  </p>
                  <p>
                    For more insight, you may also want to read Shopify’s Terms
                    of Service <br /> (https://www.shopify.com/legal/terms) <br /> or Privacy
                    Statement <br /> (https://www.shopify.com/legal/privacy).
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
