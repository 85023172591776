import React, { useState, useRef, useEffect } from "react";
import "./Auth.scss";
import Login from "../../assets/login.png";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import { useFetch } from "../../hooks/api_hook";
import { useNavigate } from "react-router-dom";
import axios from "axios";

var token;
const Auth = () => {
  const [showOTP, setShowOTP] = useState(false);
  const mobileNumberRef = useRef(null);
  const { data: users } = useFetch("/auth/users");
  const [inputHandler, setInputHandler] = useState({
    phoneNumber: "",
    otp: "",
  });

  const navigate = useNavigate();

  const changeInputHandler = (e) => {
    let { name, value } = e.target;
    if (name === "phoneNumber" || name === "otp") {
      value = value.replace(/[^0-9]/g, "");
    }
    setInputHandler({ ...inputHandler, [e.target.name]: value });
  };

  const handleContinueClick = () => {
    const mobileNumber = mobileNumberRef.current.value;
    if (mobileNumber.length === 10) {
      setShowOTP(true);
      const userExists = users?.some(
        (item) => item?.phoneNumber === inputHandler.phoneNumber
      );

      if (userExists) {
        onSendOtp();
      } else {
        onSendOtp();
      }
    } else {
      alert("Please enter a valid 10-digit mobile number.");
    }
  };

  const onSendOtp = async () => {
    try {
      const data = await axios.post("https://kanha-art-jewellery-drtc.onrender.com/auth/sendOtp", {
        phoneNumber: inputHandler.phoneNumber,
      });
      token = data.data.token;
      if (data.data.success) {
        toast.success("OTP Sent successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      } else {
        toast.error("Please enter valid phoneNumber number", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error);
      // toast.error(`${error.response.data.message}`, {
      //   position: "bottom-right",
      //   autoClose: 8000,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "dark",
      // });
    }
  };

  const onSignUp = async () => {
    try {
      // event.preventDefault();
      if (token) {
        const { data } = await axios.post(
          "https://kanha-art-jewellery-drtc.onrender.com/auth/verifyOtp",
          {
            otp: inputHandler.otp,
            token: token,
          }
        );
        if (data.success) {
          const { data } = await axios.post(
            "https://kanha-art-jewellery-drtc.onrender.com/auth/signup",
            {
              phoneNumber: inputHandler.phoneNumber,
            }
          );
          if (data.success) {
            onLogin();
            if (inputHandler.checkbox == 0) {
              forgotOnClose();
            }
          } else {
            toast.error(`singup  ${data.message}`, {
              position: "bottom-right",
              autoClose: 8000,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
            });
          }
        } else {
          toast.error(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      } else {
        toast.error("Please enter a valid OTP", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const onLogin = async () => {
    try {
      // event.preventDefault();
      if (token) {
        const { data } = await axios.post("https://kanha-art-jewellery-drtc.onrender.com/auth/login", {
          phoneNumber: inputHandler.phoneNumber,
          otp: inputHandler.otp,
          token: token,
        });
        if (data.success) {
          localStorage.setItem("auth_token", token);
          localStorage.setItem("user", JSON.stringify(data.data));
          localStorage.setItem("user_id", data.data._id);
          if (inputHandler.checkbox == 0) {
            forgotOnClose();
          }
          navigate("/");
          window.scrollTo(0, 0);
        } else {
          toast.error(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      } else {
        toast.error("Please enter a valid phoneNumber number", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(`error`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleLogin = () => {
    const userExists = users?.some(
      (item) => item?.phoneNumber === inputHandler.phoneNumber
    );
    if (userExists) {
      onLogin();
    } else {
      onSignUp();
    }
  };

  const forgotOnClose = () => {
    window.onbeforeunload = function (e) {
      localStorage.clear();
    };
  };

  return (
    <div>
      <div className="auth-main">
        <div className="auth-outer">
          <div className="auth-inner row">
            <div className="section-1 col-md-6">
              <div className="sec-1-outer">
                <div className="head">
                  <h2>
                    Login <span>or</span> Signup
                  </h2>
                </div>
                <div className="mid">
                  {!showOTP && (
                    <div className="mid-part-1">
                      <p>Mobile Number</p>
                      <input
                        type="number"
                        min="0"
                        max="10"
                        value={inputHandler.phoneNumber}
                        name="phoneNumber"
                        onChange={changeInputHandler}
                        placeholder="Enter Your Mobile Number"
                        ref={mobileNumberRef}
                      />
                    </div>
                  )}
                  {showOTP && (
                    <div className="mid-part-1">
                      <p>OTP</p>
                      <input
                        type="number"
                        min="0"
                        max="6"
                        name="otp"
                        value={inputHandler.otp}
                        onChange={changeInputHandler}
                        placeholder="Enter OTP"
                      />
                    </div>
                  )}
                  <div className="mid-part-2">
                    <input type="checkbox" />
                    <label>Remember Me</label>
                  </div>
                  <div className="mid-part-3">
                    <p>
                      By continuing, I agree to the
                      <Link to="/termsAndConditions"> Terms of Use </Link> &
                      <Link to="/privacyPolicy"> Privacy Policy</Link>
                    </p>
                  </div>
                </div>
                {!showOTP && (
                  <div className="bottom">
                    <div className="cont-outer">
                      <button onClick={handleContinueClick}>Continue</button>
                    </div>
                  </div>
                )}
                {showOTP && (
                  <div className="bottom">
                    <div className="cont-outer">
                      <button onClick={handleLogin}>Continue</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="section-2 col-md-6">
              <div className="sec-2-outer">
                <img src={Login} alt="" />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Auth;
