import React, { useState, useEffect } from "react";
import "./EditBlog.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const EditBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [readingTime, setReadingTime] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [counter, setCounter] = useState(2);
  const [image, setImage] = useState();
  const [fields, setFields] = useState([{ id: 1, title: "", content: "" }]);
  const [blog, setBlog] = useState();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/blog/getBlogById/${id}`
        );
        if (response.data.success) {
          setBlog(response.data.data);
          const newFields = response.data.data.description.map(
            (item, index) => ({
              id: index + 1,
              title: item?.title || "",
              content: item?.content || "",
            })
          );
          setFields(newFields);
          setTitle(response.data.data.title || "");
          setReadingTime(response.data.data.readingTime || "");
          setTags(response.data.data.tags || []);
          setImage(response.data.data.image || "");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };
    fetchBlog();
  }, [id]);

  const handleInputChange = (id, event) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleReadingTimeChange = (event) => {
    setReadingTime(event.target.value);
  };

  const handleAddFields = () => {
    setFields([...fields, { id: counter, subtitle: "", content: "" }]);
    setCounter(counter + 1);
  };

  const handleRemoveField = (id) => {
    const filteredFields = fields.filter((field) => field.id !== id);
    setFields(filteredFields);
  };

  const handleInputChangetag = (e) => {
    setTagInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      e.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagRemove = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  const handleUpdateBlog = async () => {
    try {
      const response = await axios.put(
        `https://kanha-art-jewellery-drtc.onrender.com/blog/updateBlogById/${id}`,
        {
          title: title,
          description: fields,
          image: image,
          readingTime: readingTime,
          tags: tags,
        }
      );

      if (response.data.success) {
        toast.success("Blog updated successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      } else {
        toast.error("Failed to update blog", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Error updating blog", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <div className="updateProduct">
      <section>
        <div className="form_data">
          <div className="form_heading">
            <h1>Update Resource Center</h1>
          </div>
          <form className="form_wrapper">
            <div className="form_input">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                id="title"
                name="title"
                placeholder="Title"
              />
            </div>
            {fields.map((field, index) => (
              <div key={index} className="add-inner col-12">
                <div className="add-sub-inner">
                  <div className="name-outer">
                    <label htmlFor="color">Description:</label>
                    {index >= 0 && (
                      <div onClick={() => handleRemoveField(field.id)}>
                        <i class="bi bi-trash3" style={{ color: "red" }}></i>{" "}
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    name="title"
                    value={field.title}
                    onChange={(e) => handleInputChange(field.id, e)}
                    placeholder="Sub Title"
                  />
                  <input
                    type="text"
                    name="content"
                    value={field.content}
                    onChange={(e) => handleInputChange(field.id, e)}
                    placeholder="content"
                  />
                </div>
              </div>
            ))}

            <button onClick={handleAddFields} className="add-field">Add Field</button>

            <div className="form_input">
              <label htmlFor="readingTime">Estimate Reading Time</label>
              <input
                type="text"
                onChange={handleReadingTimeChange}
                value={readingTime}
                id="readingTime"
                name="readingTime"
                placeholder="readingTime"
              />
            </div>
            <div className="form_input">
              <label htmlFor="productImage">Resource Image</label>
              <input
                type="file"
                id="productImage"
                onChange={(e) => setImage(e.target.files[0])}
                name="productImage"
                required
              />
              <img
                src={image}
                width={"80px"}
                height={"100px"}
                alt="Current Image"
              />
            </div>
            <div className="tags">
              <label>Tags</label>
              <div className="tags-input-container">
                <input
                  type="text"
                  className="tags-input"
                  placeholder="Please enter your tags here"
                  value={tagInput}
                  onChange={handleInputChangetag}
                  onKeyDown={handleInputKeyDown}
                />
              </div>
              <div className="tags-list">
                {tags.map((tag, index) => (
                  <div key={index} className="tag">
                    {tag}
                    <button
                      className="tag-remove"
                      onClick={() => handleTagRemove(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="btn"
              onClick={handleUpdateBlog}
              style={{
                backgroundColor: "#005C4B",
              }}
            >
              Update Resource Center
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default EditBlog;
