
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { thunk } from "redux-thunk";

import { cartReducer } from "./reducers/cartReducer";
import { likeReducer } from "./reducers/likeReducer";

const reducer = combineReducers({
  cart: cartReducer,
  like: likeReducer,
});

const middleware = [thunk];

let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;