import * as actionTypes from "../constants/cartConstants";

export const addToCart = (id) => async (dispatch) => {
  try {
    console.log(id);
    dispatch({ type: actionTypes.ADD_TO_CART, payload: { id } });
  } catch (error) {
    console.log("Error while calling cart API");
  }
};

export const removeFromCart = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: id,
  });
};
