import { useState, useEffect } from "react";
import React from "react";
import "./Footer1.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Footer1 = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/allCategory`
      );
      const data = await response.json();
      setCategories(data.data);

      return data;
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = async (id) => {
    setSelectedCategory(id);
    const data = await fetchCategoryData(id);
    setCategoryData(data.data);
  };

  const handlexyz = async (categoryName) => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getCategoryByCategoryName/${categoryName}`
      );
      const data = await response.json();

      window.scrollTo(0, 0);
      navigate(`/shop/${categoryName}`, { state: { idData: data } });
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  const fetchCategoryData = async (id) => {
    const response = await fetch(
      `https://kanha-art-jewellery-drtc.onrender.com/category/findCategoryByCategoryId/${id}`
    );
    const data = await response.json();
    return data;
  };
  return (
    <div>
      <div className="footer1-main">
        <div className="footer1-outer">
          <div className="footer1-inner">
            <div className="sub row">
              <div className="section-1 col-md-4">
                <h1
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  Kanha Art Jewellery
                </h1>
              </div>
              <div className="section-2 col-md-8 row">
                <div className="part col-md-4 col-6">
                  <div className="head">
                    <h6>CATEGORY</h6>
                  </div>
                  <div className="body">
                    {categories?.slice(0, 6).map((category) => (
                      <li
                        className="item-category"
                        style={{ listStyle: "none", padding: "2px" }}
                        key={category._id}
                      >
                        <Link
                          aria-current="page"
                          to="/"
                          onMouseEnter={() => {
                            handleCategoryClick(category._id);
                            setIsCategoryVisible(true);
                          }}
                          onMouseLeave={() => {
                            setIsCategoryVisible(false);
                          }}
                          onClick={() => {
                            handlexyz(category?.categoryName);
                            window.scrollTo(0, 0);
                          }}
                          class="nav-link"
                        >
                          {category?.categoryName}
                        </Link>
                      </li>
                    ))}
                  </div>
                </div>
                <div className="part col-md-4 col-6">
                  <div className="head">
                    <h6>COMPANY</h6>
                  </div>
                  <div className="body">
                    <Link
                      to="/about"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      About Us
                    </Link>
                    <Link
                      to="/termsAndConditions"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Term of Services
                    </Link>
                    <Link
                      to="/privacyPolicy"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to="/returnPolicy"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Return Policy
                    </Link>
                  </div>
                </div>
                <div className="part col-md-4 col-6">
                  <div className="head">
                    <h6>SUPPORT</h6>
                  </div>
                  <div className="body">
                    <Link
                      to="/resourceCenter"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Knowledge Center
                    </Link>
                    <Link
                      to="/contact"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Support Forum
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      Whatsapp
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-3 col-12">
              <div className="copyright">
                <p>Copyright © 2024 by Kanha Art Jewellery.</p>
                <p>
                  Powered by: <span>e-Hawkers Marketing LLP, Indore</span>.
                </p>
              </div>

              <ul className="icons">
                <li>
                  <a href="https://twitter.com/kanhajewellery">
                    <i class="bi bi-twitter"></i>
                  </a>
                </li>

                <li>
                  <a href="https://www.facebook.com/kanhaArtJewellery/">
                    <i class="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kanhaartjewellery/">
                    <i class="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer1;
