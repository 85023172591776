import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateProduct.scss";
import axios from "axios";

const CreateProduct = () => {
  const navigate = useNavigate();
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryList, setSelectedCategoryList] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/category/allCategory"
        );
        setAllCategory(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const [formDataData, setFormData] = useState({
    title: "",
    description: "",
    image: [],
    price: "",
    sellprice: "",
    colors: [],
    polish: [],
    DesignNo: "",
    returnDays: "",
    baseMetal: "",
    Stone: "",
    stock: false,
    size: [],
    ProductWeight: "",
    productDetail: "",
    material: [{ title: "", count: "" }],
    NecklaceStyle: "",
    categoryName: "",
    categoryList: "",
    categoryListItem: "",
    // categoryId: "",
    // categoryListId: "",
    // categoryListItemId: "",
  });

  const handleChange = (e) => {
    const { name, checked, value, id } = e.target;
    if (name === "stock") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      const { name, checked, value, id } = e.target;
      if (name === "categoryName") {
        if (value === "Other") {
          setIsOtherSelected(true); // Set flag to true when "Other" is selected
        } else {
          setSelectedCategory(value);
          setFormData((prevState) => ({
            ...prevState,
            categoryName: value,
          }));
          setIsOtherSelected(false); // Reset flag when a category is selected
        }
      } else if (name === "categoryList") {
        setSelectedCategoryList(value);
        setFormData((prevState) => ({
          ...prevState,
          categoryList: value,
        }));
      } else if (name === "categoryListItem") {
        setFormData((prevState) => ({
          ...prevState,
          categoryListItem: value,
        }));
      } else if (name === "image" || name === "colors" || name === "polish") {
        const arrayValue = value.split(",").map((item) => item.trim());
        setFormData((prevState) => ({
          ...prevState,
          [name]: arrayValue,
        }));
      } else if (name === "size") {
        const arrayValue = value.split(",").map(Number);
        setFormData((prevState) => ({
          ...prevState,
          [name]: arrayValue,
        }));
      } else {
        const newValue =
          name === "price" || name === "sellprice" ? Number(value) : value;
        setFormData((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
      }
    }
  };

  const removeData = () => {
    setFormData((prevState) => {
      const updatedMaterial = [...prevState.material.slice(1)]; // Remove the first element
      return { ...prevState, material: updatedMaterial };
    });
  };

  const [url, setUrl] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(formDataData.image);

    // Append each image file to FormData
    for (let i = 0; i < formDataData.image.length; i++) {
      formData.append("images", formDataData.image[i]);
      console.log(formData);
    }

    try {
      const response = await axios.post(
        `http://localhost:8008/product/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response?.data);

      // setUrl(response?.data?.urls);
      if (response?.data?.success) {
        // setUrl(response?.data?.urls);
        // setFormData({ ...formDataData, image: response?.data?.urls });
        // console.log(formDataData);
        setUrl(response?.data?.urls);
      } else {
        // toast.error("Opp!! Somthing Wrong Please Try Again", {
        //   position: "bottom-right",
        //   autoClose: 4000,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "light",
        // });
        return;
      }
    } catch (error) {
      console.log(formData);
      // console.error(error);
      return;
    }
    e.preventDefault();
    // await removeData();
    try {
      const response = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/product/createProduct",
        formData
      );
      // navigate("/dashboard");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };
  const backToDashboard = () => {
    navigate("/dashboard");
    window.scrollTo(0, 0);
  };

  const addMaterial = () => {
    const currentTitle = formDataData.material[0].title;
    const currentCount = Number(formDataData.material[0].count);
    const newMaterial = { title: currentTitle, count: currentCount };
    setFormData((prevState) => ({
      ...prevState,
      material: [...prevState.material, newMaterial],
    }));
    setFormData((prevState) => ({
      ...prevState,
      material: prevState.material.map((material, i) =>
        i === 0 ? { ...material, title: "", count: "" } : material
      ),
    }));
  };

  const handleMaterialChange = (e, index, field) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      material: prevState.material.map((material, i) =>
        i === index ? { ...material, [field]: value } : material
      ),
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formDataData, image: files });
  };

  return (
    <div className="createProduct">
      <section>
        <div className="form_data">
          <div className="cross" onClick={backToDashboard}>
            <i className="bi bi-file-x-fill"></i>
          </div>
          <div className="form_heading">
            <h1>Create Product</h1>
          </div>
          <form id="createProductForm" onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formDataData.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                name="description"
                value={formDataData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form_input">
              <label for="productImage">Resource Image</label>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
                id="productImage"
                name="productImage"
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="price">Price:</label>
              <input
                type="number"
                id="price"
                name="price"
                value={formDataData.price}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="sellprice">Sell Price:</label>
              <input
                type="number"
                id="sellprice"
                name="sellprice"
                value={formDataData.sellprice}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="colors">Colors (comma-separated):</label>
              <input
                type="text"
                id="colors"
                name="colors"
                value={formDataData.colors.join(", ")}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="DesignNo">Design No:</label>
              <input
                type="number"
                id="DesignNo"
                name="DesignNo"
                value={formDataData.DesignNo}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="ReturnDays">Return Days</label>
              <input
                type="number"
                id="ReturnDays"
                name="returnDays"
                value={formDataData.returnDays}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="polish">Polish (comma-separated):</label>
              <input
                type="text"
                id="polish"
                name="polish"
                value={formDataData.polish.join(", ")}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="Base Metal">Base Metal</label>
              <input
                type="text"
                id="Base Metal"
                name="baseMetal"
                value={formDataData.baseMetal}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="Stone">Stone</label>
              <input
                type="text"
                id="Stone"
                name="Stone"
                value={formDataData.Stone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input check-main">
              <label htmlFor="Stock">Stock</label>
              <div className="check-outer">
                <input
                  type="checkbox"
                  id="Stock"
                  name="stock"
                  checked={formDataData.stock}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form_input">
              <label htmlFor="size">Size (comma-separated):</label>
              <input
                type="text"
                id="size"
                name="size"
                value={formDataData.size.join(", ")}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="ProductWeight">Product Weight:</label>
              <input
                type="text"
                id="Product Weight"
                name="ProductWeight"
                value={formDataData.ProductWeight}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="productDetail">Product Details:</label>
              <input
                type="text"
                id="productDetail"
                name="productDetail"
                value={formDataData.productDetail}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input material">
              <div className="form-outer">
                <label>Material:</label>
                {formDataData.material.map((material, index) => (
                  <>
                    <div key={index}>
                      <input
                        type="text"
                        placeholder="Material Title"
                        value={material.title}
                        onChange={(e) =>
                          handleMaterialChange(e, index, "title")
                        }
                      />
                      <input
                        type="number"
                        placeholder="Material Count"
                        value={material.count}
                        onChange={(e) =>
                          handleMaterialChange(e, index, "count")
                        }
                      />
                    </div>
                  </>
                ))}
                <div className="add-outer">
                  <button onClick={addMaterial}>+ Add Materials</button>
                </div>
              </div>
            </div>{" "}
            <div className="form_input">
              <label htmlFor="NecklaceStyle">Necklace Style</label>
              <input
                type="text"
                id="NecklaceStyle"
                name="NecklaceStyle"
                value={formDataData.NecklaceStyle}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="CategoryName">Category Name</label>
              <select
                id="CategoryName"
                name="categoryName"
                value={formDataData.categoryName}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                {allCategory?.map((category) => (
                  <option key={category._id} value={category.categoryName}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>{" "}
            <div className="form_input">
              <label htmlFor="CategoryList">Category List Name</label>
              <select
                id="CategoryList"
                name="categoryList"
                value={formDataData.categoryList}
                onChange={handleChange}
                required
              >
                <option value="">Select Subcategory</option>
                {selectedCategory &&
                  allCategory
                    .find(
                      (category) => category.categoryName === selectedCategory
                    )
                    ?.categoryList.map((subcategory) => (
                      <option
                        key={subcategory._id}
                        value={subcategory.title}
                        id={subcategory._id}
                      >
                        {subcategory.title}
                      </option>
                    ))}
              </select>
            </div>{" "}
            <div className="form_input">
              <label htmlFor="categoryListItem">Category List Item</label>
              <select
                id="categoryListItem"
                name="categoryListItem"
                value={formDataData.categoryListItem}
                onChange={handleChange}
                required
              >
                <option value="">Select Item</option>
                {selectedCategoryList &&
                  allCategory
                    .find(
                      (category) => category.categoryName === selectedCategory
                    )
                    ?.categoryList.find(
                      (subcategory) =>
                        subcategory.title === selectedCategoryList
                    )
                    ?.list.map((item) => (
                      <option key={item._id} value={item.name} id={item._id}>
                        {item.name}
                      </option>
                    ))}
              </select>
            </div>
            <div className="btn" style={{ backgroundColor: "#005C4B" }}>
              <input type="submit" value="Create Product" />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default CreateProduct;
