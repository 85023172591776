import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./OrderConfirmation.scss";
import { useLocation } from "react-router-dom";
import pendel from "../../assets/home-cat-1.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

const OrderConfirmation = () => {
  const [returnReason, setReturnReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [returnProductId, setreturnProductId] = useState();
  const [checkedProducts, setCheckedProducts] = useState({ productsId: [] });

  const [orderItems, setOrderItems] = useState([]);

  const handleCheckboxChange = (
    Item,
    itemId,
    units,
    name,
    selling_price,
    productId
  ) => {
    setCheckedProducts((prevState) => {
      // Clone the previous state to ensure immutability
      const updatedProducts = [...prevState.productsId];

      // Find the index of the product by productId
      const productIndex = updatedProducts.findIndex(
        (product) => product.productId.toString() === productId.toString()
      );

      if (productIndex !== -1) {
        // Product already exists, check for the ModelPart
        const modelPartIndex = updatedProducts[
          productIndex
        ].ModelPart.findIndex((part) => part._id === itemId);

        if (modelPartIndex !== -1) {
          // ModelPart already exists, remove it
          const updatedModelPart = [...updatedProducts[productIndex].ModelPart];
          updatedModelPart.splice(modelPartIndex, 1);

          if (updatedModelPart.length === 0) {
            // Remove the product if no ModelPart left
            updatedProducts.splice(productIndex, 1);
          } else {
            // Update the product's ModelPart
            updatedProducts[productIndex] = {
              ...updatedProducts[productIndex],
              ModelPart: updatedModelPart,
            };
          }
        } else {
          // ModelPart does not exist, add it
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            ModelPart: [...updatedProducts[productIndex].ModelPart, Item],
          };
        }
      } else {
        // Product does not exist, add it with the ModelPart
        const newProduct = {
          productId: productId,
          units: units,
          ModelPart: [Item],
        };
        updatedProducts.push(newProduct);
      }

      // Return the new state
      return { productsId: updatedProducts };
    });

    const sku = name; // U
    console.log({ itemId, units, name, selling_price });
    setOrderItems((prevOrderItems) => {
      const isProductChecked = prevOrderItems.some((item) => item.sku === sku);

      if (isProductChecked) {
        return prevOrderItems.filter((item) => item.sku !== sku);
      } else {
        return [...prevOrderItems, { sku, name, units, selling_price }];
      }
    });
  };

  console.log("->>>>>>>>>>", orderItems);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const [address, setAddress] = useState([]);
  const location = useLocation();
  const userFetchId = new URLSearchParams(location.search).get("userId");
  const fetchOrder = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/api/getOrderById/${id}`
      );
      if (response.data.success) {
        console.log("->>>>>>>>>>>>.", response.data);
        setOrder(response.data.data);
      } else {
        console.log("llllllllll", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("->>>>>>>>>>>>>>>>>>>", order?.user?.phoneNumber);
  useEffect(() => {
    fetchOrder();
  }, [id]);

  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData?._id;

  const fetchAddress = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/user/getAddressByUserId/${userFetchId}`
      );
      if (response.data.success) {
        console.log("VVVVVVVVVVVVVVVVVVvv", userFetchId);
        setAddress(response.data.data);
      } else {
        console.log("llllllllll", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAddress();
  }, []);

  const cancelShipment = async () => {
    try {
      const { data } = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/api/ship/cancelRequest",
        {
          orderId: id,
        }
      );
      if (data.success) {
        toast.success("Amount Refunded successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`Error in cancel order`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  // const componentDidMount = () => {
  //   const currentDate = this.getCurrentDate();
  //   this.setState({ currentDate });
  // };

  // const getCurrentDate = () => {
  //   const date = new Date();
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const day = date.getDate().toString().padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  const handleReturn = () => {
    // Here you can handle the return logic
    handleCloseModal();

    console.log(checkedProducts);
    if (checkedProducts.length == 0) {
      toast.warn("Select Return Product", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    let takeReturnReason = "";
    console.log("Returning product with reason:", returnReason);
    if (returnReason === "Other") takeReturnReason = otherReason;
    else takeReturnReason = returnReason;

    // Close the modal after handling return
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/return/addReturnRequest`,
      method: "post",
      data: {
        orderId: order?._id,
        productsId: checkedProducts,
        reasonOfReturn: takeReturnReason,
        user: userId,
        order_items: orderItems,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data);
        if (response?.data?.status == true) {
          toast.success("Case Study get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  return (
    <>
      <div>
        <div className="OC-main">
          <div className="OC-outer">
            <div className="OC-inner">
              <div className="section-1 row">
                <div className="sec-1-part-1 col-8">
                  {order?.orderStatus !== "PROCESSING" ? (
                    <h3>
                      Your order has been successfully canceled, and a refund
                      has been processed.
                    </h3>
                  ) : (
                    <h5>Thank you, your order has been placed</h5>
                  )}

                  {/* <p>
                  The order confirmation has been sent to your email address
                </p> */}
                </div>
                <div className="sec-1-part-2 col-4">
                  {(order?.orderStatus == "PROCESSING" ||
                    order?.orderStatus == "COMPLETED") && (
                    <div className="button-outer">
                      <Link to={`/invoice/${id}`}>Download Invoice</Link>
                    </div>
                  )}
                  {order?.status == "" && (
                    <div className="button-outer">
                      <Link onClick={cancelShipment}>Cancel</Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="section-2">
                <div className="sec-2-outer">
                  <h6>Hello {userData?.fullName},</h6>
                  <p>
                    Your order has been confirmed and will be shipping in the
                    next 3 Days.
                  </p>
                </div>
              </div>
              <div className="section-3">
                <div className="sec-3-outer">
                  <div className="sec-3-inner">
                    <div className="head">
                      <h6>Order Details:</h6>
                    </div>
                    <div className="mid row">
                      <div className="part-1 col-6">
                        <div className="part-head">
                          <p>Order ID:</p>
                          <p>Order Total:</p>
                        </div>
                        <div className="part">
                          <p>{order._id}</p>
                          <p>
                            ₹{" "}
                            {order?.payBywallet
                              ? order?.amount + order?.payBywallet
                              : order?.amount}
                          </p>
                        </div>
                      </div>
                      <div className="part-2 col-6">
                        <div className="part-head">
                          <p>Transaction ID :</p>
                          <p>Date:</p>
                        </div>
                        <div className="part">
                          <p>{order?.transactionId}</p>
                          <p>
                            {new Date(order.createdAt).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="body">
                      <div className="body-outer">
                        {order?.products?.map((product, index) =>
                          product?.ModelPart?.map((Item, ind) => (
                            <div key={index} className="body-inner row">
                              <div className="image-outer col-3">
                                <img
                                  src={product?.productId?.image[0]}
                                  alt={product?.productId?.title}
                                />
                              </div>
                              <div className="content-outer col-3 ">
                                <div className="content">
                                  <p>{product?.productId?.title}</p>
                                  <div className="but-outer">
                                    <p>Qnt:</p>
                                    <p>{Item?.units}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="price-outer col-3">
                                <div className="price">
                                  <p>
                                    Price : ₹{" "}
                                    {product?.productId?.sellprice *
                                      Item?.units}
                                  </p>
                                  {Item.color && <p>Color :{Item.color}</p>}
                                  {Item.polish && <p>polish: {Item.polish}</p>}
                                  {Item.units && <p>units :{Item.units}</p>}
                                </div>
                              </div>
                              <div className="checkbox-outer col-3">
                                <h6>Select For Return</h6>
                                <input
                                  type="checkbox"
                                  checked={checkedProducts.productsId.some(
                                    (checkedProduct) =>
                                      checkedProduct.productId ===
                                        product.productId._id &&
                                      checkedProduct.ModelPart.some(
                                        (part) => part._id === Item._id
                                      )
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      Item,
                                      Item._id,
                                      Item?.units,
                                      product?.productId?.title,
                                      product?.productId?.sellprice *
                                        Item?.units,
                                      product?.productId?._id
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ))
                        )}
                      </div>

                      <div className="return-button ">
                        <button onClick={handleShowModal}>Return</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-4">
                <div className="sec-4-outer row">
                  {/* <div className="part-1 col-6">
                  <div className="part-1-outer">
                    <div className="head">
                      <h6>Shipping Details:</h6>
                    </div>
                    <div className="body">
                      <div className="part-1-body">
                        <p>Name:</p>
                        <p>Address:</p>
                        <p>Pin:</p>
                        <p>Contact No:</p>
                      </div>
                      <div className="part-2-body">
                        <p>Rishika Kothari</p>
                        <p>Sadar Road Balod</p>
                        <p>452010</p>
                        <p>9993720520</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div className="part-2 col-12">
                    <div className="part-2-outer">
                      <div className="head">
                        <h6>Billing Details:</h6>
                      </div>
                      <div className="body">
                        <div className="part-1-body">
                          <p>Name:</p>
                          <p>Address:</p>
                          <p>Pin:</p>
                          <p>Contact No.:</p>
                          <p>Amount:</p>
                        </div>
                        <div className="part-2-body">
                          {address?.map((item) => {
                            return (
                              <p>{item?.fullName || "Update User Address"}</p>
                            );
                          })}
                          <p>
                            {order?.userAddress?.street},
                            {order?.userAddress?.landmark},
                            {order?.userAddress?.city},
                            {order?.userAddress?.state},
                            {order.userAddress?.country}
                          </p>
                          <p>{order?.userAddress?.zipCode}</p>
                          <p>{order?.user?.phoneNumber}</p>
                          <p>₹ {order?.amount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-5">
                <div className="button-outer">
                  <Link to={`/myAccount/${userData?._id}`}>My Orders</Link>
                </div>
              </div>

              <div className="modal-container">
                <Modal
                  show={showModal}
                  onHide={handleCloseModal}
                  className="bottom-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Select Return Reason</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={returnReason}
                        onChange={(e) => setReturnReason(e.target.value)}
                      >
                        <option value="">Select Reason</option>
                        <option value="Wrong size or fit">
                          Wrong size or fit
                        </option>
                        <option value="Received damaged or defective item">
                          Received damaged or defective item
                        </option>
                        {/* Add more options as needed */}
                        <option value="Other">Other</option>
                      </Form.Control>
                    </Form.Group>
                    {returnReason === "Other" && (
                      <Form.Group>
                        <Form.Label>Other Reason</Form.Label>
                        <Form.Control
                          type="text"
                          value={otherReason}
                          onChange={(e) => setOtherReason(e.target.value)}
                        />
                      </Form.Group>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleReturn}>
                      Return
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default OrderConfirmation;
