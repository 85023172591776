import React, { useState, useEffect } from "react";
import "./ShopAll.scss";
import ShopCard from "../../components/ShopCard2/ShopCard";

import { useLocation } from "react-router-dom";

const Shop = () => {
  const min = 0;
  const max = 10000;

  const location = useLocation();

  const searchQuery = new URLSearchParams(location.search).get("search");
  console.log(searchQuery);

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;

  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPolishes, setSelectedPolishes] = useState([]);
  const [selectedBaseMetal, setSelectedBaseMetal] = useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [showStock, setShowStock] = useState(true);
  const [priceRange, setPriceRange] = useState({ min: min, max: max });
  const [value, setValue] = useState(10000);

  const [sortOption, setSortOption] = useState("");
  const { productData } = location.state || {
    productData: [],
  };
  //const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState([]);
  const toggleStockFilter = () => {
    setShowStock((prevShowStock) => !prevShowStock);
  };

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const searchQuerySearch = url.searchParams.get("search");

  useEffect(() => {
    console.log(productData.data);
  }, [searchQuerySearch]);

  const [filteredProducts, setFilteredProducts] = useState([]);
  console.log(filteredProducts);
  useEffect(() => {
    const filtered = filterProducts(product);
    setFilteredProducts(filtered);
  }, [
    product,
    selectedColors,
    selectedPolishes,
    selectedBaseMetal,
    showStock,
    value,
    sortOption,
    setSelectedProductTypes,
  ]);

  const getAllproduct = () => {
    fetch(
      `https://kanha-art-jewellery-drtc.onrender.com/category/allCategory?search=${
        searchQuery ? searchQuery : null
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getAllproduct();
  }, [searchQuerySearch]);

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [materialVisible, setIsMaterialVisible] = useState(false);

  const [sizeVisible, setIsSizeVisible] = useState(false);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const materialToggleContentVisibility = () => {
    setIsMaterialVisible(!materialVisible);
  };

  const sizeToggleContentVisibility = () => {
    setIsSizeVisible(!sizeVisible);
  };

  const [activeColor, setActiveColor] = useState(null);

  const handleColorChange = (color) => {
    setSelectedColors((prevColors) => {
      const newColors = prevColors.includes(color)
        ? prevColors.filter((c) => c !== color)
        : [...prevColors, color];
      return newColors;
    });
    setActiveColor(color);
  };

  const handleBaseMetalChange = (baseMetal) => {
    setSelectedBaseMetal((prevBaseMetal) => {
      if (prevBaseMetal.includes(baseMetal)) {
        return prevBaseMetal.filter((bm) => bm !== baseMetal);
      } else {
        return [...prevBaseMetal, baseMetal];
      }
    });
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setPriceRange((prevRange) => ({
      ...prevRange,
      max: newValue,
    }));
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  const filterProducts = (products) => {
    let filtered = products?.filter((product) => {
      const colorMatch =
        selectedColors?.length === 0 ||
        selectedColors?.some((color) => product?.colors?.includes(color));
      console.log("=====================", selectedColors.length);
      const polishMatch =
        selectedPolishes?.length === 0 ||
        selectedPolishes?.some((polish) => product?.polish?.includes(polish));
      const productTypeMatch =
        selectedProductTypes?.length === 0 ||
        selectedProductTypes?.includes(product.title);
      const baseMetalMatch =
        selectedBaseMetal?.length === 0 ||
        selectedBaseMetal?.includes(product.baseMetal);

      const baseCategory =
        selectedCategory.length === 0 ||
        selectedCategory.includes(product.categoryName);
      const priceMatch = product.sellprice <= value;
      const stockMatch = showStock || !product.stock;
      // const titleMatch = product.title.toLowerCase().includes(searchQuery.toLowerCase());
      return (
        colorMatch &&
        polishMatch &&
        productTypeMatch &&
        baseMetalMatch &&
        priceMatch &&
        stockMatch &&
        baseCategory
      );
    });
    if (sortOption === "lowToHigh") {
      filtered = filtered.sort((a, b) => a.sellprice - b.sellprice);
    } else if (sortOption === "highToLow") {
      filtered = filtered.sort((a, b) => b.sellprice - a.sellprice);
    }
    return filtered;
  };

  const [activePolish, setActivePolish] = useState(null);

  const handlePolishChange = (polish) => {
    setSelectedPolishes((prevPolishes) => {
      const newPolishes = prevPolishes.includes(polish)
        ? prevPolishes.filter((p) => p !== polish)
        : [...prevPolishes, polish];
      return newPolishes;
    });
    setActivePolish(polish);
  };

  const fetchCategoryData = (categoryName) => {
    setSelectedCategory((prevcategoryName) => {
      if (prevcategoryName.includes(categoryName)) {
        return prevcategoryName.filter((bm) => bm !== categoryName);
      } else {
        return [...prevcategoryName, categoryName];
      }
    });
  };

  const filterSearchProducts = () => {
    product?.filter((product) => {
      return product.title?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
  };
  console.log(filterSearchProducts());

  useEffect(() => {
    fetch(`https://kanha-art-jewellery-drtc.onrender.com/product/allProducts?search=${searchQuery}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="all-shop-main">
        <div className="shop-outer">
          <div className="shop-inner">
            <div className="shop-hero">
              <div className="hero-part-1">
                <div className="hero-top">
                  <i class="bi bi-house-door-fill"></i>
                  <i class="bi bi-chevron-right"></i>
                  <p>All Products</p>
                </div>
                <div className="hero-bottom">
                  <p>All Products</p>
                </div>
              </div>
            </div>

            <div className="shop-body">
              <div className="body-inner row">
                <div className="shop-section-1 col-3">
                  <div className="sec-1-outer">
                    <div className="shop-filter">
                      <i class="bi bi-funnel"></i> <p>Filter</p>
                    </div>

                    <div className="shop-stock">
                      <p>Out of Stock</p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={showStock}
                          onChange={toggleStockFilter}
                        />
                        <span class="slider"></span>
                      </label>
                    </div>

                    <div className="shop-price">
                      <p className="price">Price</p>
                      <div className="price-range">
                        <div className="numb-range row">
                          <div className="min-outer col-6">
                            <div className="min-inner">
                              <p className="">{min}</p>
                            </div>
                          </div>
                          <div className="max-outer col-6">
                            <div className="max-inner">
                              <p className="">{value}</p>
                            </div>
                          </div>
                        </div>

                        <input
                          type="range"
                          min={min}
                          max={max}
                          value={priceRange.max}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="shop-color">
                      <div className="color-outer row">
                        <p>Colors</p>

                        {console.log(product)}
                        {Array?.from(
                          new Set(product?.flatMap((product) => product.colors))
                        ).map((color, colorIndex) => (
                          <div
                            key={colorIndex}
                            className="color-inner col-md-2"
                            onClick={() => handleColorChange(color)}
                          >
                            <button
                              className="color"
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                                border: selectedColors.includes(color)
                                  ? "1px solid black"
                                  : "none",
                                transform:
                                  activeColor === color
                                    ? "scale(1.1)"
                                    : "scale(1)",
                              }}
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={color}
                              onClick={() => handleColorChange(color)}
                              onDoubleClick={() => handleColorChange(color)}
                            ></button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="shop-polish">
                      <div className="polish-outer row">
                        <p>Polish</p>

                        {Array?.from(
                          new Set(product?.flatMap((product) => product.polish))
                        ).map((polish, colorIndex) => (
                          <div
                            key={colorIndex}
                            className="polish-inner col-md-2"
                            onClick={() => handlePolishChange(polish)}
                          >
                            <button
                              className="polish"
                              style={{
                                backgroundColor: polish,
                                borderRadius: "50%",
                                border: selectedPolishes.includes(polish)
                                  ? "1px solid black"
                                  : "none",
                                transform:
                                  activePolish === polish
                                    ? "scale(1.1)"
                                    : "scale(1)",
                              }}
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={polish}
                              onClick={() => handlePolishChange(polish)}
                              onDoubleClick={() => handlePolishChange(polish)}
                            ></button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="shop-pro-type">
                      <div className="type-outer">
                        <div className="type-inner">
                          <div className="button-outer">
                            <button onClick={toggleContentVisibility}>
                              <p>Product Type</p>{" "}
                              <i
                                className={`bi ${
                                  isContentVisible
                                    ? "bi-caret-up-fill"
                                    : "bi-caret-down-fill"
                                }`}
                              ></i>
                            </button>
                          </div>

                          {isContentVisible && (
                            <div className="type-content">
                              <div className="part">
                                <div className="sub-part">
                                  <input
                                    type="checkbox"
                                    onClick={() => fetchCategoryData("Item")}
                                  />
                                  <label>Item</label>
                                </div>
                              </div>
                              <div className="part">
                                <div className="sub-part">
                                  <input
                                    type="checkbox"
                                    onClick={() => fetchCategoryData("Itemxyz")}
                                  />
                                  <label>Itemxyz</label>
                                </div>
                              </div>
                              <div className="part">
                                <div className="sub-part">
                                  <input
                                    type="checkbox"
                                    onClick={() =>
                                      fetchCategoryData("Necklaces")
                                    }
                                  />
                                  <label>Necklaces</label>
                                </div>
                              </div>
                              <div className="part">
                                <div className="sub-part">
                                  <input
                                    type="checkbox"
                                    onClick={() =>
                                      fetchCategoryData("Earrings")
                                    }
                                  />
                                  <label>Earrings</label>
                                </div>
                              </div>
                              <div className="part">
                                <div className="sub-part">
                                  <input
                                    type="checkbox"
                                    onClick={() => fetchCategoryData("Bangles")}
                                  />
                                  <label>Bangles</label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="shop-material">
                      <div className="type-outer">
                        <div className="type-inner">
                          <div className="button-outer">
                            <button onClick={materialToggleContentVisibility}>
                              <p>Base Metal</p>{" "}
                              <i
                                className={`bi ${
                                  materialVisible
                                    ? "bi-caret-up-fill"
                                    : "bi-caret-down-fill"
                                }`}
                              ></i>
                            </button>
                          </div>
                          {materialVisible && (
                            <div className="type-content">
                              {Object.entries(
                                product.reduce((acc, product) => {
                                  const baseMetal = product.baseMetal;
                                  if (!acc[baseMetal]) {
                                    acc[baseMetal] = { count: 0, baseMetal };
                                  }
                                  acc[baseMetal].count += 1;
                                  return acc;
                                }, {})
                              ).map(([baseMetal, { count }]) => (
                                <div key={baseMetal} className="part">
                                  <div className="sub-part">
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        handleBaseMetalChange(baseMetal)
                                      }
                                    />
                                    <label>{baseMetal}</label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="shop-section-2 col-9">
                  <div className="sec-2-outer">
                    <div className="sec-2-inner">
                      <div className="top">
                        <p>{filterProducts(product)?.length} Products found</p>
                        <div className="sort">
                          <div class="dropdown">
                            <button
                              class=" dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Sort
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  class="dropdown-item"
                                  onClick={() => handleSortChange("lowToHigh")}
                                >
                                  Price : low to high
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  onClick={() => handleSortChange("highToLow")}
                                >
                                  Price : High to low
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bottom ">
                        <div className="bottom-outer ">
                          {filterProducts(product).length === 0 ? (
                            <p>There is currently no product available.</p>
                          ) : (
                            filterProducts(product).map((product) => (
                              <div className="sng-product ">
                                <ShopCard
                                  key={product._id}
                                  product={{
                                    ...product,
                                    likeMore: product?.liked?.includes(userId),
                                  }}
                                />
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
