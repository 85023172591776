import React, { useState } from "react";
import "./Home.scss";
import { useEffect } from "react";
import prop1 from "../../assets/below-home-hero.png";
import prop2 from "../../assets/truck.png";
import prop3 from "../../assets/neck.png";
import prop4 from "../../assets/price.png";
import Post from "../../components/Post/Post";
import homepocket from "../../assets/home-pocket.png";
import BestCard from "../../components/BestCard/BestCard";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import bangle from "../../assets/bangle.png";
import home from "../../assets/home-cat-lg.png";
import img3 from "../../assets/home-cat.png";

import testimonial2 from "../../assets/testimonial/testimonial2.jpg";
import testimonial3 from "../../assets/testimonial/testimonial3.jpg";
import testimonial4 from "../../assets/testimonial/testimonial4.jpg";
import testimonial5 from "../../assets/testimonial/testimonial5.jpg";
import testimonial6 from "../../assets/testimonial/testimonial6.jpg";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";

import earring from "../../assets/banner/earring.jpg";
import kadas from "../../assets/banner/kadas.jpg";
import pendant from "../../assets/banner/pendant.jpg";
import rings from "../../assets/banner/rings.jpg";

import chokers from "../../assets/banner/chokers.jpg";
import haarams from "../../assets/banner/haarams.jpg";
import layers from "../../assets/banner/layers.jpg";
import shortNecklace from "../../assets/banner/shortnecklaces.jpg";

import chandbali from "../../assets/banner/chandbali.jpg";
import danglers from "../../assets/banner/danglers.jpg";
import jhumkas from "../../assets/banner/jhumkas.jpg";
import studs from "../../assets/banner/studs.jpg";

import emraldGreen from "../../assets/banner/emraldGreen.jpg";
import luminBlue from "../../assets/banner/luminBlue.jpg";
import mintZicron from "../../assets/banner/mintZicron.jpg";
import rubyRed from "../../assets/banner/rubyRed.jpg";

const Home = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");
  const [mostBuyedProduct, setMostBuyedProduct] = useState([]);
  const productData = product?.data ?? [];
  const [itemsTo, setItemsTo] = useState(Math.min(4, productData?.length || 0));
  const categoryData = category?.data;
  const [itemsToShow, setItemsToShow] = useState(4);

  const [itemToSendDataAsProps, setItemToSendDataAsProps] = useState([]);

  /* fetch blog*/
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`https://kanha-art-jewellery-drtc.onrender.com/blog/getblogs`);
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          console.log("Error fetching blogs");
        }
      } catch (error) {
        console.log("erorr", error);
      }
    };
    fetchBlogs();
  }, []);

  /* fetch Product*/
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://kanha-art-jewellery-drtc.onrender.com/product/allProducts`
        );
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setProduct(data);
          } else {
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {}
    };
    fetchProduct();
  }, []);

  useEffect(() => {
    setItemsTo(Math.min(4, productData?.length || 0));
  }, [productData]);

  let productsForProps;
  /* fetch Category*/
  const fetchCategory = async () => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/allCategory`
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data) {
          setCategory(data);
          console.log(data?.data[9]?.categoryList[0]?.list[0]?.productId);
          setItemToSendDataAsProps(
            data?.data[8]?.categoryList[0]?.list[0]?.productId
          );
        } else {
        }
      } else {
        throw new Error("Category not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* fetch Most Buyed Product*/
  const fetchMostBuyedProduct = async () => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/mostBuyedProduct`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setMostBuyedProduct(data);
        } else {
          console.log("No data received");
        }
      } else {
        throw new Error("Category not found");
      }
    } catch (error) {
      console.error("Error fetching most bought products:", error);
    }
  };
  useEffect(() => {
    fetchMostBuyedProduct();
    fetchCategory();
  }, []);

  const handleViewAllClick = () => {
    navigate("/shop", {
      state: { productData: productData },
    });

    window.scrollTo(0, 0);
  };

  /* fetch category by categoryID*/
  const handleProductData = async (id) => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/category/findCategoryByCategoryId/${id}`
      );

      const data = response.data.data.products;

      navigate("/shop", {
        state: { productData: data },
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching category data: ", error);
    }
  };

  /* fetch Category List by categoryItemName*/
  const handleGetAllProductData = async (itemName) => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getListInsideCategoryListbyItemName/${itemName}`
      );
      const data = await response.json();

      navigate(`/shop/${itemName}`, {
        state: { list: data.list, products: data.products },
      });
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching related products:", error);
    }
  };

  const handleScrollAndNavigate = (blogId) => {
    window.scrollTo(0, 0);
    navigate(`/singleBlog/${blogId}`);
  };
  console.log("{{{{{{{{{{{{", itemToSendDataAsProps);

  const handleGetAllRelatedProductData = async (itemName) => {
    try {
      console.log(itemName);
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getListInsideCategoryListbyItemName/${itemName}`
      );
      const data = await response.json();
      window.scrollTo(0, 0);

      console.log(data);

      navigate(`/shop/${itemName}`, {
        state: { list: data.list, products: data.products },
      });
    } catch (error) {
      console.error("Error fetching related products:", error);
    }
  };

  const handlexyz = async (categoryName) => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getCategoryByCategoryName/${categoryName}`
      );
      const data = await response.json();
      window.scrollTo(0, 0);

      navigate(`/shop/${categoryName}`, { state: { idData: data } });
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const notice = document.querySelector(".notice-1");
  //     const scrollPosition =
  //       window.scrollY || document.documentElement.scrollTop;
  //     const scrollThreshold =
  //       (document.documentElement.scrollHeight - window.innerHeight) * 0.06;

  //     console.log("Scroll Position:", scrollPosition);
  //     console.log("Scroll Threshold:", scrollThreshold);

  //     if (scrollPosition > scrollThreshold) {
  //       notice.classList.add("fixed");
  //     } else {
  //       notice.classList.remove("fixed");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div className="home-main">
        <div className="home-outer">
          <div className="home-inner">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav={false}
              dots={false}
              autoplay={true}
              autoplayHoverPause={true}
              autoplayTimeout={3500}
              responsive={{
                0: {
                  items: 1,
                },
                678: {
                  items: 1,
                },
                900: {
                  items: 1,
                },
                1200: {
                  items: 1,
                },
              }}
            >
              <div className="home-hero row">
                <div className="section-1 col-md-5">
                  <div className="inner">
                    <h2>
                      Kanha Art <br />
                      Jewellery
                    </h2>
                    <h6>Starting At Rs. 599</h6>
                    <div className="but">
                      <Link to="/shop">
                        <p className="m-0">Shop by collection</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-hero row">
                <div className="section-1 col-md-5">
                  <div className="inner">
                    <h2>
                      Kanha Art <br />
                      Jewellery
                    </h2>
                    <h6>Starting At Rs. 599</h6>
                    <div className="but">
                      <Link to="/shop">
                        <p className="m-0">Shop by collection</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="home-hero row">
                <div className="section-1 col-md-5">
                  <div className="inner">
                    <h2>
                      Kanha Art <br />
                      Jewellery
                    </h2>
                    <h6>Starting At Rs. 599</h6>
                    <div className="but">
                      <Link to="/shop">
                        <p className="m-0">Shop by collection</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>

            <div className="notice-1">
              <div className="container">
                <p>Buy for 3999 and get 30% off</p>
              </div>
            </div>

            <div className="home-below-hero">
              <div className="head">
                <p>Experience the Kanha Difference</p>
              </div>
              <div className="body-1 row">
                <div className="part-1 col-6 col-md-3 ">
                  <div className="part-inner">
                    <div className="image-outer1">
                      <img src={prop1} alt="" />
                    </div>
                    <p className="life">
                      Timeless <br />
                      Elegance
                    </p>
                  </div>
                  <div className="overlay">
                    <p>
                      Discover jewellery pieces that transcend trends, offering
                      enduring beauty and sophistication for every occasion.
                    </p>
                  </div>
                </div>
                <div className="part-1 mobile-special col-6 col-md-3">
                  <div className="part-inner ">
                    <div className="image-outer2">
                      <img src={prop4} alt="" />
                    </div>
                    <p className="life">
                      Quality <br />
                      Craftsmanship
                    </p>
                  </div>
                  <div className="overlay">
                    <p>
                      Experience exceptional quality and attention to detail in
                      every piece, crafted with precision and expertise.
                    </p>
                  </div>
                </div>
                <div className="part-1 mobile-top col-6 col-md-3">
                  <div className="part-inner">
                    <div className="image-outer3">
                      <img src={prop2} alt="" />
                    </div>
                    <p className="life">
                      Free <br /> Shipping
                    </p>
                  </div>
                  <div className="overlay">
                    <p>
                      Delight in complimentary shipping on all orders,
                      reflecting our commitment to your satisfaction.
                    </p>
                  </div>
                </div>
                <div className="part-1 special col-6 col-md-3">
                  <div className="part-inner">
                    <div className="image-outer4">
                      <img src={prop3} alt="" />
                    </div>
                    <p className="life">
                      Lifetime <br /> Guarantee
                    </p>
                  </div>
                  <div className="overlay">
                    <p>
                      Enjoy peace of mind with our lifetime guarantee, promising
                      enduring beauty and quality craftsmanship with every
                      piece.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-best-seller">
              <div className="head">
                <p className="new-top">Jewellery That Steals the Show</p>
                <h6>Top-Selling Treasures</h6>
                <p>
                  Explore our collection of top-selling jewellery pieces loved
                  by customers worldwide
                </p>
              </div>

              <div className="body row">
                {productData?.slice(0, itemsTo).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="inner col-6 col-sm-3  col-md-4 col-lg-3"
                    >
                      <BestCard item={item} index={index} />
                    </div>
                  );
                })}
              </div>
              <div className="all-button">
                <div className="but">
                  <button onClick={handleViewAllClick}>
                    <span>All Products</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="home-subcategory-banner">
              <div className="body-container">
                <div className="card-container " id="card-11">
                  <div
                    className="banner-cat "
                    onClick={() =>
                      handleGetAllRelatedProductData(`Pendant Sets`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src={pendant} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Kadas`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={kadas} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Jhumkas`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={earring} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() =>
                      handleGetAllRelatedProductData(`Finger Rings`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src={rings} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="short-necklane-heading">
              <h1>Short Necklaces</h1>
              <ProductCarousel data={productData} />
            </div>

            <div className="home-category">
              <div className="category-outer">
                <div className="category-inner">
                  <div className="head">
                    <p className="new-top">Discover Your Signature Style</p>
                    <h6>Explore by Category</h6>
                    <p>
                      Explore our diverse range of jewellery categories to find
                      pieces that reflect your unique taste
                    </p>
                  </div>
                  <div className="mid">
                    <div className="inner row">
                      <OwlCarousel
                        className="owl-theme"
                        loop={true}
                        margin={10}
                        autoplay={true}
                        dots={false}
                        autoplayHoverPause={true}
                        autoplayTimeout={2500}
                        responsive={{
                          0: {
                            items: 2,
                          },
                          678: {
                            items: 2,
                          },
                          900: {
                            items: 3,
                          },
                          1200: {
                            items: 4,
                          },
                        }}
                      >
                        {[
                          "Necklaces",
                          "Earrings",
                          "Accessories",
                          "Bangles",
                        ].map((title, index) => {
                          const frontImages = [
                            "https://www.kushals.com/cdn/shop/files/antique-necklace-ruby-green-gold-antique-necklace-151744-36701960110236.jpg?v=169830064200&width=704",
                            "https://www.kushals.com/cdn/shop/files/silver-zircon-earring-ruby-rodium-gold-92-5-silver-zircon-earring-161473-37133130530972.jpg?v=170325046100&width=704",
                            "https://www.kushals.com/cdn/shop/files/temple-finger-ring-oxidised-gold-silver-temple-finger-ring-161461-36506788495516.jpg?v=169459822900&width=704",
                            "https://www.kushals.com/cdn/shop/files/antique-bangle-ruby-green-gold-2-4-antique-bangle-167862-37840804315292.jpg?v=171117728700&width=704",
                          ];
                          const backImages = [
                            "https://www.freeiconspng.com/uploads/jewellery-png-transparent-images-5.png",
                            "https://www.freeiconspng.com/uploads/jewellery-png-28.png",
                            "https://www.freeiconspng.com/uploads/wedding-rings-jewellery-png-31.png",
                            "https://www.freeiconspng.com/uploads/jewellery-png-8.png",
                          ];

                          return (
                            <div className="IF" key={index}>
                              <div className="wrapper">
                                <div className="cols">
                                  <div
                                    className="col"
                                    onClick={() => handlexyz(title)}
                                    onTouchStart={() => {
                                      // Consider a more dynamic approach to identify the correct element.
                                      document
                                        .querySelectorAll(".col")
                                        [index].classList.toggle("hover");
                                    }}
                                  >
                                    <div className="container">
                                      <div
                                        className="front"
                                        style={{
                                          backgroundImage: `url(${
                                            frontImages[index % 4]
                                          })`,
                                        }}
                                      >
                                        <div className="inner">
                                          <p>{title}</p>
                                          {/* Consider adding meaningful content or attributes here. */}
                                          <span></span>
                                        </div>
                                      </div>
                                      <div className="back">
                                        <div className="inner">
                                          <img
                                            src={backImages[index % 4]}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </div>

                  <div className="home-subcategory-banner">
                    <div className="body-container">
                      <div className="card-container">
                        <div
                          className="banner-cat"
                          onClick={() =>
                            handleGetAllRelatedProductData(`Layered Necklaces`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={layers} alt="" />
                        </div>
                        <div
                          className="banner-cat"
                          onClick={() =>
                            handleGetAllRelatedProductData(`Short Necklaces`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={shortNecklace} alt="" />
                        </div>
                        <div
                          className="banner-cat"
                          onClick={() =>
                            handleGetAllRelatedProductData(`Chokers`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={chokers} alt="" />
                        </div>
                        <div
                          className="banner-cat"
                          onClick={() =>
                            handleGetAllRelatedProductData(`Long Necklaces`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={haarams} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* home crouses cards  */}
                  <div className="short-necklane-heading">
                    <h1>Earring Type </h1>
                    <ProductCarousel data={itemToSendDataAsProps} />
                  </div>

                  <div className="bottom row">
                    <div className="texts row">
                      <div className="text-outer  col-md-4">
                        <p className="new-top">
                          Perfect Piece for Every Moment
                        </p>
                        <h2>Shop By Occasions</h2>
                        <p>
                          Browse our curated selections for weddings, birthdays,
                          anniversaries, and more.
                        </p>
                      </div>

                      <div
                        className="sec-1  col-md-8"
                        onClick={() => handleGetAllProductData("Party")}
                      >
                        <div className="inner">
                          <img src={bangle} alt="" class="" />
                          <div className="overlay">Party</div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="imag row mt-2"
                      onClick={() => {
                        navigate("/shop");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <div
                        className="sec-2 col-3 col-md-4"
                        onClick={() => handleGetAllProductData("Festive")}
                      >
                        <div className="inner">
                          <img
                            src="https://raerproductiondata.s3.ap-south-1.amazonaws.com/products/1727265483386_Kanha_Jewl_1st_K.C+%2829%29.webp"
                            alt=""
                          />
                          <div className="overlay">festival jewellery </div>
                        </div>
                      </div>

                      <div
                        className="sec-2 col-md-4 col-3 "
                        onClick={() => handleGetAllProductData("Casual")}
                      >
                        <div className="inner">
                          <img
                            src="https://raerproductiondata.s3.ap-south-1.amazonaws.com/products/1727265483386_Kanha_Jewl_1st_K.C+%2829%29.webp"
                            alt=""
                          />
                          <div className="overlay">Casual jewellery</div>
                        </div>
                      </div>

                      <div
                        className="sec-2 col-3 col-md-4 "
                        onClick={() => handleGetAllProductData("Bridal")}
                      >
                        <div className="inner">
                          <img
                            src="https://raerproductiondata.s3.ap-south-1.amazonaws.com/products/1727265483386_Kanha_Jewl_1st_K.C+%2829%29.webp"
                            alt=""
                          />
                          <div className="overlay">Bridal jewellery</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-subcategory-banner">
              <div className="body-container">
                <div className="card-container">
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Danglers`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={danglers} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Jhumkas`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={jhumkas} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Chandbalis`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={chandbali} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Studs`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={studs} alt="" />
                  </div>
                  {/* <div
                    className="banner-cat"
                    onClick={() => navigate(`/shop/ShortNecklaces`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={img3} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => navigate(`/shop/Kundan`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={img3} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => navigate(`/shop/Kadas`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={img3} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => navigate(`/shop/Payal`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={img3} alt="" />
                  </div> */}
                </div>
              </div>
            </div>

            <div className="short-necklane-heading">
              <h1>Pair Bangles</h1>
              <ProductCarousel data={productData} />
            </div>

            <div className="home-pocket">
              <div className="head">
                <p className="new-top">Sparkle Without Splurging</p>
                <h1>Budget-Friendly Picks</h1>
                <p>
                  Discover affordable elegance that proves you don't need to
                  break the bank for brilliance.
                </p>
              </div>
              <div className="body">
                <div className="inner row">
                  <div
                    onClick={() => {
                      const under2000Data = productData.filter(
                        (item) => item.sellprice < 2000
                      );
                      navigate("/shop", {
                        state: { productData: under2000Data },
                      });
                      window.scrollTo(0, 0);
                    }}
                    className="deal-card col-md-6 col-6"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="outer">
                      <img src={homepocket} alt="" />
                      <div className="overlay ">Under 2000</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      const above2000Data = productData.filter(
                        (item) => item.sellprice >= 2000
                      );
                      navigate("/shop", {
                        state: { productData: above2000Data },
                      });
                      window.scrollTo(0, 0);
                    }}
                    className="deal-card col-md-6 col-6"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="outer">
                      <img src={homepocket} alt="" />
                      <div className="overlay">Above 2000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-subcategory-banner">
              <div className="body-container">
                <div className="card-container">
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Antique`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={emraldGreen} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Kundan`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={luminBlue} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Zircon`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={mintZicron} alt="" />
                  </div>
                  <div
                    className="banner-cat"
                    onClick={() => handleGetAllRelatedProductData(`Temple`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={rubyRed} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="short-necklane-heading">
              <h1>Chokers</h1>
              <ProductCarousel data={productData} />
            </div>

            <div className="home-review container-main">
              <div className="head">
                <h6>Happy Customer Stories</h6>
              </div>
              <div className="testimonial">
                <div className="key-points ">
                  <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    margin={10}
                    dots={false}
                    autoplay={true}
                    nav={false}
                    autoplayHoverPause={true}
                    autoplayTimeout={2500}
                    center={true}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      678: {
                        items: 1,
                      },
                      900: {
                        items: 3,
                      },
                      1200: {
                        items: 3,
                      },
                    }}
                  >
                    <div className="testimonial-main">
                      <div className="testimonial-outer">
                        <div className="testimonial-inner row">
                          <div className="section-1 col-5">
                            <div className="sec-1-outer">
                              <div className="image-outer">
                                <img src={testimonial3} alt="" />
                              </div>
                              <div className="text-outer">
                                <h2>Khushi Yadav</h2>
                                {/* <p>Lorem Ipsum Dolor</p> */}
                              </div>
                            </div>
                            <div className="back"></div>
                          </div>
                          <div className="section-2 col-7">
                            <div className="sec-2-outer">
                              <div className="image-outer">
                                <i class="bi bi-quote"></i>{" "}
                              </div>
                              <div className="content-outer">
                                <p>
                                  I'm absolutely thrilled with the
                                  craftsmanship; it is impeccable, and the
                                  attention to detail is outstanding. I receive
                                  compliments every time I wear their pieces.
                                </p>
                              </div>
                              <div className="image-outer-bottom">
                                <div className="starts">
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                </div>
                                <div className="image-outer">
                                  <i class="bi bi-quote"></i>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-main">
                      <div className="testimonial-outer">
                        <div className="testimonial-inner row">
                          <div className="section-1 col-5">
                            <div className="sec-1-outer">
                              <div className="image-outer">
                                <img src={testimonial2} alt="" />
                              </div>
                              <div className="text-outer">
                                <h2>Samriddhi Acholiya</h2>
                                {/* <p>Lorem Ipsum Dolor</p> */}
                              </div>
                            </div>
                            <div className="back"></div>
                          </div>
                          <div className="section-2 col-7">
                            <div className="sec-2-outer">
                              <div className="image-outer">
                                <i class="bi bi-quote"></i>{" "}
                              </div>
                              <div className="content-outer">
                                <p>
                                  Shopping for jewellery has never been easier!
                                  Kanha Art jewellery's customer service is
                                  top-notch. I'm impressed by the quality of
                                  their products and their commitment to
                                  customer satisfaction.
                                </p>
                              </div>
                              <div className="image-outer-bottom">
                                <div className="starts">
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>

                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div className="image-outer">
                                  <i class="bi bi-quote"></i>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-main">
                      <div className="testimonial-outer">
                        <div className="testimonial-inner row">
                          <div className="section-1 col-5">
                            <div className="sec-1-outer">
                              <div className="image-outer">
                                <img src={testimonial6} alt="" />
                              </div>
                              <div className="text-outer">
                                <h2>Aarohi</h2>
                                {/* <p>Lorem Ipsum Dolor</p> */}
                              </div>
                            </div>
                            <div className="back"></div>
                          </div>
                          <div className="section-2 col-7">
                            <div className="sec-2-outer">
                              <div className="image-outer">
                                <i class="bi bi-quote"></i>{" "}
                              </div>
                              <div className="content-outer">
                                <p>
                                  I recently bought a necklace for my wife's
                                  birthday, and she hasn't stopped smiling since
                                  she received it! The packaging was beautiful,
                                  and the personalized note added a special
                                  touch.
                                </p>
                              </div>
                              <div className="image-outer-bottom">
                                <div className="starts">
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>

                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div className="image-outer">
                                  <i class="bi bi-quote"></i>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-main">
                      <div className="testimonial-outer">
                        <div className="testimonial-inner row">
                          <div className="section-1 col-5">
                            <div className="sec-1-outer">
                              <div className="image-outer">
                                <img src={testimonial4} alt="" />
                              </div>
                              <div className="text-outer">
                                <h2>Rishika Kothari</h2>
                                {/* <p>Lorem Ipsum Dolor</p> */}
                              </div>
                            </div>
                            <div className="back"></div>
                          </div>
                          <div className="section-2 col-7">
                            <div className="sec-2-outer">
                              <div className="image-outer">
                                <i class="bi bi-quote"></i>{" "}
                              </div>
                              <div className="content-outer">
                                <p>
                                  Whether I'm looking for something classic or
                                  trendy, they always have the perfect piece to
                                  match my style. I'm continually impressed by
                                  the variety and quality of their designs.
                                </p>
                              </div>
                              <div className="image-outer-bottom">
                                <div className="starts">
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                </div>
                                <div className="image-outer">
                                  <i class="bi bi-quote"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-main">
                      <div className="testimonial-outer">
                        <div className="testimonial-inner row">
                          <div className="section-1 col-5">
                            <div className="sec-1-outer">
                              <div className="image-outer">
                                <img src={testimonial5} alt="" />
                              </div>
                              <div className="text-outer">
                                <h2>Ayushi Yadav</h2>
                                {/* <p>Lorem Ipsum Dolor</p> */}
                              </div>
                            </div>
                            <div className="back"></div>
                          </div>
                          <div className="section-2 col-7">
                            <div className="sec-2-outer">
                              <div className="image-outer">
                                <i class="bi bi-quote"></i>{" "}
                              </div>
                              <div className="content-outer">
                                <p>
                                  I purchased a pair of earrings for my best
                                  friend's wedding, and they were the perfect
                                  accessory to complete her bridal look. She was
                                  overjoyed with them, and I couldn't be happier
                                  with my choice.
                                </p>
                              </div>
                              <div className="image-outer-bottom">
                                <div className="starts">
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>
                                  <i
                                    class="bi bi-star-fill"
                                    style={{ color: "#F07C00" }}
                                  ></i>

                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div className="image-outer">
                                  <i class="bi bi-quote"></i>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>

            <div className="home-styling">
              <div className="head">
                <h6>Styling Guides</h6>
                <p className="new-top">Elevate Your Look, Your Way</p>
              </div>
              <div className="body">
                <div className="inner row">
                  {blogs &&
                    blogs.slice(0, 3).map((blog, index) => (
                      <div
                        className="card-out col-md-4  col-sm-4 col-6"
                        key={index}
                        onClick={() => handleScrollAndNavigate(blog._id)}
                      >
                        <Post
                          id={blog._id}
                          title={blog.title}
                          description={blog.description[0].content}
                          image={blog.image}
                          readingTime={blog.readingTime}
                          tags={blog.tags}
                          createdAt={blog.createdAt}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="bottom">
                <div className="frame">
                  <button class="custom-btn btn-7" onClick={handleViewAllClick}>
                    <span>View all</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="home-hot">
              <div className="head">
                <p className="new-top">jewellery That's Turning Heads</p>
                <h6>Trending Now</h6>
                <p>
                  Explore our curated selection of the hottest jewellery pieces
                  <br /> that are flying off the shelves
                </p>
              </div>
              <div className="body">
                <div className="sec-1 row">
                  {mostBuyedProduct?.slice(0, 4).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="part col-md-3 col-6"
                        onClick={() => handleProductData(item.categoryId)}
                      >
                        <div className="part-inner">
                          <img src={item?.image[0]} alt="" />
                          <div className="over-outer">
                            <div className="overlay">
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
