import React, { useEffect, useState } from "react";
import "./ResourceCenter.scss";
import Post from "../../components/Post/Post";
import { Link } from "react-router-dom";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AllCategory from "../../components/AllCategory/AllCategory";

import { useNavigate } from "react-router-dom";

const ResourceCenter = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchBlog, setSearchBlog] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const sortedBlogs = blogs.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const latestBlogs = sortedBlogs.slice(0, 3);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`https://kanha-art-jewellery-drtc.onrender.com/blog/getblogs`);
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
        // toast.error(`${error.message}`, {
        //   position: "bottom-right",
        //   autoClose: 8000,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "dark",
        // });
      }
    };
    fetchBlogs();
  }, []);

  const search = async () => {
    console.log("frdes", searchBlog);
    if (searchField !== "") {
      setSearchPerformed(true);
      try {
        const { data } = await axios.post(
          `https://kanha-art-jewellery-drtc.onrender.com/blog/searchBlog`,
          {
            search: searchField,
          }
        );
        setSearchBlog(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        console.error(`Error searching blogs: ${error.message}`);
        setSearchBlog([]);
      }
    } else {
      setSearchBlog([]);
      setSearchPerformed(false);
    }
  };

  useEffect(() => {
    search(searchField);
  }, [searchField]);

  // useEffect(() => {
  //   if (blogs.length > 0) {
  //     setIsLoading(false);
  //   }
  // }, [blogs]);

  // console.log(isLoading);

  const singleHandler = (id) => {
    navigate(`/singleBlog/${id}`);
     window.scrollTo(0, 0); 

  };

  return (
    <div>
      <div className="RC-main">
         
        <div className="RC-outer">
          <div className="RC-inner">
            <div className="section-1">
              <div className="outer">
                <div className="inner row">
                  <div className="text col-12">
                    <div className="part">
                      <i class="bi bi-house-door-fill"></i>
                      <i class="bi bi-chevron-right"></i>
                      <p>Blogs</p>
                    </div>
                    <div className="part">
                      <h1>
                        Welcome <br /> to Blogs
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="outer">
                <div className="inner row">
                  <div className="part1 col-md-8 col-4">
                    <div className="inner">
                      <div className="sub-part1">
                        <div class="dropdown">
                          <button
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-border-all"></i>
                            All Categories
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#allproducts">
                                Necklaces
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#allproducts">
                                Bangles
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#allproducts">
                                Earrings
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="part2 col-md-4 col-8">
                    <div className="sec2-inner">
                      <input
                        placeholder="Type here to search"
                        value={searchField}
                        onChange={(e) => setSearchField(e.target.value)}
                      />
                      <div className="side-outer">
                        <div className="side">
                          <i className="bi bi-search"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="search-outer row">
                    {searchPerformed && searchBlog.length === 0 ? (
                      <>
                        <div className="no-results">
                          <div className="head">
                            <h4>Search Blog</h4>
                          </div>
                          <p>No results found.</p>
                        </div>
                      </>
                    ) : (
                      searchBlog?.map((blog, index) => (
                        <div
                          key={index}
                          className=" card-out col-md-4 col-sm-4 col-12"
                          onClick={() => {
                            singleHandler(blog?._id);
                          }}
                        >
                          <Post
                            id={blog._id}
                            title={blog.title}
                            description={blog.description[0].content}
                            image={blog.image}
                            readingTime={blog.readingTime}
                            tags={blog.tags}
                            createdAt={blog.createdAt}
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="section-3">
              <div className="outer">
                <div className="inner">
                  <div className="head">
                    <h1>Latest Posts</h1>
                  </div>
                  <div className="body">
                    <div className="inner row">
                      {latestBlogs &&
                        latestBlogs.map((blog, index) => (
                          <div
                            className="card-out col-md-4  col-sm-4 col-12"
                            key={index}
                            onClick={() => {
                              singleHandler(blog?._id);
                            }}
                          >
                            <Post
                              id={blog._id}
                              title={blog.title}
                              description={blog.description[0].content}
                              image={blog.image}
                              readingTime={blog.readingTime}
                              tags={blog.tags}
                              createdAt={blog.createdAt}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-4">
              <div className="outer">
                <div className="inner">
                  <div className="head">
                    <h1>Most Viewed by the people</h1>
                  </div>
                  <div className="body">
                    <div className="inner row">
                      {blogs &&
                        blogs.slice(0, 3).map((blog, index) => (
                          <div
                            className="card-out col-md-4  col-sm-4 col-12"
                            key={index}
                            onClick={() => {
                              singleHandler(blog?._id);
                            }}
                          >
                            <Post
                              id={blog._id}
                              title={blog.title}
                              description={blog.description[0].content}
                              image={blog.image}
                              readingTime={blog.readingTime}
                              tags={blog.tags}
                              createdAt={blog.createdAt}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-5">
              <div className="outer">
                <div className="inner">
                  <div className="key-points d-flex justify-content-center">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={1 }
                      autoplay={false}
                      autoplayHoverPause={true}
                      autoplayTimeout={5000}
                      center={true}
                      stagePadding={300}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        678: {
                          items: 1,
                        },
                        900: {
                          items: 1,
                        },
                        1200: {
                          items: 1,
                        },
                      }}
                    >
                      <div className="key-card">
                        <div className="gapping row align-items-center justify-content-center">
                          <p>By Necklaces</p>
                        </div>
                      </div>
                      <div className="key-card">
                        <div className="gapping row align-items-center justify-content-center">
                          <p>By Bangles</p>
                        </div>
                      </div>
                      <div className="key-card">
                        <div className="gapping row align-items-center justify-content-center">
                          <p>By Earrings</p>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                  <div className="body">
                    <div className="inner row">
                      {blogs &&
                        blogs
                          .filter((item) => item.title == "Earrings")
                          .slice(0, 3)
                          .map((blog, index) => (
                            <div
                              className="card-out col-md-4  col-sm-4 col-12 "
                              key={index}
                              onClick={() => {
                                singleHandler(blog?._id);
                              }}
                            >
                              <Post
                                id={blog._id}
                                title={blog.title}
                                description={blog.description[0].content}
                                image={blog.image}
                                readingTime={blog.readingTime}
                                tags={blog.tags}
                                createdAt={blog.createdAt}
                              />
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-6 " id="allproducts">
              <div className="outer">
                <div className="inner">
                  <div className="head">
                    <h1>All Posts</h1>
                  </div>
                  <div className="body">
                    <div className="inner row">
                      {blogs &&
                        blogs.map((blog, index) => (
                          <div
                            className="card-out col-md-4  col-sm-4 col-12"
                            key={index}
                            onClick={() => {
                              singleHandler(blog?._id);
                            }}
                          >
                            <Post
                              id={blog._id}
                              title={blog.title}
                              description={blog.description[0].content}
                              image={blog.image}
                              readingTime={blog.readingTime}
                              tags={blog.tags}
                              createdAt={blog.createdAt}
                            />
                          </div>
                        ))}
                      {/* <div className="load col-12">
                        <button className="col-md-12 load-more-button">
                          Load More
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceCenter;
