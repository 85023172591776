import React, { useState, useEffect } from "react";
import "./ShopPage.scss";
import ShopCard from "../../components/ShopCard2/ShopCard";

import { useLocation } from "react-router-dom";
var productCount;
const ShopPage = () => {
  const min = 0;
  const max = 10000;

  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPolishes, setSelectedPolishes] = useState([]);
  const [selectedBaseMetal, setSelectedBaseMetal] = useState([]);
  const [showStock, setShowStock] = useState(true);
  const [priceRange, setPriceRange] = useState({ min: min, max: max });
  const [value, setValue] = useState(10000);

  //const [filteredProducts, setFilteredProducts] = useState([]);

  const [sortOption, setSortOption] = useState("");

  const location = useLocation();

  console.log("Location state:", location.state);
  // const [] khjfkdsf

  const { products, list, idData } = location.state || {
    list: null,
    products: [],
    idData: null,
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;
  console.log("_>>>>>>>>>>>>", userId);

  console.log("aaaaaaaaaaaaa", idData?.category?.products);
  //   useEffect(() => {
  //     const filtered = filterProducts(products);
  //     setFilteredProducts(filtered);
  //  }, [products, selectedColors, selectedPolishes, selectedBaseMetal, showStock, value, sortOption]);

  const toggleStockFilter = () => {
    setShowStock((prevShowStock) => !prevShowStock);
  };

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [materialVisible, setIsMaterialVisible] = useState(false);

  const [sizeVisible, setIsSizeVisible] = useState(false);

  console.log(idData?.category?.products);
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const materialToggleContentVisibility = () => {
    setIsMaterialVisible(!materialVisible);
  };

  const sizeToggleContentVisibility = () => {
    setIsSizeVisible(!sizeVisible);
  };
  const [activeColor, setActiveColor] = useState(null);

  const handleColorChange = (color) => {
    setSelectedColors((prevColors) => {
      const newColors = prevColors.includes(color)
        ? prevColors.filter((c) => c !== color)
        : [...prevColors, color];
      return newColors;
    });
    setActiveColor(color);
  };

  const handleBaseMetalChange = (baseMetal) => {
    setSelectedBaseMetal((prevBaseMetal) => {
      if (prevBaseMetal.includes(baseMetal)) {
        return prevBaseMetal.filter((bm) => bm !== baseMetal);
      } else {
        return [...prevBaseMetal, baseMetal];
      }
    });
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setPriceRange((prevRange) => ({
      ...prevRange,
      max: newValue,
    }));
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  const filterProducts = (products) => {
    productCount = products.length;
    let filtered = products.filter((product) => {
      const colorMatch =
        selectedColors.length === 0 ||
        selectedColors.some((color) => product.colors.includes(color));
      console.log("=====================", selectedColors.length);
      const polishMatch =
        selectedPolishes.length === 0 ||
        selectedPolishes.some((polish) => product.polish.includes(polish));
      // const productTypeMatch =
      //   selectedProductTypes.length === 0 ||
      //   selectedProductTypes.includes(product.title);
      const baseMetalMatch =
        selectedBaseMetal.length === 0 ||
        selectedBaseMetal.includes(product.baseMetal);
      const priceMatch = product.sellprice <= value;
      const stockMatch = showStock || !product.stock;
      return (
        colorMatch &&
        polishMatch &&
        // productTypeMatch &&
        baseMetalMatch &&
        priceMatch &&
        stockMatch
      );
    });
    if (sortOption === "lowToHigh") {
      filtered = filtered.sort((a, b) => a.sellprice - b.sellprice);
    } else if (sortOption === "highToLow") {
      filtered = filtered.sort((a, b) => b.sellprice - a.sellprice);
    }
    return filtered;
  };

  const [activePolish, setActivePolish] = useState(null);

  const handlePolishChange = (polish) => {
    setSelectedPolishes((prevPolishes) => {
      const newPolishes = prevPolishes.includes(polish)
        ? prevPolishes.filter((p) => p !== polish)
        : [...prevPolishes, polish];
      return newPolishes;
    });
    setActivePolish(polish);
  };

  return (
    <>
      <div className="shop-main">
        {list && products ? (
          <div className="shop-outer ">
            <div className="shop-inner">
              <div
                className="shop-hero "
                style={{ backgroundImage: `url(${products[0]?.image[0]})` }}
              >
                <div className="hero-part-1">
                  <div className="hero-top">
                    <i class="bi bi-house-door-fill"></i>
                    <i class="bi bi-chevron-right"></i>
                    <p>{list.name}</p>
                  </div>
                  <div className="hero-bottom">
                    <p>{list.name}</p>
                  </div>
                </div>
              </div>
              <div className="shop-body">
                <div className="body-inner row">
                  <div className="shop-section-1 col-3">
                    <div className="sec-1-outer">
                      <div className="shop-filter">
                        <i class="bi bi-funnel"></i> <p>Filterr</p>
                      </div>
                      <div className="shop-stock">
                        <p>Out of Stock</p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={showStock}
                            onChange={toggleStockFilter}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div className="shop-price">
                        <p className="price">Price</p>
                        <div className="price-range">
                          <div className="numb-range row">
                            <div className="min-outer col-6">
                              <div className="min-inner">
                                <p className="">{min}</p>
                              </div>
                            </div>
                            <div className="max-outer col-6">
                              <div className="max-inner">
                                <p className="">{value}</p>
                              </div>
                            </div>
                          </div>

                          <input
                            type="range"
                            min={min}
                            max={max}
                            value={priceRange.max}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="shop-color">
                        <div className="color-outer row">
                          <p>Colors</p>
                          {Array.from(
                            new Set(
                              products.flatMap((product) => product.colors)
                            )
                          ).map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="color-inner col-md-2"
                            >
                              <button
                                className="color"
                                style={{
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  border: selectedColors.includes(color)
                                    ? "1px solid black"
                                    : "none",
                                  transform:
                                    activeColor === color
                                      ? "scale(1.1)"
                                      : "scale(1)",
                                }}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={color}
                                onClick={() => handleColorChange(color)}
                                onDoubleClick={() => handleColorChange(color)}
                              ></button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="shop-polish">
                        <div className="polish-outer row">
                          <p>Polish</p>
                          {Array.from(
                            new Set(
                              products.flatMap((product) => product.polish)
                            )
                          ).map((polish, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="polish-inner  col-md-2"
                            >
                              <button
                                className="polish"
                                style={{
                                  backgroundColor: polish,
                                  borderRadius: "50%",
                                  border: selectedPolishes.includes(polish)
                                    ? "1px solid black"
                                    : "none",
                                  transform:
                                    activePolish === polish
                                      ? "scale(1.1)"
                                      : "scale(1)",
                                }}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={polish}
                                onClick={() => handlePolishChange(polish)}
                                onDoubleClick={() => handlePolishChange(polish)}
                              ></button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="shop-material">
                        <div className="type-outer">
                          <div className="type-inner">
                            <div className="button-outer">
                              <button onClick={materialToggleContentVisibility}>
                                <p>Base Metal</p>{" "}
                                <i
                                  className={`bi ${
                                    materialVisible
                                      ? "bi-caret-up-fill"
                                      : "bi-caret-down-fill"
                                  }`}
                                ></i>
                              </button>
                            </div>
                            {materialVisible && (
                              <div className="type-content">
                                {Object.entries(
                                  products.reduce((acc, product) => {
                                    const baseMetal = product.baseMetal;
                                    if (!acc[baseMetal]) {
                                      acc[baseMetal] = { count: 0, baseMetal };
                                    }
                                    acc[baseMetal].count += 1;
                                    return acc;
                                  }, {})
                                ).map(([baseMetal, { count }]) => (
                                  <div key={baseMetal} className="part">
                                    <div className="sub-part">
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleBaseMetalChange(baseMetal)
                                        }
                                      />
                                      <label>{baseMetal}</label>
                                    </div>
                                    <p>{count}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="shop-section-2 col-9">
                    <div className="sec-2-outer">
                      <div className="sec-2-inner">
                        <div className="top">
                          <p>
                            {products
                              ? (productCount = filterProducts(products).length)
                              : productCount}{" "}
                            Products found
                          </p>
                          <div className="sort">
                            <div class="dropdown">
                              <button
                                class=" dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Sort
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    onClick={() =>
                                      handleSortChange("lowToHigh")
                                    }
                                  >
                                    Price : Low to high
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    onClick={() =>
                                      handleSortChange("highToLow")
                                    }
                                  >
                                    Price : High to low
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom-outer row">
                            {filterProducts(products).map((product) => (
                              <div key={product?._id} className="col-md-4">
                                <ShopCard
                                  key={product._id}
                                  product={{
                                    ...product,
                                    likeMore: product?.liked?.includes(userId),
                                  }}
                                />
                                {console.log(product)}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="shop-outer ">
            <div className="shop-inner">
              <div
                className={`shop-hero `}
                style={{ backgroundImage: `url(${idData?.category?.image})` }}
              >
                <div className="hero-part-1 ">
                  <div className="hero-top">
                    <i class="bi bi-house-door-fill"></i>
                    <i class="bi bi-chevron-right"></i>
                    <p>{idData?.category.categoryName}</p>
                  </div>
                  <div className="hero-bottom">
                    <p>{idData?.category.categoryName}</p>
                  </div>
                </div>
              </div>
              <div className="shop-body ">
                <div className="body-inner row">
                  <div className="shop-section-1 col-3">
                    <div className="sec-1-outer">
                      <div className="shop-filter">
                        <i class="bi bi-funnel"></i> <p>Filter</p>
                      </div>

                      <div className="shop-stock">
                        <p>Out Of Stock</p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={showStock}
                            onChange={toggleStockFilter}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>

                      <div className="shop-price">
                        <p className="price">Price</p>
                        <div className="price-range">
                          <div className="numb-range row">
                            <div className="min-outer col-6">
                              <div className="min-inner">
                                <p className="">{min}</p>
                              </div>
                            </div>
                            <div className="max-outer col-6">
                              <div className="max-inner">
                                <p className="">{value}</p>
                              </div>
                            </div>
                          </div>
                          <input
                            type="range"
                            min={min}
                            max={max}
                            value={priceRange.max}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="shop-color">
                        <div className="color-outer row">
                          <p>Colors</p>
                          {Array.from(
                            new Set(
                              idData?.category?.products?.flatMap(
                                (product) => product.colors
                              )
                            )
                          ).map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="color-inner col-md-2"
                            >
                              <button
                                className="color"
                                style={{
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  border: selectedColors.includes(color)
                                    ? "1px solid black"
                                    : "none",
                                  transform:
                                    activeColor === color
                                      ? "scale(1.1)"
                                      : "scale(1)",
                                }}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={color}
                                onClick={() => handleColorChange(color)}
                                onDoubleClick={() => handleColorChange(color)}
                              ></button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="shop-polish">
                        <div className="polish-outer row">
                          <p>Polish</p>
                          {Array.from(
                            new Set(
                              idData?.category?.products?.flatMap(
                                (product) => product.polish
                              )
                            )
                          ).map((polish, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="polish-inner  col-md-2"
                            >
                              <button
                                className="polish"
                                style={{
                                  backgroundColor: polish,
                                  borderRadius: "50%",
                                  border: selectedPolishes.includes(polish)
                                    ? "1px solid black"
                                    : "none",
                                  transform:
                                    activePolish === polish
                                      ? "scale(1.1)"
                                      : "scale(1)",
                                }}
                                type="button"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={polish}
                                onClick={() => handlePolishChange(polish)}
                                onDoubleClick={() => handlePolishChange(polish)}
                              ></button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="shop-material">
                        <div className="type-outer">
                          <div className="type-inner">
                            <div className="button-outer">
                              <button onClick={materialToggleContentVisibility}>
                                <p>Base Metal</p>{" "}
                                <i
                                  className={`bi ${
                                    materialVisible
                                      ? "bi-caret-up-fill"
                                      : "bi-caret-down-fill"
                                  }`}
                                ></i>
                              </button>
                            </div>

                            {materialVisible && (
                              <div className="type-content">
                                {Object.entries(
                                  idData?.category?.products?.reduce(
                                    (acc, product) => {
                                      const baseMetal = product.baseMetal;
                                      if (!acc[baseMetal]) {
                                        acc[baseMetal] = {
                                          count: 0,
                                          baseMetal,
                                        };
                                      }
                                      acc[baseMetal].count += 1;
                                      return acc;
                                    },
                                    {}
                                  )
                                ).map(([baseMetal, { count }]) => (
                                  <div key={baseMetal} className="part">
                                    <div className="sub-part">
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleBaseMetalChange(baseMetal)
                                        }
                                      />
                                      <label>{baseMetal}</label>
                                    </div>
                                    <p>{count}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shop-section-2 col-9">
                    <div className="sec-2-outer">
                      <div className="sec-2-inner">
                        <div className="top">
                          <p>
                            {idData?.category?.products
                              ? (productCount = filterProducts(
                                  idData?.category?.products
                                ).length)
                              : productCount}
                            Products found
                          </p>
                          <div className="sort">
                            <div class="dropdown">
                              <button
                                class=" dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Sort
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    onClick={() =>
                                      handleSortChange("lowToHigh")
                                    }
                                  >
                                    Price : Low to high
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    onClick={() =>
                                      handleSortChange("highToLow")
                                    }
                                  >
                                    Price : High to low
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom-outer row">
                            {idData &&
                            idData?.category &&
                            idData?.category?.products ? (
                              filterProducts(idData?.category?.products).map(
                                (product) => (
                                  <div key={product?._id} className="col-sm-6 col-md-4 col-6">
                                    <ShopCard
                                      key={product._id}
                                      product={{
                                        ...product,
                                        likeMore:
                                          product?.liked?.includes(userId),
                                      }}
                                    />
                                  </div>
                                )
                              )
                            ) : (
                              <p>Loading products...</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShopPage;
