import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./UpdateProduct.scss";
import axios from "axios";

const UpdateProduct = () => {
  const { id } = useParams();

  console.log("Product ID:", id);

  const navigate = useNavigate();
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryList, setSelectedCategoryList] = useState(null);
  const [image, setImage] = useState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: [],
    price: "",
    sellprice: "",
    colors: [],
    polish: [],
    DesignNo: "",
    returnDays: "",
    baseMetal: "",
    Stone: "",
    stock: false,
    size: [],
    ProductWeight: "",
    productDetail: "",
    material: [],
    NecklaceStyle: "",
    categoryName: "",
    categoryList: "",
    categoryListItem: "",
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/category/allCategory"
        );

        setAllCategory(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);
  console.log(formData);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/product/getProduct/${id}`
        );
        console.log("Response..........new.........", response.data);
        setFormData(response.data.data);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);

  console.log("Form Data.............update....new.........", formData);

  const handleChange = (e) => {
    const { name, checked, value, id } = e.target;
    console.log(name);
    if (name === "stock") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      const { name, value, id } = e.target;
      if (name === "categoryName") {
        setSelectedCategory(value);
        setFormData((prevState) => ({
          ...prevState,
          categoryName: value,
        }));
      } else if (name === "categoryList") {
        setSelectedCategoryList(value);
        setFormData((prevState) => ({
          ...prevState,
          categoryList: value,
        }));
      } else if (name === "categoryListItem") {
        setFormData((prevState) => ({
          ...prevState,
          categoryListItem: value,
        }));
      } else if (name === "image" || name === "colors" || name === "polish") {
        const arrayValue = value.split(",").map((item) => item.trim());
        setFormData((prevState) => ({
          ...prevState,
          [name]: arrayValue,
        }));
      } else if (name === "size") {
        const arrayValue = value.split(",").map(Number);
        setFormData((prevState) => ({
          ...prevState,
          [name]: arrayValue,
        }));
      } else if (name === "materialTitle" || name === "materialCount") {
        setFormData((prevState) => {
          const updatedMaterial = prevState.material.map((item) =>
            item.title === prevState.materialTitle
              ? { ...item, count: value }
              : item
          );
          if (
            updatedMaterial.some(
              (item) => item.title !== prevState.materialTitle
            )
          ) {
            updatedMaterial.push({
              title: prevState.materialTitle,
              count: value,
            });
          }
          return { ...prevState, material: updatedMaterial };
        });
      } else {
        const newValue =
          name === "price" || name === "sellprice" ? Number(value) : value;
        setFormData((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `https://kanha-art-jewellery-drtc.onrender.com/product/updateProduct/${id}`;
      const response = await axios.patch(url, formData);
      console.log("Product updated successfully:", response.data);
      // navigate("/dashboard");
      // window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleMaterialChange = (event, index, key) => {
    const newMaterial = [...formData.material];
    newMaterial[index][key] = event.target.value;
    setFormData({ ...formData, material: newMaterial });
  };

  // Add a new material entry
  const addMaterial = () => {
    setFormData({
      ...formData,
      material: [...formData.material, { title: "", count: "" }],
    });
  };

  // Remove a material entry
  const removeMaterial = (index) => {
    const newMaterial = [...formData.material];
    newMaterial.splice(index, 1);
    setFormData({ ...formData, material: newMaterial });
  };

  const backToDashboard = () => {
    navigate("/dashboard");
    window.scrollTo(0, 0);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    setFormData({ ...formData, image: files });
  };

  return (
    <div className="updateProduct">
      <section>
        <div className="form_data">
          <div className="cross" onClick={backToDashboard}>
            <i className="bi bi-file-x-fill"></i>
          </div>
          <div className="form_heading">
            <h1>Update Product</h1>
          </div>
          <form id="createProductForm" onSubmit={handleSubmit}>
            <div className="form_input">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>{" "}
            <div className="form_input">
              <label for="productImage">Resource Image</label>
              <input
                type="file"
                id="productImage"
                multiple
                onChange={handleImageChange}
                name="productImage"
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="price">Price:</label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="sellprice">Sell Price:</label>
              <input
                type="number"
                id="sellprice"
                name="sellprice"
                value={formData.sellprice}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="colors">Colors (comma-separated):</label>
              <input
                type="text"
                id="colors"
                name="colors"
                value={formData.colors.join(", ")}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="DesignNo">Design No:</label>
              <input
                type="number"
                id="DesignNo"
                name="DesignNo"
                value={formData.DesignNo}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="ReturnDays">Return Days</label>
              <input
                type="number"
                id="ReturnDays"
                name="returnDays"
                value={formData.returnDays}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="polish">Polish (comma-separated):</label>
              <input
                type="text"
                id="polish"
                name="polish"
                value={formData.polish.join(", ")}
                onChange={handleChange}
                required
              />
            </div>{" "}
            <div className="form_input">
              <label htmlFor="Base Metal">Base Metal</label>
              <input
                type="text"
                id="Base Metal"
                name="baseMetal"
                value={formData.baseMetal}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="Stone">Stone</label>
              <input
                type="text"
                id="Stone"
                name="Stone"
                value={formData.Stone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input check-main">
              <label htmlFor="Stock">Stock</label>
              <div className="check-outer">
                <input
                  type="checkbox"
                  id="Stock"
                  name="stock"
                  checked={formData.stock}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form_input">
              <label htmlFor="size">Size (comma-separated):</label>
              <input
                type="text"
                id="size"
                name="size"
                value={formData.size.join(", ")}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="ProductWeight">Product Weight:</label>
              <input
                type="text"
                id="Product Weight"
                name="ProductWeight"
                value={formData.ProductWeight}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="productDetail">Product Details:</label>
              <input
                type="text"
                id="productDetail"
                name="productDetail"
                value={formData.productDetail}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_input add-outer row">
              {formData.material.map((material, index) => (
                <div key={index} className="add-inner col-12">
                  <div className="add-sub-inner">
                    <div className="name-outer">
                      <label htmlFor="color">Color:</label>
                      {index >= 0 && (
                        <div onClick={() => removeMaterial(index)}>
                          <i class="bi bi-trash3" style={{ color: "red" }}></i>{" "}
                        </div>
                      )}
                    </div>
                    <input
                      type="text"
                      placeholder="Material Title"
                      value={material.title}
                      onChange={(e) => handleMaterialChange(e, index, "title")}
                    />
                    <input
                      type="number"
                      placeholder="Material Count"
                      value={material.count}
                      onChange={(e) => handleMaterialChange(e, index, "count")}
                    />
                  </div>
                </div>
              ))}

              <button onClick={addMaterial}>Add Material</button>
            </div>
            <div className="form_input">
              <label htmlFor="NecklaceStyle">Necklace Style</label>
              <input
                type="text"
                id="NecklaceStyle"
                name="NecklaceStyle"
                value={formData.NecklaceStyle}
                onChange={handleChange}
                required
              />
            </div>
            {/* <label htmlFor="CategoryName">Category Name</label>
            <select
              id="CategoryName"
              name="categoryName"
              value={formData.categoryName}
              onChange={handleChange}
              required
            >
              <option value="">Select Category</option>
              {allCategory?.map((category) => (
                <option key={category._id} value={category.categoryName}>
                  {category.categoryName}
                </option>
              ))}
            </select>
            {formData.categoryList && (
              <>
                <label htmlFor="CategoryList">Category List Name</label>
                <select
                  id="CategoryList"
                  name="categoryList"
                  value={formData.categoryList}
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select Subcategory</option>
                </select>
              </>
            )}

            {formData.categoryListItem && (
             <>
                <label htmlFor="categoryListItem">Category List Item</label>
                <select
                  id="categoryListItem"
                  name="categoryListItem"
                  value={formData.categoryListItem}
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select Item</option>
                
              </>
            )}
*/}
            <div className="btn" style={{ backgroundColor: "#005C4B" }}>
              <input type="submit" value="Update Product" />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default UpdateProduct;
