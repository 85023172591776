import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./CreateBlog.scss";
const CreateBlog = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user) {
      navigate("/createBlog");
      window.scrollTo(0, 0);
    } else {
      navigate("/assss");
      window.scrollTo(0, 0);
    }
  }, []);

  const [image, setImage] = useState();

  const history = useNavigate();
  const backToDashboard = () => {
    history("/dashboard");
  };

  const [fields, setFields] = useState([{ id: 1, title: "", content: "" }]);
  const [counter, setCounter] = useState(2);
  const [title, setTitle] = useState();

  const [readingTime, setReadingTime] = useState("");

  const handleReadingTimeChange = (event) => {
    setReadingTime(event.target.value);
  };

  const handleAddFields = () => {
    setFields([...fields, { id: counter, title: "", content: "" }]);
    setCounter(counter + 1);
  };

  const handleInputChange = (id, event) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleRemoveField = (id) => {
    const filteredFields = fields.filter((field) => field.id !== id);
    setFields(filteredFields);
  };

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const handleInputChangetag = (e) => {
    setTagInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      e.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagRemove = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const removeIdFromFields = () => {
    const updatedFields = fields.map(({ id, ...rest }) => rest);
    return updatedFields;
  };
  const getMyListing = async () => {
    const dataToSend = removeIdFromFields();

    const formData = new FormData();
    formData.append("image", image);

    const imageUrl = await axios.post(
      "https://kanha-art-jewellery-drtc.onrender.com/blog/blogImage",
      formData
    );
    if (imageUrl) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://kanha-art-jewellery-drtc.onrender.com/blog/createBlog",
        data: {
          title: title,
          description: dataToSend,
          image: image,
          readingTime: readingTime,
          tags: tags,
        },
      };
      await axios
        .request(config)
        .then((response) => {
          if (response?.data?.status == true) {
            const newData = response?.data?.data;
            history("/dashboard")
          }
          if (response?.data?.status == false) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  return (
    <>
      <div className="createBlog">
        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashboard}>
                <i class="bi bi-file-x-fill"></i>
              </div>
              <div className="form_heading">
                <h1>Create Resource Center</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={handleTitleChange}
                    value={title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                {fields.map((field) => (
                  <div key={field?.id} className=" form-outer">
                    <div className="form_input ">
                      <div className="label-outer d-flex justify-content-between">
                        <label htmlFor="subtitle">Subtitle</label>
                        <label
                          style={{ color: "red", fontSize: "20px" }}
                          onClick={() => handleRemoveField(field.id)}
                        >
                          <i class="bi bi-trash3"></i>
                        </label>
                      </div>

                      <input
                        type="text"
                        name="title"
                        value={field?.title}
                        onChange={(e) => handleInputChange(field?.id, e)}
                        placeholder="Subtitle"
                      />
                    </div>
                    <div className="form_input">
                      <label htmlFor="subtitle">Content</label>
                      <textarea
                        type="text"
                        name="content"
                        value={field.content}
                        onChange={(e) => handleInputChange(field.id, e)}
                        placeholder="Content"
                      />
                    </div>
                    {/* <div className="form_input button-outer">
                      <button onClick={() => handleRemoveField(field.id)}>
                        Remove
                      </button>
                    </div> */}
                  </div>
                ))}
                <div className="add-outer">
                  <button onClick={handleAddFields}>+ Add Fields</button>
                </div>

                <div className="form_input">
                  <label htmlFor="readingTime">Estimate Reading Time</label>
                  <input
                    type="readingTime"
                    onChange={handleReadingTimeChange}
                    value={readingTime}
                    id="readingTime"
                    name="readingTime"
                    placeholder="readingTime"
                  />
                </div>
                <div className="form_input">
                  <label for="productImage">Resource Image</label>
                  <input
                    type="file"
                    id="productImage"
                    onChange={(e) => setImage(e.target.files[0])}
                    name="productImage"
                    required
                  />
                </div>
                <div className="tags">
                  <label>Tags</label>
                  <div className="tags-input-container">
                    <input
                      type="text"
                      className="tags-input"
                      placeholder="Please enter your tags here"
                      value={tagInput}
                      onChange={handleInputChangetag}
                      onKeyDown={handleInputKeyDown}
                    />
                  </div>
                  <div className="tags-list">
                    {tags.map((tag, index) => (
                      <div key={index} className="tag">
                        {tag}
                        <button
                          className="tag-remove"
                          onClick={() => handleTagRemove(index)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="btn"
                  onClick={getMyListing}
                  style={{ backgroundColor: "#005C4B" }}
                >
                  Create Resource Center
                </div>
              </form>
            </div>
          }
        </section>
      </div>

    </>
  );
};

export default CreateBlog;
