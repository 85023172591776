import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./app/pages/Home/Home";
import About from "./app/pages/About/About";
import Contact from "./app/pages/Contact/Contact";
import Product from "./app/pages/singleproduct/singleproduct";
import { SingleBlog } from "./app/pages/singleBlog/singleBlog";
import ResourceCenter from "./app/pages/ResourceCenter/ResourceCenter";
import ShopPage from "./app/pages/shopPage/ShopPage";
import Dashboard from "./app/pages/Dashboard/Dashboard";
import Cart from "./app/pages/Cart/Cart";
import Auth from "./app/pages/Auth/Auth";
import ReturnPolicy from "./app/pages/ReturnPolicy/ReturnPolicy";
import PrivacyPolicy from "./app/pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./app/pages/TermsAndCondition/TermsAndCondition";
import Wishlist from "./app/pages/Wishlist/Wishlist";
import Checkout from "./app/pages/Checkout/Checkout";
import MyAccount from "./app/pages/MyAccount/MyAccount";
import UpdateUser from "./app/pages/UpdateUser/UpdateUser";
import UpdateBlog from "./app/pages/UpdateBlog/UpdateBlog";
import UpdateProduct from "./app/pages/UpdateProduct/UpdateProduct";
import CreateBlog from "./app/pages/CreateBlog/CreateBlog";
import CreateProduct from "./app/pages/CreateProduct/CreateProduct";
import OrderConfirmation from "./app/pages/OrderConfirmation/OrderConfirmation";
import Invoice from "./app/pages/Invoice/Invoice";
import AdminProcess from "./app/pages/AdminProcess/AdminProcess";
import Error from "./app/pages/404/404";
import ShopAll from "./app/pages/ShopAll/ShopAll";
import Header from "./app/components/Header/Header";
import OrderFailed from "./app/pages/orderFailedPage/orderFailedPage";
import Footer from "./app/components/Footer1/Footer1";
import EditBlog from "./app/pages/EditBlog/EditBlog";
import CommingSoon from "./app/pages/CommingSoon/CommingSoon";

function App() {
  return (
    <Router>
      {/* <CommingSoon /> */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/shop/:itemName" element={<ShopPage />} />
        <Route path="/shop/:categoryName" element={<ShopPage />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/singleBlog/:id" element={<SingleBlog />} />
        <Route path="/resourceCenter" element={<ResourceCenter />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/Cart/:id" element={<Cart />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/returnPolicy" element={<ReturnPolicy />} />
        <Route path="/termsAndConditions" element={<TermsAndCondition />} />
        <Route path="/wishlist/:id" element={<Wishlist />} />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/myAccount/:id" element={<MyAccount />} />
        <Route path="/updateUser/:id" element={<UpdateUser />} />
        <Route path="/updateBlog" element={<UpdateBlog />} />
        <Route path="/updateProduct/:id" element={<UpdateProduct />} />
        <Route path="/createBlog" element={<CreateBlog />} />
        <Route path="/createProduct" element={<CreateProduct />} />
        <Route path="/orderConfirmation/:id" element={<OrderConfirmation />} />
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/adminProcess/:id/:userid" element={<AdminProcess />} />
        <Route path="/shop" element={<ShopAll />} />
        <Route path="/orderFailed" element={<OrderFailed />} />
        <Route path="/editBlog/:id" element={<EditBlog />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
