import React, { useEffect, useState } from "react";
import axios from "axios";
import bgImage from "../../assets/SingleblogBackgroundImage.png";
import Post from "../../components/Post/Post";
import { useParams } from "react-router-dom";
import "./singleBlog.scss";


export const SingleBlog = () => {
  const [blog, setBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const { id } = useParams();

  


  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/blog/getBlogById/${id}`
        );
        if (response.data.success) {
          setBlog(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBlog();
  }, []);

  useEffect(() => {
    const fetchAllBlogs = async () => {
      try {
        const response = await axios.get(`https://kanha-art-jewellery-drtc.onrender.com/blog/getBlogs`);
        if (response.data.success) {
          setAllBlogs(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllBlogs();
  }, [blog]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="SB-main">
       
      <div className="SB-outer">
        <div className="singleBlog">
          <div className="common">
            <div className="image-outer">
              <img
                src={blog?.image}
                alt={blog?.title}
                className="background-image"
              />
            </div>

            <div className="card">
              <div className="card-body">
                {blog && (
                  <>
                    <div className="card-time-heading">
                      <span className="card-date">
                        {formatDate(blog.createdAt)}
                      </span>
                      <h1 className="card-main-heading">{blog.title}</h1>
                    </div>
                    <div className="card-single-block">
                      {blog.description.map((desc, descIndex) => (
                        <div key={descIndex} className="third-block">
                          <h4 className="third-heading">{desc.title}</h4>
                          <div className="third-simple-text">
                            {desc.content}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="related-post"><p>Related Posts</p></div>
          <div className=" row-class row">
            {blog &&
              allBlogs
                .filter((item) => item.title == blog.title)
                .slice(0, 3)
                .map((filteredBlog, index) => (
                  <div
                    className="card-out col-md-4 col-sm-4 col-12"
                    key={index}
                  >
                    <Post
                      id={filteredBlog._id}
                      title={filteredBlog.title}
                      description={filteredBlog.description[0].content}
                      image={filteredBlog.image}
                      readingTime={filteredBlog.readingTime}
                      tags={filteredBlog.tags}
                      createdAt={formatDate(filteredBlog.createdAt)}
                    />

                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};
