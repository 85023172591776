import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import img3 from "../../assets/home-cat.png";
import "./ProductCarousel.scss";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ProductCarousel = ({ data }) => {
  const navigate = useNavigate();
  console.log("data->>>>>>", data);
  const productData = new Map(
    data?.map((product, index) => [
      index + 1,
      {
        name: product.title,
        mainPrice: product.price,
        price: product.sellprice,
        image: product.image[0] || img3,
        _id: product._id,
      },
    ])
  );

  console.log("product->>>>>>>>", productData);

  return (
    <Carousel responsive={responsive} className="product-container">
      {[...productData.entries()]?.map(([id, product]) => (
        <div
          key={id}
          className="product-item"
          onClick={() => {
            navigate(`/product/${product._id}`);
            window.scrollTo(0, 0);
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="product-inner">
            <div className="product-img">
              <img
                src={product.image}
                alt={product.name}
                style={{ width: "50rm", height: "50rm" }}
              />
            </div>

            <div className="product-content">
              <h4
                className="name-product"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {product.name}
              </h4>
              <div className="price-outer-div">
                <h6 className="price-product">Pirce: ₹ {product.mainPrice}/-</h6>
                <del className="del-price"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>₹ {product.price}/-</del>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default ProductCarousel;
