import * as actionTypes from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      const newId = action.payload.id;
      console.log(action.payload);

      // Check if the item already exists in the cart
      const existItemIndex = state.cartItems.findIndex(
        (product) => product.id === newId
      );

      if (existItemIndex !== -1) {
        // If the item exists, return the current state without any modification
        return state;
      } else {
        // If the item does not exist, add it to the cart
        return {
          ...state,
          cartItems: [...state.cartItems, { id: newId }],
        };
      }
    case actionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (product) => product.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
