import React, { useEffect, useState } from "react";
import "./Wishlist.scss";
import WishlistCard from "../../components/WishlistCard/WishlistCard";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [products, setProducts] = useState([]);
  //console.log("wishlist", wishlist);

  const handleDelete = (product) => {
    const productId = product._id;
    console.log("->>>>>>", wishlist);
    console.log(setWishlist[0]?.products);

    const updatedWishlist = wishlist.filter((item) => {
      // Filter out the product with the matching productId from the item's products array
      item.products = item.products.filter(
        (product) => product._id !== productId
      );
      // Keep the item if it still has products after filtering
      return item.products.length > 0;
    });
    // Update the wishlist state
    setWishlist(updatedWishlist);
  };

  console.log(wishlist);
  const { id } = useParams();

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/wishlist/getWishlist?userId=${id}`
      );
      setWishlist(response.data.data);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };
  useEffect(() => {
    fetchWishlist();
  }, [id]);

  return (
    <div>
      <div className="wishlist-main">
        <div className="wishlist-inner row">
          <div className="head">
            <h2>Wishlist</h2>
          </div>
          <div className="card-outer row">
            {wishlist && wishlist.length > 0 ? (
              wishlist.map((item, index) =>
                item.products && item.products.length > 0 ? (
                  item.products.map((product, productIndex) => (
                    <div
                      key={productIndex}
                      className="card-inner col-sm-4 col-md-3 col-lg-2 col-6  "
                    >
                      <WishlistCard
                        product={product}
                        onDelete={() => handleDelete(product)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="no-products">
                    <p>
                      No products in your wishlist. <Link to="/">Go back.</Link>
                    </p>
                  </div>
                )
              )
            ) : (
              <div className="no-products">
                <p>
                  No products in your wishlist. <Link to="/">Go back.</Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
