import React, { useState } from "react";
import "./BestCard.scss";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
//done
const BestCard = (item, index) => {
  const navigate = useNavigate();
  const itemData = item?.item;
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;
  console.log("item data ji", itemData);
  const [imageSrc, setImageSrc] = useState(itemData?.image[0]);

  const handleMouseEnter = () => {
    setImageSrc(itemData?.image[1]);
  };

  const handleMouseLeave = () => {
    setImageSrc(itemData?.image[0]);
  };

  const navigateToSingleProduct = () => {
    navigate(`/product/${itemData._id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        key={index}
        className="bestcard-main"
        onClick={() => {
          navigate(`/product/${itemData._id}`);
          window.scrollTo(0, 0);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="bestcard-outer">
          <div className="bestcard-inner">
            <div className="image-outer">
              <div class="mask1 w-100">
                <img src={imageSrc} alt="" />
              </div>
            </div>
            <div className="text-outer">
              <p
                className="name"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {itemData?.title}
              </p>
              <div className="price">
                <div className="pp">
                  <p className="main">Price: ₹{itemData?.price}/-</p>
                  <p className="cross">₹{itemData?.sellprice}/-</p>
                </div>
              </div>
              <button
                onClick={navigateToSingleProduct}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Quick Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestCard;

// .........................

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./BestCard.scss"; // Import the SCSS file

// const BestCard = ({ item, index }) => {
//   const navigate = useNavigate();
//   const itemData = item?.item;

//   const [imageSrc, setImageSrc] = useState(itemData?.image[0]);

//   const handleMouseEnter = () => {
//     setImageSrc(itemData?.image[1]);
//   };

//   const handleMouseLeave = () => {
//     setImageSrc(itemData?.image[0]);
//   };

//   const navigateToSingleProduct = () => {
//     navigate(`/product/${itemData._id}`);
//     window.scrollTo(0, 0);
//   };

//   return (
//     <div
//       key={index}
//       className="bestcard-main"
//       onClick={navigateToSingleProduct}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="bestcard-outer">
//         <div className="bestcard-inner">
//           <div className="image-outer">
//             <img
//               src={imageSrc}
//               alt={itemData?.title}
//               className="bestcard-image"
//             />
//           </div>
//           <div className="text-outer">
//             <p className="name">{itemData?.title}</p>
//             <div className="price">
//               <div className="pp">
//                 {itemData?.price <= itemData?.sellprice ? (
//                   <p className="main">₹{itemData?.sellprice}/-</p>
//                 ) : (
//                   <>
//                     <p className="cross">₹{itemData?.price}/-</p>
//                     <p className="main">₹{itemData?.sellprice}/-</p>
//                   </>
//                 )}
//               </div>
//               <button className="quick-buy-btn">Quick Buy</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BestCard;
