import React, { useState, useEffect } from "react";
import "./Checkout.scss";
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks/api_hook";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const Checkout = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [shipCharge, setShipCharge] = useState(undefined);
  const { data: userData } = useFetch(`/auth/user/${user?._id}`);
  const [view, setView] = useState("part");
  const [discountPrice, setDiscountPrice] = useState(0);
  const [cart, setCart] = useState([]);
  const [inputHandler, setInputHandler] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    phoneNumber: "",
    country: "",
  });
  const authToken = localStorage.getItem("auth_token");

  const [totalAmount, setTotalAmount] = useState(0);
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isWalletChecked, setIsWalletChecked] = useState(false);
  const [walletReducingAmount, setWalletReducingAmount] = useState(0);

  let [finalAmount, setFinalAmount] = useState(0);
  const { id: ProductIdSearch } = useParams();
  const { data } = useFetch(`/api/getProductsInCart/${user?._id}`);

  const getProductDetails = async () => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/getProduct/${ProductIdSearch}`
      );
      const respons = await response?.json();
      if (respons?.success == true) {
        if (respons?.response_code == 200) {
          const data = respons?.data;
          setCart(data);
        } else {
          // toast.error(respons?.message, {
          //   position: "bottom-right",
          //   autoClose: 8000,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "dark",
          // });
        }
      } else {
        if (respons?.response_code == 404) {
          toast.warn("Product Not Found", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        } else if (respons?.response_code == 500) {
          toast.error(respons?.message, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      console.log("->>>>>>>>>>>>", error);
      toast.error("Somethings Wrong", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const fetchWallet = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/wallet/getWalletById/${user?._id}`
      );
      if (response.data) {
        const total = response?.data?.transaction?.reduce(
          (acc, item) => acc + (item?.amount || 0),
          0
        );
        setTotalAmount(total);
      } else {
        console.log("transaction failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeInputHandler = (e) => {
    let { name, value } = e.target;
    if (name === "phoneNumber") {
      inputHandler.phoneNumber = userData?.phoneNumber;
    }
    setInputHandler({ ...inputHandler, [e.target.name]: value });
  };

  const fetchAddressByUserId = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/user/getAddressByUserId/${user?._id}`
      );
      if (response.data.success) {
        setUserAddresses(response.data.data);
        // Check if userAddresses is not empty and set the first address to local storage
        if (response.data.data.length > 0) {
          const firstAddress = response.data.data[0];
          localStorage.setItem("selectedAddress", JSON.stringify(firstAddress));
          setSelectedAddress(firstAddress); // Also update the state if needed
        }
      } else {
        console.log("fetch the address");
      }
    } catch (error) {
      console.log("findinf the errror yr", error);
    }
  };

  const CalculateShippment = async (selectedAddress) => {
    setView("part");
    const number = selectedAddress.zipCode;
    const pinCode = parseInt(number);
    try {
      const response = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/api/ship/calcShipment",
        {
          shipping_postcode: 452010,
          weight: 1,
          declared_value: 1,
          is_return: 0,
        }
      );
      setShipCharge(response.data.shipPrice);
      // toast.success("Proceed with Payment", {
      //   position: "bottom-right",
      //   autoClose: 8000,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "dark",
      // });
    } catch (error) {
      console.log(error);
      console.error("Failed to fetch ship details", error);
    }
  };

  const handleOrderFunction = async (event) => {
    event.preventDefault();
    try {
      if (shipCharge === undefined) {
        alert("Confirmm Address ,Before Placing Order !");
      } else {
        let totalPayAmount = cart.totalPrice || cart.sellprice;
        if (isWalletChecked === true) {
          totalPayAmount = finalAmount;
        }
        const { data } = await axios.post(
          "https://kanha-art-jewellery-drtc.onrender.com/api/pay/phonePePayment",
          {
            userId: user?._id,
            amount: totalPayAmount,
            cartId: cart?.cartId,
            fullName: selectedAddress.fullName,
            street: selectedAddress.street,
            landmark: selectedAddress.landmark,
            city: selectedAddress.city,
            country: selectedAddress.country,
            state: selectedAddress.state,
            zipCode: 452010,
            walletReducingAmount: walletReducingAmount,
          }
        );

        if (data.success) {
          if (data.data == "Good Data") navigate(`/myAccount/${user?._id}`);
          else window.location.replace(data.data);
        }
      }
    } catch (error) {
      console.log("Failed to submit form", error);
    }
  };

  const validateForm = () => {
    const { name, email, street, landmark, city, state, pinCode, country } =
      inputHandler;
    return (
      name && email && street && landmark && city && state && pinCode && country
    );
  };
  const addAddress = async () => {
    try {
      if (!validateForm()) {
        toast.error("Please fill all fields", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        return;
      }
      const item = {
        userId: user?._id,
        fullName: inputHandler.name,
        street: inputHandler.street,
        landmark: inputHandler.landmark,
        email: inputHandler.email,
        city: inputHandler.city,
        state: inputHandler.state,
        zipCode: inputHandler.pinCode,
        country: inputHandler.country,
      };
      localStorage.setItem("selectedAddress", JSON.stringify(item));
      if (validateForm()) {
        const response = await axios.post(
          "https://kanha-art-jewellery-drtc.onrender.com/user/createAddress",
          {
            userId: user?._id,
            fullName: inputHandler.name,
            email: inputHandler.email,
            street: inputHandler.street,
            landmark: inputHandler.landmark,
            city: inputHandler.city,
            state: inputHandler.state,
            zipCode: inputHandler.pinCode,
            country: inputHandler.country,
          }
        );
        if (response.data.success) {
          setUserAddresses((prevAddresses) => [...prevAddresses, item]);
          window.location.reload();
          setView("part");
          // toast.success(`${response.data.message}`, {
          //   position: "bottom-right",
          //   autoClose: 8000,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "dark",
          // });
          return true;
        } else {
          console.error("Failed to add address", response.data.message);
          return false;
        }
      }
    } catch (error) {
      console.error("Failed to add address", error);
      return false;
    }
  };

  const checkboxClickHandler = (item) => {
    setSelectedAddress(item);
    localStorage.setItem("selectedAddress", JSON.stringify(item));
  };

  const addAmountFromWallet = () => {
    setIsWalletChecked(!isWalletChecked);
    if (!isWalletChecked) {
      const reduceWalletAmount = cart?.totalPrice || cart?.sellprice;
      if (reduceWalletAmount < totalAmount) {
        setFinalAmount(0);
        setWalletReducingAmount(reduceWalletAmount);
      } else {
        setFinalAmount(reduceWalletAmount - totalAmount);
        setWalletReducingAmount(totalAmount);
      }
    } else {
      setWalletReducingAmount(0);
    }
  };

  console.log(walletReducingAmount);

  useEffect(() => {
    if (authToken) {
      navigate(`/checkout/${ProductIdSearch}`);
      window.scrollTo(0, 0);
    } else {
      navigate("/auth");
      window.scrollTo(0, 0);
    }
    // fetchUserAddress();
    const storedAddress = localStorage.getItem("selectedAddress");
    if (storedAddress) {
      setSelectedAddress(JSON.parse(storedAddress));
    }
    fetchAddressByUserId();
    fetchWallet();
  }, []);

  useEffect(() => {
    if (ProductIdSearch == "null") {
      setCart(data);
    } else {
      // getProductDetails();
    }
  }, [ProductIdSearch, data]);

  return (
    <div>
      <div className="checkout-main">
        <ToastContainer />
        <div className="checkout-outer">
          <div className="checkout-inner row">
            <div className="section-1 col-md-6">
              <div className="sec-1-outer">
                <div className="sec-1-inner">
                  <div className="head">
                    <h2>Checkout</h2>
                  </div>
                  <div className="body">
                    {view === "part" && (
                      <>
                        <div className="part">
                          <div className="AC-main">
                            <div className="AC-outer">
                              <div className="AC-inner d-flex flex-direction-column align-item-center">
                                <div className="check d-flex align-items-center">
                                  <p>Delivery Address</p>
                                </div>
                                {selectedAddress ? (
                                  <label htmlFor="">
                                    <p>{selectedAddress?.fullName}</p>
                                    <p>{user?.phoneNumber}</p>
                                    <p>
                                      {selectedAddress?.street}
                                      {selectedAddress?.landmark} ,
                                      {selectedAddress?.city}
                                    </p>
                                    <p>
                                      {selectedAddress?.state} ,
                                      {selectedAddress?.country}
                                    </p>
                                    <p>{selectedAddress?.zipCode}</p>
                                  </label>
                                ) : (
                                  <label htmlFor="">
                                    <p>{userAddresses[0]?.fullName}</p>
                                    <p>{user?.phoneNumber}</p>
                                    <p>
                                      {userAddresses[0]?.street}
                                      {userAddresses[0]?.landmark}
                                      {userAddresses[0]?.city}
                                    </p>
                                    <p>
                                      {userAddresses[0]?.state}
                                      {userAddresses[0]?.country}
                                    </p>
                                    <p>{userAddresses[0]?.zipCode}</p>
                                  </label>
                                )}
                                <div className="change d-flex align-items-center">
                                  <button onClick={() => setView("change")}>
                                    Change
                                  </button>
                                </div>
                              </div>
                              <div className="AC-bottom">
                                <button onClick={() => setView("form-part")}>
                                  Add a new address
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="button-outer">
                            <button
                              onClick={() => {
                                CalculateShippment(selectedAddress);
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {view === "change" && (
                      <div className="change">
                        <div className="change-outer">
                          <div className="change-inner">
                            {userAddresses.map((item, index) => (
                              <div
                                key={item._id || index}
                                className="sec-1 d-flex align-items-center"
                              >
                                <div className="check-box">
                                  <input
                                    type="radio"
                                    onClick={() => {
                                      checkboxClickHandler(item);
                                    }}
                                    name="address"
                                  />
                                </div>
                                <label htmlFor="">
                                  <p>{item?.fullName}</p>
                                  <p>{user?.phoneNumber}</p>
                                  <p>
                                    {item?.street},{item?.landmark} ,
                                    {item?.city}
                                  </p>
                                  <p>
                                    {item?.state} , {item?.country}
                                  </p>
                                  <p>{item?.zipCode}</p>
                                </label>
                              </div>
                            ))}

                            <div className="bottom">
                              <div className="button-outer">
                                <button onClick={() => setView("part")}>
                                  Continue
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {view === "form-part" && (
                      <>
                        <div className="form-part">
                          <div className="sub-part">
                            <p>Full Name</p>
                            <input
                              type="text"
                              name="name"
                              value={inputHandler.name}
                              onChange={changeInputHandler}
                              placeholder="Enter Full Name"
                            />
                          </div>
                          <div className="sub-part">
                            <p>Email</p>
                            <input
                              type="email"
                              name="email"
                              value={inputHandler.email}
                              onChange={changeInputHandler}
                              placeholder="Enter email"
                            />
                          </div>
                          <div className="sub-part">
                            <p>Street</p>
                            <input
                              type="tel"
                              name="street"
                              value={inputHandler.street}
                              placeholder="Enter street"
                              onChange={changeInputHandler}
                            />
                          </div>
                          <div className="sub-part">
                            <p>Landmark</p>
                            <input
                              type="text"
                              name="landmark"
                              value={inputHandler.landmark}
                              onChange={changeInputHandler}
                              placeholder="Enter landmark"
                            />
                          </div>
                          <div className="part-2 row">
                            <div className="sub-part2 col-6">
                              <p>Town / City</p>
                              <input
                                className="sub-input"
                                type="text"
                                name="city"
                                value={inputHandler.city}
                                onChange={changeInputHandler}
                                placeholder="Enter City/Town"
                              />
                            </div>
                            <div className="sub-part2 col-6">
                              <p>State</p>
                              <input
                                className="sub-input"
                                type="text"
                                name="state"
                                value={inputHandler.state}
                                onChange={changeInputHandler}
                                placeholder="Enter state"
                              />
                            </div>
                          </div>
                          <div className="part-2 row">
                            <div className="sub-part2 col-6">
                              <p>Pincode</p>
                              <input
                                className="sub-input"
                                type="text"
                                name="pinCode"
                                value={inputHandler.pinCode}
                                onChange={changeInputHandler}
                                placeholder="Enter pincode"
                              />
                            </div>
                            <div className="sub-part2 col-6">
                              <p>Country / Region</p>
                              <input
                                className="sub-input"
                                type="text"
                                name="country"
                                value={inputHandler.country}
                                onChange={changeInputHandler}
                                placeholder="Enter country"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="button-outer">
                            <button onClick={addAddress}>Continue</button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="section-2 col-md-6">
              <div className="sec-2-outer">
                <div className="sec-2-inner">
                  <div className="part-1">
                    <div className="head">
                      <h2>Wallet</h2>
                    </div>
                    <div className="body">
                      <p>Total Amount: </p>
                      <p className="price">₹ {totalAmount}.00</p>
                    </div>
                  </div>
                  <hr />
                  <div className="part-2">
                    <div className="head">
                      <h2>Summary</h2>
                    </div>
                    <div className="body">
                      <div className="sub-body">
                        <p>MRP :</p>
                        <p className="price">
                          ₹{(cart?.totalPrice || 0)?.toLocaleString("en-IN")}
                          .00
                        </p>
                      </div>
                      <div className="sub-body">
                        <p>Discounts :</p>
                        <p className="price">
                          ₹{" "}
                          {cart?.Discount ||
                            (cart?.price - cart?.sellprice > 0 &&
                              cart?.price - cart?.sellprice) ||
                            0}
                          .00
                        </p>
                      </div>
                      <div className="sub-body">
                        <p>Price :</p>
                        <p className="price">
                          ₹
                          {(
                            cart?.totalPrice ||
                            cart?.sellprice ||
                            0
                          )?.toLocaleString("en-IN")}
                          .00
                        </p>
                      </div>

                      <div className="sub-body">
                        <p>
                          Shipping Charge
                          <span class="badge badge-info">Free</span>
                        </p>
                        <p className="price">
                          ₹0 <del>{shipCharge}</del>{" "}
                        </p>
                      </div>
                      <div className="sub-body">
                        <div className="check-out">
                          <input
                            type="checkbox"
                            onChange={addAmountFromWallet}
                          />
                          <label>Pay by Wallet:</label>
                        </div>

                        <p className="price">₹{totalAmount}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="part-3">
                    <p>Total:</p>
                    <p className="price">
                      ₹
                      {isWalletChecked
                        ? finalAmount
                        : (
                            cart?.totalPrice ||
                            cart?.sellprice ||
                            0
                          )?.toLocaleString("en-IN")}
                    </p>
                  </div>
                  <div className="part-4">
                    <p>
                      By completing your purchase you agree to these
                      <Link to="/termsAndConditions"> Terms of Service.</Link>
                    </p>
                  </div>
                  <div className="part-5">
                    <div className="place-button-outer">
                      <div className="button-inner">
                        <button onClick={(e) => handleOrderFunction(e)}>
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="part-6">
                    <p>15-Days Return Guarantee</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
