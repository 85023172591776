import React, { useState, useEffect } from "react";
import "./singleproduct.scss";
import { Link, Navigate } from "react-router-dom";
import truck from "./../../assets/truck.svg";
import ReturnProduct from "./../../assets/return.svg";
import payment from "./../../assets/payment.svg";
import BestCard from "../../components/BestCard/BestCard";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/api_hook";
import HomeCard from "../../components/HomeCard/HomeCard";
import $ from "jquery";
import { useDispatch } from "react-redux";
import COD from "../../assets/COD.png";
import lifetime from "../../assets/lifetime.png";
import { addToCart } from "../../../redux/actions/cartActions";

const Product = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const [productData, setProductData] = useState([]);
  const { id } = useParams();
  const [mostBuyedProduct, setMostBuyedProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [inCart, setInCart] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;
  const { data: cart } = useFetch(`/api/getCartByUser/${userId}`, [
    userId,
    product,
  ]);

  const [isHeartFilled, setIsHeartFilled] = useState(false);

  // const getProductData
  const [inputHandler, setInputHandler] = useState({
    reviewContent: " ",
    rating: " ",
  });
  const [rated, setRated] = useState(0);
  const [color, setColor] = useState("");
  const [polish, setPolish] = useState("");
  const [size, setSize] = useState("");

  // for review
  const [reviews, setReviews] = useState([]);
  const fetchReviewsByDone = async () => {
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/review/getReviewById/${id}`,
      method: "get",
    };
    console.log(" fff", config);

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.success == true) {
          setReviews(response?.data?.data?.reviews);
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Internal Server Error", {
              position: "bottom-right",
              autoClose: 4000,

              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Internal Server Error", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          // toast.warn("Fill Form", {
          //   position: "bottom-right",
          //   autoClose: 4000,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "light",
          // });
        }
      });
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  // get product whick is comming from shop or anywhere
  const fetchProduct = async () => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/getProduct/${id}`
      );
      console.log("single product", response);
      const respons = await response?.json();
      if (respons?.success == true) {
        if (respons?.response_code == 200) {
          const data = respons?.data;
          if (data) setProduct(data);
          console.log("data", data);
          setIsHeartFilled(data?.liked?.includes(userId));
          // toast.success("Product Detail", {
          //   position: "bottom-right",
          //   autoClose: 8000,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "dark",
          // });
        } else {
          toast.error(respons?.message, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      } else {
        if (respons?.response_code == 404) {
          toast.warn("Product Not Found", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        } else if (respons?.response_code == 500) {
          toast.error(respons?.message, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      toast.error("Somethings Wrong", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const [ratingSet, setRatingSet] = useState(5.0);

  const setRating = () => {
    let sumOfRatings = 0;
    reviews?.forEach((review) => {
      sumOfRatings += review.rating;
    });
    const averageRating = sumOfRatings / reviews?.length;
    const formattedAverageRating = averageRating.toFixed(1);
    setRatingSet(formattedAverageRating);
  };

  useEffect(() => {
    fetchProduct();
  }, [reviews]);

  useEffect(() => {
    setRating();
  });

  const fetchProductData = async () => {
    try {
      const response = await fetch(`https://kanha-art-jewellery-drtc.onrender.com/product/allProducts`);
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setProductData(data.data);
        } else {
        }
      } else {
        throw new Error("Product not found");
      }
    } catch (error) {}
  };
  const fetchMostBuyedProduct = async () => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/mostBuyedProduct`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setMostBuyedProduct(data);
        } else {
          console.log("No data received");
        }
      } else {
        throw new Error("Category not found");
      }
    } catch (error) {
      console.error("Error fetching most bought products:", error);
    }
  };
  useEffect(() => {
    fetchProductData();
    fetchMostBuyedProduct();
    fetchReviewsByDone();
  }, []);

  useEffect(() => {
    if (cart) {
      setInCart(
        cart?.products.find((product) => {
          return product?.productId?._id === id;
        })
      );
      setQuantity(
        // cart?.products.find((product) => {
        //   return product?.productId?._id === id;
        // })?.units
        1
      );
    }
  }, [cart, id]);

  const addOnCartClick = async () => {
    if (
      (product.colors.length > 0 && color == "") ||
      (product.polish.length > 0 && polish == "") ||
      (product.size.length > 0 && size == "")
    ) {
      toast.warn("Please Select colors Or Polish Or size", {
        position: "bottom-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }
    try {
      if (userId) {
        await axios.put("https://kanha-art-jewellery-drtc.onrender.com/api/addToCart", {
          productId: id,
          userId: userId,
          units: quantity,
          color: color,
          polish: polish,
          size: size,
          // image : image
        });
        navigate(`/Cart/${userId}`);
        window.scrollTo(0, 0);
      } else {
        toast.error("Please login to add this item to your cart", {
          position: "bottom-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`on cart click ${error.message}`, {
        position: "bottom-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const dispatch = useDispatch();

  const onCartClick = async () => {
    // dispatch(addToCart(id + color + polish + size));
    addOnCartClick();
  };

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showAllReviews, setShowAllReviews] = useState(false);

  useEffect(() => {
    fetchReviews();
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getNumberOfProductsToShow = () => {
    if (viewportWidth >= 992) {
      return 4;
    }
    if (viewportWidth >= 768) {
      return 3;
    }
    if (viewportWidth >= 576) {
      return 2;
    } else {
      return 1;
    }
  };

  const numberOfProductsToShow = getNumberOfProductsToShow();

  const increase = () => {
    setQuantity(quantity + 1);
  };
  const decrease = () => {
    if (quantity != 1) {
      setQuantity(quantity - 1);
    }
  };
  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/review/getReviewById/${id}`
      );

      if (response.data.data.reviews) {
        setReviews(response.data.data.reviews);
      } else {
        toast.error("No reviews found in the response.", {
          position: "bottom-right",
          autoClose: 6000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      // toast.error("INTERNAL ERROR", {
      //   position: "bottom-right",
      //   autoClose: 8000,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "dark",
      // });
    }
  };

  const ReviewAddHandler = async (e) => {
    e.preventDefault();
    if (!user) {
      toast.error("You can't add a review until you log in.", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } else {
      const { reviewContent, rating } = inputHandler;
      if (reviewContent.length == 0) {
        toast.error("Write Review Content", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        return;
      }
      const { data } = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/review/addReview",
        {
          reviewContent: reviewContent,
          rating: rated,
          productId: id,
          userId: userId,
        }
      );

      if (data.success === false) {
        toast.error(data.message, {
          position: "bottom-right",
          autoClose: 5000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        // alert(data.message);
      } else if (data.success) {
        fetchReviews();
        setRated(0);
        setInputHandler({
          ...inputHandler,
          reviewContent: " ",
          rating: " ",
        });
      }
    }
  };

  $(document).ready(function () {
    var list = $(".list");
    var numToShow = 0;
    var buttonViewMore = $("#next");
    var buttonShowLess = $("#prev");
    var numInList = list?.length;

    list.hide();
    list.slice(0, numToShow).show();
    if (numInList > numToShow) {
      buttonViewMore.show();
    }

    buttonViewMore.click(function () {
      var showing = list.filter(":visible")?.length;
      list.slice(showing - 1, showing + numToShow).fadeIn();
      var nowShowing = list.filter(":visible")?.length;

      // Hide "View More" button and show "Show Less" button when all reviews are shown
      if (nowShowing >= numInList) {
        buttonViewMore.hide();
        buttonShowLess.show();
      }
    });

    buttonShowLess.click(function () {
      list.hide();
      list.slice(0, numToShow).show();
      buttonViewMore.show();
      buttonShowLess.hide();
    });
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalCaption, setModalCaption] = useState("");

  const openModal = (imgSrc, altText) => {
    setModalOpen(true);
    setModalImage(imgSrc);
    setModalCaption(altText);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedPolish, setSelectedPolish] = useState(null);

  const handlePolishClick = (polish) => {
    setSelectedPolish(polish);
    setPolish(polish);
  };
  const handleColorClick = (color) => {
    setSelectedColor(color);
    setColor(color);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
    setSize(size);
  };

  console.log("->>>>>>>>>>.", product?.liked?.includes(userId));

  return (
    <>
      <div className="single-product">
        <div className="container-main">
          <div className="breadcrumb">
            <ul className=" row-breadcrumb d-flex flex-direction-column align-items-center">
              <li className="">
                <Link to="/">
                  <i class="bi bi-house-fill"></i>
                </Link>
                <span>
                  <i class="bi bi-chevron-right"></i>
                </span>
              </li>
              <li className=" d-flex align-items-center">
                <p>{product?.title}</p>
                <span>
                  <i class="bi bi-chevron-right"></i>
                </span>
              </li>
            </ul>
          </div>

          <div className="single-img-det row">
            <div className="product-image col-sm-9 col-md-7 col-lg-6">
              <div className=" product-image-outer">
                <div className="image">
                  {product && product.image && (
                    <div
                      className="img"
                      onClick={() =>
                        openModal(product.image[0], "Product Image")
                      }
                    >
                      <img src={product.image[0]} alt="Product Image" />
                    </div>
                  )}
                </div>

                <div className=" image-gallery mx-2">
                  {product && product.image && (
                    <div className="gallery-grid">
                      {product.image.slice(1).map((img, index) => (
                        <div
                          key={index}
                          className="grid-item "
                          onClick={() => openModal(img, "Gallery Image")}
                        >
                          <img src={img} alt="Gallery Image" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {modalOpen && (
                <div className="modal" onClick={closeModal}>
                  <span className="close" onClick={closeModal}>
                    &times;
                  </span>
                  <img
                    className="modal-content"
                    src={modalImage}
                    alt={modalCaption}
                  />
                </div>
              )}
            </div>

            <div className="info-main col-sm-10 col-md-5 col-lg-6 ">
              <div className="row ">
                <div className="title col-12">
                  <h1
                    className=" name"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product?.title}
                  </h1>
                  <div className=" wish">
                    <span className="add-to-wish ">
                      <HomeCard
                        isFilled={isHeartFilled}
                        id={product._id}
                        userId={userId}
                      />
                    </span>
                  </div>
                </div>

                <div className="details col-md-8">
                  <div className="info-secondary row">
                    <div className="other-details col-md-5">
                      <div className="rating">
                        <i class="bi bi-star"></i>{" "}
                        <span>{isNaN(ratingSet) ? "5.0" : ratingSet} </span>{" "}
                        <span className="text">Rating</span>
                      </div>
                      <div className="delivery">
                        <div>
                          <img src={truck} alt="" /> <span>Free Delivery</span>
                        </div>
                      </div>
                    </div>

                    <div className="price mx-1 col-md-5">
                      <p className="mb-0">MRP</p>
                      <h1 className="mb-0">₹ {product?.sellprice}/-</h1>
                      <h6 style={{ color: "grey" }}>(Inc. of all Taxes)</h6>
                    </div>
                  </div>
                </div>

                <div className="actions col-md-4 row">
                  <div className="actions col-12">
                    <div className="quantity d-flex justify-content-between col-12">
                      <span className="icon" onClick={decrease}>
                        <i class="bi bi-dash"></i>
                      </span>
                      <span>{quantity}</span>
                      <span className="icon" onClick={increase}>
                        <i class="bi bi-plus"></i>
                      </span>
                    </div>
                  </div>

                  <div className="add-cart col-12">
                    <button onClick={onCartClick}>Add to Cart</button>
                  </div>
                </div>

                <div className="size-card ">
                  <div className="size-bangle">
                    <h4>Size :</h4>
                    {product &&
                      product?.size &&
                      product?.size.map((size) => {
                        return (
                          <div
                            className={` stone-color-card  ${
                              selectedSize === size ? "select" : ""
                            }`}
                            onClick={() => handleSizeClick(size)}
                          >
                            <h4 className="mt-1">{size}</h4>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="diff-colors">
                  <div className="stone">
                    <h1 style={{ fontFamily: "Quesha", fontWeight: 300 }}>
                      Stone Color
                    </h1>
                    <div className="stone-cardsss">
                      {product &&
                        product.colors &&
                        product.colors.map((color) => (
                          <div
                            className={`card stone-color-card ${
                              selectedColor === color ? "select" : ""
                            }`}
                            onClick={() => handleColorClick(color)}
                            key={color}
                          >
                            <div className="stone-card">
                              <div
                                className="round-color"
                                style={{ backgroundColor: color }}
                              ></div>
                              <div className="color-text">
                                <div>{color}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="line-added"></div>
                  <div className="stone-polish">
                    <h1 style={{ fontFamily: "Quesha", fontWeight: 300 }}>
                      Polish
                    </h1>
                    <div className="stone-cardsss">
                      {product &&
                        product?.polish &&
                        product?.polish?.map((polish) => {
                          return (
                            <div
                              className={`card stone-color-card ${
                                selectedPolish === polish ? "select" : ""
                              }`}
                              onClick={() => handlePolishClick(polish)}
                            >
                              <div className="stone-card">
                                <div
                                  className="round-color"
                                  style={{ backgroundColor: `${polish}` }}
                                ></div>
                                <div className="color-text">
                                  <div>{polish}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                {/* {product && product?.size == [] ? ( */}
                {/* <div className="size-card ">
                    <h1>Size</h1>
                    <div className="size-bangle">
                      {product &&
                        product?.size &&
                        product?.size.map((size) => {
                          return (
                            <div className="card size-card-content">
                              <h2>{size}</h2>
                            </div>
                          );
                        })}
                    </div>
                  </div> */}
                {/* //  ) : (
                  // <div></div>
                //  )} */}

                {/* <div className="col-12"></div> */}
              </div>
            </div>
          </div>

          <div className="featured-info">
            <div className="details row justify-content-between">
              <div className="return item col-md-3 col-6 ">
                <div className="icon">
                  <img src={ReturnProduct} alt="" />
                </div>
                <div className="text">
                  {" "}
                  <h2>
                    15 Days <br /> Free Returns
                  </h2>
                </div>
              </div>
              <div className="cod item col-md-3 col-6">
                <div className="icon">
                  <img src={COD} alt="" />
                </div>
                <div className="text">
                  {" "}
                  <h2>
                    Cash On <br />
                    Delivery
                  </h2>
                </div>
              </div>
              <div className="free-service item col-md-3 col-6">
                <div className="icon">
                  <img src={lifetime} alt="" />
                </div>
                <div className="text">
                  {" "}
                  <h2>
                    Lifetime Free <br /> Reparing Service
                  </h2>
                </div>
              </div>
              <div className="secure-transaction item col-md-3 col-6">
                <div className="icon">
                  <img src={payment} alt="" />
                </div>
                <div className="text">
                  {" "}
                  <h2>
                    Secure <br />
                    Transactions
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="description">
            <div className="data">
              <div className="heading">
                <h1>Product Description</h1>
              </div>
              <div className="details row">
                <div className="col-md-4 item">
                  <div className="row">
                    <p className="col-6"> Design No. </p>
                    <p className="col-6">{product?.DesignNo} </p>
                  </div>
                  <div className="row">
                    <p className="col-6">Stone </p>
                    <p className="col-6">{product?.Stone} </p>
                  </div>
                </div>
                <div className="col-md-4 item">
                  <div className="row">
                    <p className="col-6">Base Metal</p>
                    <p className="col-6">{product?.baseMetal}</p>
                  </div>
                  <div className="row">
                    <p className="col-6">Product Weight (Gms)</p>
                    <p className="col-6">{product?.ProductWeight}</p>
                  </div>
                </div>
                <div className="col-md-4 item">
                  <div className="row">
                    <p className="col-6"> Product Details</p>
                    <p className="col-6">{product?.productDetail}</p>
                  </div>
                  <div className="row">
                    <p className="col-6"> Ring Type</p>
                    <p className="col-6">{product?.NecklaceStyle}</p>
                  </div>
                </div>
              </div>
              <div className="text">
                <p>
                  Kanha Art Jewellery’s Fashion Jewellery{" "}
                  {product?.categoryId?.categoryName}
                </p>
                <p>{product?.description}</p>
              </div>
            </div>
          </div>
          <div className="additional-information">
            <div className="details">
              <h3>Additional Information</h3>
              <p>Country of Origin: India</p>
              <p>Brand Owned and Marketed by: Kanha Art Jewellery Pvt. Ltd.</p>
              <p>
                #45, 12, 3rd Floor, Lorem ipsum dolor sit amet, consectetur
                adipiscing elit 120001
              </p>
            </div>
          </div>

          <div className="review-main">
            <div className="review-outer">
              <div className="review-inner">
                <div className="head">
                  <h5>Add Reviews</h5>
                </div>
                <div className="body">
                  <div className="body-top">
                    <div className="stars">
                      {Array.apply(null, { length: 5 }).map((e, i) => (
                        <i
                          class={i >= rated ? `bi bi-star` : `bi bi-star-fill`}
                          id="review-icon"
                          onClick={() => setRated(i + 1)}
                          required="true"
                        ></i>
                      ))}
                    </div>

                    <textarea
                      id=""
                      rows="5"
                      name="reviewContent"
                      onChange={onChangeInputHandler}
                      value={inputHandler?.reviewContent}
                      placeholder="Please leave your review here..."
                    ></textarea>
                    <div className="button-outer">
                      <button onClick={ReviewAddHandler}>Send</button>
                    </div>
                  </div>
                  <div className="body-bottom-main">
                    {reviews?.map((item, index) => {
                      // Only render the first review if not showing all
                      if (!showAllReviews && index > 2) return null;
                      return (
                        <div className="body-bottom row">
                          <div className="part-1 col-2">
                            <div className="icon-outer">
                              <i class="bi bi-person-circle"></i>
                            </div>
                          </div>

                          <div className="part-2 col-10">
                            <div className="subpart-1">
                              <div className="stars">
                                {Array.apply(null, { length: 5 }).map(
                                  (e, i) => (
                                    <i
                                      class={
                                        i >= item?.rating
                                          ? `bi bi-star`
                                          : `bi bi-star-fill`
                                      }
                                      id="review-icon"
                                    ></i>
                                  )
                                )}
                              </div>
                              <div className="name">
                                <p>{item?.userId?.fullName}</p>
                              </div>
                            </div>
                            <div className="subpart-2">
                              <p>{item?.review}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {reviews && reviews?.reviews?.length > 2 ? (
                      <div className="body-bottom-button">
                        {showAllReviews ? (
                          <button onClick={() => setShowAllReviews(false)}>
                            Show Less
                          </button>
                        ) : (
                          <button onClick={() => setShowAllReviews(true)}>
                            View More
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="best-seller">
            <div className="head">
              <h6>Best Seller</h6>
              <p>
                Our popular products are so beautyful to see that the shoppers{" "}
                <br />
                are easily attracted to them.
              </p>
            </div>
            <div className="body row">
              {mostBuyedProduct
                .slice(0, numberOfProductsToShow)
                .map((item, index) => (
                  <div
                    key={index}
                    className="inner col-6 col-sm-6 col-md-4 col-lg-3 "
                  >
                    <BestCard item={item} index={index} />
                  </div>
                ))}
            </div>
            <div className="all-button">
              <div className="outer">
                <button
                  onClick={() => {
                    navigate("/shop");
                    window.scrollTo(0, 0);
                  }}
                >
                  View More collection
                </button>
              </div>
            </div>
          </div>
          <div className="shop-by-category">
            <div className="category-outer">
              <div className="category-inner">
                <div className="head">
                  <h6>Shop Other Items</h6>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas est similique aspernatur nobis <br /> repellat quaerat
                    obcaecati possimus autem!
                  </p>
                </div>
                <div className="mid">
                  <div className="inner row">
                    {Array.isArray(productData) &&
                      productData.slice(0, 4).map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              navigate("/shop");
                              window.scrollTo(0, 0);
                            }}
                            className="cat col-6 col-md-3"
                          >
                            <img src={item?.image[0]} alt="" />
                            <div className="overlay">
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="all-button">
              <div className="outer">
                <button
                  onClick={() => {
                    navigate("/shop");
                    window.scrollTo(0, 0);
                  }}
                >
                  View More collection
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default Product;
