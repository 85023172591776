import React, { useState, useEffect } from "react";
import "./WishlistCard.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/actions/cartActions";
import { addToLike } from "../../../redux/actions/likeActions";
import "react-toastify/dist/ReactToastify.css";

const WishlistCard = (props) => {
  const navigate = useNavigate();
  const [isDeleted, setIsDeleted] = useState(false);
  const { id } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user._id;

  const { product, onDelete } = props;
  console.log(product, "product ji");

  const handleAddToCart = async (id, productId) => {
    dispatch(addToCart(productId));
  };

  const handleBuyNow = () => {
    navigate(`/checkout/${product._id}`);
    window.scrollTo(0, 0);
  };

  const toggleHeartColor = async () => {
    try {
      const updateResponse = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/updateProduct/${product._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: product._id,
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update product liked status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getwishlist = async (productId) => {
    try {
      const response = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/wishlist/wishlist",
        {
          userId: userId,
          productId: productId,
        }
      );
      if (response.data) {
        toggleHeartColor();
        setIsDeleted(true);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleDelete = async (productId) => {
    if (productId) dispatch(addToLike(productId));
    getwishlist(productId);
  };

  // const addOnCartClick = async () => {
  //   try {
  //     if (userId) {
  //       await axios.put("https://kanha-art-jewellery-drtc.onrender.com/api/addToCart", {
  //         productId: product._id,
  //         userId: userId,
  //         units: 1,
  //       });
  //       navigate(`/Cart/${userId}`);
  //       window.scrollTo(0, 0);
  //     } else {
  //       toast.error("Please login to add this item to your cart", {
  //         position: "bottom-right",
  //         autoClose: 8000,
  //         pauseOnHover: true,
  //         draggable: true,
  //         theme: "dark",
  //       });
  //     }
  //   } catch (error) {
  //     toast.error(`on cart click ${error.message}`, {
  //       position: "bottom-right",
  //       autoClose: 8000,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "dark",
  //     });
  //   }
  // };

  const dispatch = useDispatch();

  const onCartClick = async (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };

  if (isDeleted) {
    return null;
  }

  return (
    <div>
      <div className="WC-main">
        <div className="WC-outer">
          <div className="WC-inner">
            <div className="section-1">
              <div className="image-outer">
                {/* <img src={product.image} alt="" /> */}
                <img
                  src="https://raerproductiondata.s3.ap-south-1.amazonaws.com/products/1727264093799_Kanha_Jewl_1st_K.C%20%2822%29.webp"
                  alt=""
                />
                <div
                  className="delete-icon"
                  onClick={() => {
                    onDelete(product._id);
                    handleDelete(product._id);
                  }}
                >
                  <i className="bi bi-archive-fill"></i>
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="content-outer">
                <div className="name">
                  <p>{product.title}</p>
                </div>
                <div className="price">
                  <p className="main">₹{product.sellprice}/-</p>
                  <p className="cross">₹{product.price}/-</p>
                </div>
                <div class="dropdown">
                  <p className="basemetal">Police {product.polish}</p>
                </div>

                <div className="buttons-out row">
                  {/* <div className="buy col-md-6 col-10">
                    <div className="but-outer">
                      <button className="buy-but" onClick={handleBuyNow}>
                        Buy Now
                      </button>
                    </div>
                  </div> */}
                  <div
                    className="cart col-md-12 text-cente mt-3"
                    onClick={() => handleAddToCart(id, product._id)}
                  >
                    <div className="but-outer">
                      <button
                        className="cart-but"
                        onClick={() => onCartClick(product._id)}
                      >
                        Add to Cart
                      </button>
                    </div>
                    {/* <div className="icon-outer">
                      <i class="bi bi-bag"
                        onClick={() => onCartClick(product._id)}
                        ></i>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistCard;
