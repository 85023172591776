import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import mini1 from "../../assets/dashboard-mini-2.png";
import order from "../../assets/01 Orders.png";
import amount from "../../assets/02 Amount.png";
import Users from "../../assets/03 Users.png";
import Products from "../../assets/04 Products.png";
import graph1 from "../../assets/graph1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Chart } from "react-google-charts";
import GraphRevenue from "../../components/graphRevenue/graphRevenue";
import { useFetch } from "../../hooks/api_hook";

import { confirmAlert } from "react-confirm-alert";
//done
import "react-confirm-alert/src/react-confirm-alert.css";

const Dashboard = () => {
  const { data: graphData } = useFetch("/api/getAllGraph");
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("dashboard");
  const [blogs, setBlogs] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchBlog, setSearchBlog] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [image, setImage] = useState();
  const [fields, setFields] = useState([{ id: 1, title: "", content: "" }]);
  const [counter, setCounter] = useState(2);
  const [title, setTitle] = useState();
  const [BolgId, setBolgId] = useState(null);
  const [readingTime, setReadingTime] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [productNameProduct, setProductNameProduct] = useState("");
  const [searchResultProduct, setSearchResultProduct] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();
  const [allproducts, setAllproducts] = useState([]);
  const [showEditBlog, setShowEditBlog] = useState(false);
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [shipStatus, setShipStatus] = useState([]);
  const [shipment, setShipments] = useState([]);
  const [returnOrder, setReturnOrder] = useState([]);
  const options = {
    hAxis: {
      title: "Time",
    },
    vAxis: {
      title: "Revenue",
    },
    series: {
      1: { curveType: "function" },
    },
  };
  let processed,
    rejected,
    Approved,
    countApproved = 0,
    countRejected = 0,
    countProcessing = 0;
  let currentOrder,
    previousApprovedCount = 0,
    previousRejectedCount = 0,
    previousProcessingCount = 0;

  const [cancelReason, setCancelReason] = useState("");
  const [cancelProductId, setCancelProductId] = useState(null);

  function diff_months(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  }

  useEffect(() => {
    graphData?.map((dataSet, index) => {
      currentOrder = new Date();
      let previousOrder = new Date(dataSet.timestamps);

      var diff = currentOrder.getMonth() - previousOrder.getMonth();

      if (diff_months(currentOrder, previousOrder) !== 0) {
        if (dataSet?.orderStatus === "PROCESSING") {
          previousProcessingCount = previousProcessingCount + 1;
        }
        if (dataSet?.orderStatus === "REJECTED") {
          previousRejectedCount = previousRejectedCount + 1;
        }
        if (dataSet?.orderStatus === "APPROVED") {
          previousApprovedCount = previousApprovedCount + 1;
        }
      }

      if (diff_months(currentOrder, previousOrder) === 0) {
        if (dataSet?.orderStatus === "PROCESSING") {
          countProcessing = countProcessing + 1;
        }
        if (dataSet?.orderStatus === "REJECTED") {
          countRejected = countRejected + 1;
        }
        if (dataSet?.orderStatus === "APPROVED") {
          countApproved = countApproved + 1;
        }
      }
    });
    setDiffData({
      old: [
        ["Name", "orderStatus"],
        ["approved", previousApprovedCount],
        ["rejected", previousRejectedCount],
        ["processing", previousProcessingCount],
      ],
      new: [
        ["Name", "orderStatus"],
        ["approved", countApproved],
        ["rejected", countRejected],
        ["processing", countProcessing],
      ],
    });
  }, [graphData]);
  const [currentRevenue, setCurrentRevenue] = useState(0);
  const [previousMonothRevenue, setPreviousMonthRevenue] = useState(0);

  useEffect(() => {
    if (orders) {
      let total = 0;
      let previousRevenue = 0;
      for (let i = 0; i < orders?.length; i++) {
        if (diff_months(new Date(), new Date(orders[i].timestamps)) === 0) {
          total = orders[i]?.amount;
        } else if (
          diff_months(new Date(), new Date(orders[i].timestamps)) === 1
        ) {
          previousRevenue += orders[i].amount;
        }
      }
      setCurrentRevenue(total);
      setPreviousMonthRevenue(previousRevenue);
    }
  }, [orders]);

  const onLogout = () => {
    confirmAlert({
      title: "Logout",

      buttons: [
        {
          label: "OK",
          onClick: () => {
            localStorage.clear();
            window.location.href = "/auth";
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            navigate("/dashboard");
          },
        },
      ],
    });
  };

  const backToDashboard = () => {
    history("/dashboard");
  };

  const handleReadingTimeChange = (event) => {
    setReadingTime(event.target.value);
  };

  const handleAddFields = () => {
    setFields([...fields, { id: counter, subtitle: "", content: "" }]);
    setCounter(counter + 1);
  };

  const handleInputChange = (id, event) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleRemoveField = (id) => {
    const filteredFields = fields.filter((field) => field.id !== id);
    setFields(filteredFields);
  };

  const handleInputChangetag = (e) => {
    setTagInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      e.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTagRemove = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const removeIdFromFields = () => {
    const updatedFields = fields.map(({ id, ...rest }) => rest);
    return updatedFields;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/auth/users"
        );
        if (response.data.success) {
          setUsers(response.data.data);
          setSearchResult(response.data.data);
        } else {
          console.log("kjh", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/api/getAllOrders"
        );
        const orders = response?.data?.data;
        // let shipments = []; // This will hold the approvalStatus for each order

        // // Fetch approvalStatus for each order and store it in the shipments array
        // for (const order of orders) {
        //   const shipmentResponse = await axios.get(
        //     `https://kanha-art-jewellery-drtc.onrender.com/api/order/${order._id}`
        //   );
        //   shipments.push(shipmentResponse?.data?.approvalStatus); // Store the approvalStatus for this order
        // }

        // Process and set orders and shipments
        let tempTotalAmount = 0;
        orders.forEach((order) => {
          tempTotalAmount += order?.amount;
        });
        setOrders(orders);
        setTotalAmount(tempTotalAmount);
        // setShipments(shipments); // Set the shipments data, which now includes the approvalStatus for each order
      } catch (error) {
        toast.error("Error while fetching Orders", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    };

    fetchOrders();
  }, []);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm.length == 0) {
      setSearchResult(users);
    }
    setSearchTerm(searchTerm);
    const result = users.filter((user) =>
      user._id.slice(-5).includes(searchTerm.slice(-2))
    );
    setSearchResult(result);
  };

  const handleProductInputChange = (event) => {
    const productNameProduct = event.target.value;
    setProductNameProduct(event.target.value);

    if (productNameProduct.length == 0) {
      setSearchResultProduct(allproducts);
      return;
    }

    const resultProduct = allproducts.filter((productProduct) =>
      productProduct.title
        .toLowerCase()
        .includes(productNameProduct.toLowerCase().trim())
    );
    setSearchResultProduct(resultProduct);
  };

  const search = async () => {
    if (searchField !== "") {
      setSearchPerformed(true);
      try {
        const { data } = await axios.post(
          `https://kanha-art-jewellery-drtc.onrender.com/blog/searchBlog`,
          {
            search: searchField,
          }
        );
        setSearchBlog(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        toast.error(`${error.message}`, {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setSearchBlog([]);
      }
    } else {
      setSearchBlog([]);
      setSearchPerformed(false);
    }
  };

  useEffect(() => {
    search(searchField);
  }, [searchField]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://kanha-art-jewellery-drtc.onrender.com/product/allProducts"
      );
      if (Array.isArray(response.data.data)) {
        setAllproducts(response.data.data);
        setSearchResultProduct(response.data.data);
      } else {
        toast.error("Error while fetching products", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setAllproducts([]);
      }
    } catch (error) {
      toast.error("Caught error in Products", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      setAllproducts([]);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.role.role === "Admin") {
        navigate("/dashboard");
        window.scrollTo(0, 0);
      } else {
        navigate("/");
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/auth");
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/blog/getblogs`
      );
      if (response.data.success) {
        setBlogs(response.data.data);
      } else {
        toast.error("Error while fetching Blogs", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Caught error in Blogs", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  function deleteResourceHandler(id) {
    try {
      fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/blog/deleteBlogById/${id}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Blog deleted!!") {
            toast.success(data.message, {
              position: "bottom-right",
              autoClose: 8000,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
            });
            fetchBlogs();
          }
        });
    } catch (error) {
      toast.error(`delete the resource${error.message}`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  }

  function onDelete(id) {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      deleteResourceHandler(id);
    }
  }

  const handleDelete = async (productId) => {
    try {
      const response = await axios.delete(
        `https://kanha-art-jewellery-drtc.onrender.com/product/deleteProduct/${productId}`
      );
      toast.success("Product deleted successfully", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      fetchProducts();
    } catch (error) {
      toast.error("Error while deleting the product", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleShowEditBlog = (blog, id) => {
    navigate(`/editBlog/${id}`);

    setBolgId(id);
    setTags(blog?.tags);
    setTitle(blog?.title);
    const newFields = blog?.description.map((item, index) => ({
      id: index + 1,
      ...item,
    }));

    setFields(newFields);
    setImage(blog?.image);
    setReadingTime(blog?.readingTime);

    // setShowEditBlog(true);
  };

  const handleCloseModal = () => {
    console.log("Closing modal");
    setShowEditBlog(false);
  };

  const handleSaveButton = async () => {
    const dataToSend = removeIdFromFields();
    try {
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://kanha-art-jewellery-drtc.onrender.com/blog/updateBlogById/${BolgId}`,
        data: {
          title: title,
          description: dataToSend,
          image: image,
          readingTime: readingTime,
          tags: tags,
        },
      };
      await axios
        .request(config)
        .then((response) => {
          if (response?.data?.success == true) {
            fetchBlogs();
          }
          if (response?.data?.success == false) {
          }
        })
        .catch((error) => {
          toast.error("Error while Updating Blog", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        });
    } catch (error) {
      toast.error("Caught error in updating Blog", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const [activeFilter, setActiveFilter] = useState({
    filter: "",
  });
  const applyFilter = (e, index) => {
    if (index === 2) {
      setActiveFilter({ filter: e.target.value });
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/user/allAddress`
        );
        if (response.data.success) {
          setAddress(response.data.data);
        } else {
          toast.error("Error while fetching Address", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      } catch (error) {
        toast.error("Caught error in Address", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    };
    fetchAddress();
  }, []);

  useEffect(() => {
    const fetchUsersAndAddresses = async () => {
      try {
        const usersResponse = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/auth/users"
        );
        if (!usersResponse.data.success) {
          throw new Error("Error while fetching Users");
        }
        const users = usersResponse.data.data;

        const addressesResponse = await axios.get(
          "https://kanha-art-jewellery-drtc.onrender.com/user/allAddress"
        );
        if (!addressesResponse.data.success) {
          throw new Error("Error while fetching Addresses");
        }
        const addresses = addressesResponse.data.data;

        const combinedData = users.map((user) => {
          const address = addresses.find((addr) => addr.userId === user._id);
          return {
            ...user,
            phoneNumber: user.phoneNumber,
            fullName: address?.fullName,
            email: address?.email,
          };
        });

        setCombinedData(combinedData);
      } catch (error) {
        toast.error("Error fetching data", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    };

    fetchUsersAndAddresses();
  }, []);

  const [diffdata, setDiffData] = useState({ old: [], new: [] });

  const getAllReturnOrderRequest = () => {
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/return/getReturnDetails`,
      method: "get",
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data?.data);
        if (response?.data?.status == true) {
          setReturnOrder(response?.data?.data);
          toast.success("Case Study get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  const handleReturn = (ReturnId) => {
    console.log(ReturnId);
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/api/ship/createRet`,
      method: "post",
      data: {
        orderId: ReturnId,
        userId: user._id,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data?.data);
        if (response?.data?.status == true) {
          setReturnOrder(response?.data?.data);
          toast.success("Case Study get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  const handleCancel = (ReturnId) => {
    if (cancelReason.length == 0) {
      toast.warn("Fill Reason Of Cancel", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/return/cancelReturnOrder`,
      method: "post",
      data: {
        reasonOfCancel: cancelReason,
        ReturnId,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data?.data);
        if (response?.data?.status == true) {
          setReturnOrder(response?.data?.data);
          toast.success("Case Study get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  const handlePay = (ReturnId) => {
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/api/pay/refund`,
      method: "post",
      data: {
        orderId: ReturnId,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Hello");
        console.log(response?.data?.data);
        if (response?.data?.status == true) {
          setReturnOrder(response?.data?.data);
          toast.success("Case Study get Successfully", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    getAllReturnOrderRequest();
  }, []);

  return (
    <div>
      <div className="DB-main">
        <div className="DB-outer">
          <div className="DB-inner row ">
            <div className="section-1 col-md-3">
              <div className="sec-1-top">
                <div className="part">
                  <button
                    name="dashboard"
                    className={`part-outer ${
                      activeButton === "dashboard" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("dashboard")}
                  >
                    <i className="bi bi-house"></i>
                    <p>Dashboard</p>
                  </button>
                </div>
                <div className="part">
                  <button
                    name="order"
                    className={`part-outer ${
                      activeButton === "orders" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("orders")}
                  >
                    <i className="bi bi-basket3"></i> <p>All Orders</p>
                  </button>
                </div>
                <div className="part">
                  <button
                    name="blog"
                    className={`part-outer ${
                      activeButton === "blogs" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("blogs")}
                  >
                    <i className="bi bi-book"></i> <p>Blogs</p>
                  </button>
                </div>
                <div className="part">
                  <button
                    name="product"
                    className={`part-outer ${
                      activeButton === "products" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("products")}
                  >
                    <i className="bi bi-bag"></i> <p>Products</p>
                  </button>
                </div>
                <div className="part">
                  <button
                    name="return"
                    className={`part-outer ${
                      activeButton === "return" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("return")}
                  >
                    <i className="bi bi-bag"></i> <p>Return Order</p>
                  </button>
                </div>
              </div>

              <div className="sec-1-mid">
                <div className="mid-outer">
                  <p>Account Pages</p>
                </div>
              </div>
              <div className="sec-1-bottom">
                <div className="part">
                  <button
                    className={`part-outer ${
                      activeButton === "user" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("user")}
                  >
                    <i className="bi bi-person"></i> <p>User</p>
                  </button>
                </div>
                <div className="part">
                  <button
                    className={`part-outer ${
                      activeButton === "logout" ? "active" : ""
                    }`}
                    onClick={onLogout}
                  >
                    <i className="bi bi-box-arrow-left"></i> <p>Logout</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="section-2 col-md-9">
              {activeButton === "dashboard" ? (
                <div className="sec-2-outer ">
                  <div className="sec-2-top row ">
                    <div className="top-part-outer right col-md-3 col-6">
                      <div className="gapping">
                        <div className="image-outer">
                          <img src={order} width={"45px"} alt="" />
                        </div>
                        <div className="text-outer">
                          <p className="numb">{orders?.length}</p>
                          <p className="desc">No. of Orders</p>
                        </div>
                      </div>
                    </div>
                    <div className="top-part-outer col-md-3 col-6">
                      <div className="gapping">
                        <div className="image-outer">
                          <img src={amount} width={"45px"} alt="" />
                        </div>
                        <div className="text-outer">
                          <p className="numb">{totalAmount}</p>{" "}
                          {/* Display totalAmount here */}
                          <p className="desc">Total Amount</p>
                        </div>
                      </div>
                    </div>
                    <div className="top-part-outer col-md-3 col-6">
                      <div className="gapping">
                        <div className="image-outer">
                          <img src={Users} width={"68px"} alt="" />
                        </div>
                        <div className="text-outer">
                          <p className="numb">{users.length}</p>{" "}
                          {/* Display totalAmount here */}
                          <p className="desc">No. of Users</p>
                        </div>
                      </div>
                    </div>

                    <div className="top-part-outer left col-md-3 col-6">
                      <div className="gapping">
                        <div className="image-outer">
                          <img src={Products} width={"56px"} alt="" />
                        </div>
                        <div className="text-outer">
                          <p className="numb">{allproducts.length}</p>
                          <p className="desc">No. of Products</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sec-2-mid">
                    <div className="mid-outer row">
                      <div className="mid-part1-outer col-md-6">
                        <div className="gapping">
                          <div className="mid-part-inner">
                            <div className="total">
                              <p>TOTAL ORDERS</p>
                            </div>
                            <div className="week">
                              <p>
                                <span>(+23)than</span> last week
                              </p>
                            </div>
                            <div className="graphs">
                              <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="180px"
                                diffdata={diffdata}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mid-part-outer col-md-6">
                        <div className="gapping">
                          <div className="mid-part-inner">
                            <div className="total">
                              <p>Product requirement over the months</p>
                            </div>
                            <div className="week">
                              <p>
                                <span> (+5)</span> more in 2024
                              </p>
                            </div>
                            <div className="graphs">
                              <GraphRevenue />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeButton === "orders" ? (
                <div className="sec-2-outer-orders">
                  <div className="order-inner">
                    <div className="sec-2-order-head">
                      <div className="order-top-side">
                        <h2>All Orders</h2>
                        <p>Active Orders</p>
                      </div>
                      <div className="order-top-side row">
                        <div className="search-outer col-12"></div>
                        <div class="dropdown col-12">
                          <select
                            type="text"
                            name="input"
                            id="filterDropdown"
                            placeholder="Short by:Newest "
                            onChange={(e) => applyFilter(e, 2)}
                            value={activeFilter.filter}
                          >
                            <option value=""> Filter </option>
                            <option value="By ShipRocket">By ShipRocket</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="sec-2-order-body">
                      <div className="order-body-inner-top row ">
                        <div className="order-part col-1">
                          <p>S. No.</p>
                        </div>
                        <div className="order-part col-2">
                          <p>OrderId</p>
                        </div>
                        <div className="order-part col-1">
                          <p>Price</p>
                        </div>
                        <div className="order-part col-1">
                          <p>Status</p>
                        </div>
                        <div className="order-part col-2">
                          <p>Delievery </p>
                        </div>
                        <div className="order-part col-2">
                          <p>Shippment Order Status</p>
                        </div>

                        <div className="order-part col-2">
                          <p>Order On</p>
                        </div>
                        <div className="order-part col-1">
                          <p>Process Order</p>
                        </div>
                      </div>
                      {orders
                        .filter((order) => {
                          if (activeFilter.filter === "") return true;
                          return order.status === activeFilter.filter;
                        })
                        .map((order, index) => (
                          // console.log("==============================",order?.user)
                          <div
                            key={index}
                            className="order-body-inner-bottom row"
                          >
                            <div className="order-part col-1">
                              <p>{index + 1}</p>
                            </div>
                            <div className="order-part col-2">
                              <Link
                                to={`/orderConfirmation/${order?._id}?userId=${order?.user}`}
                              >
                                ...{order?._id.substring(18, 24)}
                              </Link>
                            </div>

                            <div className="order-part col-1">
                              <p>{order?.amount}</p>
                            </div>
                            <div className="order-part col-1">
                              <p>{order?.orderStatus}</p>
                            </div>
                            <div className="order-part col-2">
                              <p>{order?.status || "--"}</p>
                            </div>
                            <div className="order-part col-2">
                              <p>{shipment[index] || "No Approval Status"}</p>
                            </div>

                            <div className="order-part col-2">
                              <p>
                                {new Date(
                                  order?.createdAt
                                ).toLocaleDateString()}
                              </p>
                            </div>
                            <div className="order-part col-1">
                              <Link
                                to={`/adminProcess/${order?._id}/${order?.user}`}
                              >
                                <i class="bi bi-check-square"></i>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : activeButton === "blogs" ? (
                <div className="sec-2-outer-blogs">
                  <div className="blogs-inner">
                    <div className="sec-2-blogs-head">
                      <div className="blogs-top-side">
                        <h2>All Blogs</h2>
                        <Link to="/createBlog">Create Blog</Link>
                      </div>
                      <div className="blogs-top-side">
                        <div className="search-outer">
                          <input
                            type="text"
                            placeholder="Type here to search"
                            value={searchField}
                            onChange={(e) => setSearchField(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sec-2-blogs-body">
                      <div className="blogs-body-inner-top row">
                        <div className="blogs-part col-1">
                          <p>S. No.</p>
                        </div>
                        <div className="blogs-part col-2">
                          <p>Image</p>
                        </div>
                        <div className="blogs-part col-2">
                          <p>Name</p>
                        </div>
                        <div className="blogs-part col-5">
                          <p>Description</p>
                        </div>
                        <div className="blogs-part col-2">
                          <p>Action</p>
                        </div>
                      </div>
                      <div className="blog-body">
                        {searchPerformed ? (
                          searchBlog.length === 0 ? (
                            <div className="no-results">
                              <p>No results found.</p>
                            </div>
                          ) : (
                            searchBlog.map((blog, index) => (
                              <div key={index} className="col-12">
                                <div className="blogs-body-inner-bottom row">
                                  <div className="blogs-part col-1">
                                    <p>{index + 1}</p>
                                  </div>
                                  <div className="blogs-part col-2">
                                    <div className="img-outer">
                                      <img src={blog?.image} alt="" />
                                    </div>
                                  </div>
                                  <div className="blogs-part col-2">
                                    <p>{blog.title}</p>
                                  </div>
                                  <div className="blogs-part col-5">
                                    <p>{blog.description[0].content}</p>
                                  </div>
                                  <div className="blogs-part col-2">
                                    <div className="action-outer row">
                                      <div className="but-outer col-12">
                                        <Button
                                          variant="primary"
                                          onClick={() =>
                                            handleShowEditBlog(blog._id)
                                          }
                                        >
                                          Edit
                                        </Button>
                                      </div>

                                      {/* <Link to="/updateBlog">Edit</Link> */}
                                      <div className="but-outer col-12">
                                        <button
                                          onClick={() => onDelete(blog._id)}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )
                        ) : (
                          blogs &&
                          blogs.map((blog, index) => (
                            // console.log(blog._id)
                            <div className="blogs-body-inner-bottom row">
                              <div className="blogs-part col-1">
                                <p>{index + 1}</p>
                              </div>
                              <div className="blogs-part col-2">
                                <div className="img-outer">
                                  <img src={blog.image} alt="" />
                                </div>
                              </div>
                              <div className="blogs-part col-2">
                                <p>{blog.title}</p>
                              </div>
                              <div className="blogs-part col-5">
                                <p>{blog.description[0].content}</p>
                              </div>
                              <div className="blogs-part col-2">
                                <div className="action-outer row">
                                  <div className="but-outer col-12">
                                    <div className="but-outer col-12">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleShowEditBlog(blog, blog._id)
                                        }
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                    {showEditBlog && (
                                      <Modal show={true}>
                                        <Modal.Header>
                                          <Modal.Title>Edit Blog</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <section>
                                            {
                                              <div className="form_data">
                                                <div className="form_heading">
                                                  <h1>
                                                    Update Resource Center
                                                  </h1>
                                                </div>

                                                <form>
                                                  <div className="form_input">
                                                    <label htmlFor="title">
                                                      Title
                                                    </label>
                                                    <input
                                                      type="text"
                                                      onChange={
                                                        handleTitleChange
                                                      }
                                                      value={title}
                                                      id="title"
                                                      name="title"
                                                      placeholder="Title"
                                                    />
                                                  </div>
                                                  {fields.map((field) => (
                                                    <div key={field.id}>
                                                      <input
                                                        type="text"
                                                        name="subtitle"
                                                        value={field.title}
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            field.id,
                                                            e
                                                          )
                                                        }
                                                        placeholder="Sub Title"
                                                      />
                                                      <input
                                                        type="text"
                                                        name="content"
                                                        value={field.content}
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            field.id,
                                                            e
                                                          )
                                                        }
                                                        placeholder="content"
                                                      />
                                                      <button
                                                        onClick={() =>
                                                          handleRemoveField(
                                                            field.id
                                                          )
                                                        }
                                                      >
                                                        Remove
                                                      </button>
                                                    </div>
                                                  ))}
                                                  <button
                                                    onClick={handleAddFields}
                                                  >
                                                    + Add Fields
                                                  </button>

                                                  <div className="form_input">
                                                    <label htmlFor="readingTime">
                                                      Estimate Reading Time
                                                    </label>
                                                    <input
                                                      type="readingTime"
                                                      onChange={
                                                        handleReadingTimeChange
                                                      }
                                                      value={readingTime}
                                                      id="readingTime"
                                                      name="readingTime"
                                                      placeholder="readingTime"
                                                    />
                                                    <p>
                                                      Reading Time:{" "}
                                                      {readingTime}
                                                    </p>
                                                  </div>
                                                  <div className="form_input">
                                                    <label for="productImage">
                                                      Resource Image
                                                    </label>
                                                    <input
                                                      type="file"
                                                      id="productImage"
                                                      onChange={(e) =>
                                                        setImage(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                      name="productImage"
                                                      required
                                                    />
                                                  </div>
                                                  <div className="tags">
                                                    <label>Tags</label>
                                                    <div className="tags-input-container">
                                                      <input
                                                        type="text"
                                                        className="tags-input"
                                                        placeholder="Please enter your tags here"
                                                        value={tagInput}
                                                        onChange={
                                                          handleInputChangetag
                                                        }
                                                        onKeyDown={
                                                          handleInputKeyDown
                                                        }
                                                      />
                                                    </div>
                                                    <div className="tags-list">
                                                      {tags.map(
                                                        (tag, index) => (
                                                          <div
                                                            key={index}
                                                            className="tag"
                                                          >
                                                            {tag}
                                                            <button
                                                              className="tag-remove"
                                                              onClick={() =>
                                                                handleTagRemove(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              &times;
                                                            </button>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                  {/* <div
                                                    className="btn"
                                                    // onClick={getMyListing}
                                                    style={{
                                                      backgroundColor:
                                                        "#005C4B",
                                                    }}
                                                  >
                                                    Create Resource Center
                                                  </div> */}
                                                </form>
                                              </div>
                                            }
                                          </section>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="secondary"
                                            onClick={handleCloseModal}
                                          >
                                            Close
                                          </Button>
                                          <Button
                                            variant="primary"
                                            onClick={() => handleSaveButton()}
                                          >
                                            Save Changes
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    )}
                                  </div>
                                  <div className="but-outer col-12">
                                    <button onClick={() => onDelete(blog._id)}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeButton === "products" ? (
                <div className="sec-2-outer-products">
                  <div className="products-inner">
                    <div className="sec-2-products-head">
                      <div className="products-top-side">
                        <h2>All Products</h2>
                        <Link to="/createProduct">Create Product</Link>
                      </div>
                      <div className="products-top-side">
                        <div className="search-outer">
                          <input
                            type="text"
                            placeholder="Search"
                            value={productNameProduct}
                            onChange={handleProductInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sec-2-products-body">
                      <div className="products-body-inner-top row ">
                        <div className="products-part col-1">
                          <p>S.No.</p>
                        </div>
                        <div className="products-part col-2">
                          <p>Image</p>
                        </div>
                        <div className="products-part col-2">
                          <p>Name</p>
                        </div>
                        <div className="products-part col-1">
                          <p>Price</p>
                        </div>
                        <div className="products-part col-2">
                          <p>Category</p>
                        </div>
                        <div className="products-part col-1">
                          <p>Count</p>
                        </div>

                        <div className="products-part col-3">
                          <p>Action</p>
                        </div>
                      </div>

                      <div className="product-body">
                        {searchResultProduct?.map((product, index) => (
                          <div
                            key={index}
                            className="products-body-inner-bottom row"
                          >
                            <div className="products-part col-1">
                              <p>{index + 1}</p>
                            </div>
                            <div className="products-part col-2">
                              <div className="img-outer">
                                <img src={product.image} alt={product.title} />
                              </div>
                            </div>
                            <div className="products-part col-2">
                              <p>{product.title}</p>
                            </div>
                            <div className="products-part col-1">
                              <p>{product.price}</p>
                            </div>
                            <div className="products-part col-2">
                              <p>{product.size}</p>
                            </div>
                            <div className="products-part col-1">
                              <p>{product.category}</p>
                            </div>
                            <div className="products-part col-3">
                              <div className="action-outer row">
                                <div className="but-outer col-12">
                                  <Link to={`/updateProduct/${product._id}`}>
                                    Edit
                                  </Link>
                                </div>
                                <div className="but-outer col-12">
                                  <button
                                    onClick={() => handleDelete(product._id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* ................................................... */}
                    </div>
                  </div>
                </div>
              ) : activeButton === "return" ? (
                <div className="sec-2-outer-products">
                  <div className="products-inner">
                    <div className="sec-2-products-head">
                      <div className="products-top-side">
                        <h2>Return Request</h2>
                      </div>
                      <div className="products-top-side">
                        <div className="search-outer">
                          <input
                            type="text"
                            placeholder="Search"
                            value={productNameProduct}
                            onChange={handleProductInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sec-2-products-body">
                      <div className="products-body-inner-top row ">
                        <div className="products-part col-2">
                          <p>S. No.</p>
                        </div>
                        {/* <div className="products-part col-2">
                          <p>Image</p>
                        </div> */}
                        <div className="products-part col-2" id="name">
                          <p>Name</p>
                        </div>
                        <div className="products-part col-1">
                          <p>Price</p>
                        </div>
                        <div className="products-part col-1">
                          <p>units</p>
                        </div>
                        <div className="products-part col-2">
                          <p>Category</p>
                        </div>
                        <div className="products-part col-2">
                          <p>Resion</p>
                        </div>

                        <div className="products-part col-1">
                          <p>Action</p>
                        </div>
                      </div>

                      <div className="product-body">
                        {returnOrder?.map((product, index) => (
                          <div
                            key={index}
                            className="products-body-inner-bottom row"
                          >
                            <div className="products-part col-1">
                              <p>{index + 1}</p>
                            </div>
                            {/* <div className="products-part col-2">
                              <div className="img-outer">
                                <img src={product.image} alt={product.title} />
                              </div>
                            </div> */}
                            <div className="products-part col-2">
                              <p>{product?.productsId[0]?.productId?.title}</p>
                            </div>
                            <div className="products-part col-1">
                              <p>
                                {product?.productsId[0]?.productId?.sellprice}
                              </p>
                            </div>
                            <div className="products-part col-2">
                              <p>{product?.productsId[0]?.units}</p>
                            </div>
                            <div className="products-part col-1">
                              <p>{product?.user?.phoneNumber}</p>
                            </div>
                            <div className="products-part col-1">
                              <p>{product?.reasonOfReturn}</p>
                            </div>
                            <div className="products-part col-3">
                              <div className="action-outer row">
                                <div className="but-outer col-12">
                                  {product?.status == "Pending" ? (
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleReturn(product?.orderId)
                                        }
                                      >
                                        Return
                                      </button>
                                      <div className="but-outer col-12">
                                        <button
                                          onClick={() =>
                                            setCancelProductId(product._id)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handlePay(product?.orderId)
                                      }
                                    >
                                      PayAmount
                                    </button>
                                  )}
                                </div>

                                {cancelProductId === product._id && (
                                  <div className="cancel-reason col-12">
                                    <textarea
                                      value={cancelReason}
                                      onChange={(e) =>
                                        setCancelReason(e.target.value)
                                      }
                                      placeholder="Enter reason for cancellation"
                                    />
                                    <button
                                      onClick={() => handleCancel(product._id)}
                                    >
                                      Send Reason
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* ................................................... */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sec-2-outer-users">
                  <div className="users-inner">
                    <div className="sec-2-users-head">
                      <div className="users-top-side">
                        <h2>All Users</h2>
                        {/* <p>Active Users</p> */}
                      </div>
                      <div className="users-top-side">
                        <div className="search-outer">
                          {/* <input
                            type="text"
                            className="nav-input"
                            name="users"
                            style={{ width: "15rem" }}
                            placeholder="Search"
                          /> */}
                          <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sec-2-users-body">
                      <div className="users-body-inner-top row">
                        <div className="users-part col-1">
                          <p>S. No.</p>
                        </div>
                        <div className="users-part col-2">
                          <p>User Id</p>
                        </div>
                        <div className="users-part col-3">
                          <p>Contact</p>
                        </div>
                        <div className="users-part col-2">
                          <p>Name</p>
                        </div>

                        <div className="users-part col-4">
                          <p>Email</p>
                        </div>
                      </div>
                      {combinedData.map((user, index) => {
                        return (
                          <div
                            key={index}
                            className="users-body-inner-bottom row"
                          >
                            <div className="users-part col-1">
                              <p>{index + 1}</p>
                            </div>
                            <div className="users-part col-2">
                              <p>...{user?._id.substring(18, 24)}</p>
                            </div>
                            <div className="users-part col-3">
                              <p>{user.phoneNumber}</p>
                            </div>

                            <div className="users-part col-1">
                              <p>{user?.fullName}</p>
                            </div>

                            <div className="users-part col-5">
                              <p>{user?.email}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Dashboard;
