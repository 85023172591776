import React from "react";
import "./Post.scss";
import { Link } from "react-router-dom";

const Post = ({ id, title, description, image, readingTime }) => {
  return (
    <div>
      <div className="post-main">
        <div className="post-outer">
          <div className="post-inner">
            <div className="part-1">
              <div className="sub-inner">
                <div class="mask1 w-100">
                  <img src={image} alt={title} />
                </div>
              </div>
            </div>
            <div className="part-2">
              <div className="sub-part2-1">
                <div className="top">
                  <p>{title}</p>
                </div>
                <div className="content">
                  <p>{description.substring(0, 100)}...</p>
                </div>
              </div>
              <div className="sub-part2-2">
                {/* <div className="time">
                  <p>{readingTime}</p>
                </div> */}
                <div
                  className="bottom"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link
                    to={`/singleBlog/${id}`}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
