import React, { useState, useEffect } from "react";
import Bangles from "../../assets/bangle.png";
import "./ShopCard.scss";
import HomeCard from "../HomeCard/HomeCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { useFetch } from "../../hooks/api_hook";

const ShopCard = ({ product }) => {
  console.log(product);
  const navigate = useNavigate();

  //const [wishlistItems, setWishlistItems] = useState([]);
  const [isHeartFilled, setIsHeartFilled] = useState(product.likeMore);

  console.log(isHeartFilled);
  const [isHovered, setIsHovered] = useState(false);
  const id = product._id;
  const location = useLocation();
  const [quantity, setQuantity] = useState(1);
  const [inCart, setInCart] = useState(false);
  const [total, setTotal] = useState(1);
  const cartItems = location.state;
  const products = cartItems?.data?.products || [];

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;

  const { data: cart, setData: setCart } = useFetch(
    `/api/getCartByUser/${userId}`
  );

  const queryParams = new URLSearchParams(location.search);
  const amount = queryParams.get("amount");

  //updates the total price when the cart data changes.
  useEffect(() => {
    if (cart) {
      let totalPrice = 0;
      for (let i = 0; i < cart.products.length; i++) {
        totalPrice +=
          cart.products[i]?.productId?.sellprice * cart.products[i]?.units;
      }
      setTotal(totalPrice);
    }
  }, [cart]);

  const discountPercentage = product.price
    ? ((product.price - product.sellprice) / product.price) * 100
    : 0;

  const discountAmount = product.price ? product.price - product.sellprice : 0;

  const [imageSrc, setImageSrc] = useState(product?.image[0]);

  const handleMouseEnter = () => {
    setImageSrc(product?.image[1]);
  };

  const handleMouseLeave = () => {
    setImageSrc(product?.image[0]);
  };

  // handle the initial setup and updates related to the user's cart and wishlist.
  useEffect(() => {
    if (cart) {
      setInCart(
        cart?.products.find((product) => {
          return product?.productId?._id === id;
        })
      );
      /*
      setQuantity(
        cart?.products.find((product) => {
        return product?.productId?._id === id;
      })?.units
      );
      */
    }
  }, [cart, id]);

  //adds the product to the user's cart and navigates to the cart page

  const onBuyNowClick = (id) => {
    navigate(`/product/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="SC-main">
        <div className="SC-outer">
          <div className="SC-inner">
            <div className="SC-part ">
              <div
                className="image-outer"
                onClick={() => {
                  navigate(`/product/${product._id}`);
                  window.scrollTo(0, 0);
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="image-inner">
                  <img src={imageSrc} alt={product?.name} />
                  <div className="overlay "
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>{product.title}</div>
                </div>
              </div>

              <div className="content-outer">
                <div className="content-inner">
                  <div className="SC-price">
                    <div className="pricediv">
                      <p>₹{product?.sellprice}/-</p>
                      <del>₹{product?.price}/-</del>
                    </div>
                    <button>
                      <HomeCard
                        isFilled={isHeartFilled}
                        id={product._id}
                        userId={userId}
                      />
                    </button>
                  </div>
                  {isHovered ? (
                    <div
                      className="cartonhover"
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <button
                        onClick={() => {
                          onBuyNowClick(product?._id);
                        }}
                      >
                        Buy now{" "}
                      </button>
                    </div>
                  ) : product?.price >= product?.sellprice ? (
                    <div
                      className="percent"
                      onMouseEnter={() => setIsHovered(true)}
                    >
                      <p className="per">
                        {" "}
                        {discountPercentage.toFixed(2)}% OFF
                      </p>
                      <p className="save">
                        Save Rs. {discountAmount.toFixed(2)}/-
                      </p>
                    </div>
                  ) : (
                    <div
                      className="percent"
                      onMouseEnter={() => setIsHovered(true)}
                    >
                      <h6
                        style={{
                          color: "blue",
                          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Top Trending Products
                      </h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
