import React, { useState, useEffect } from "react";
import "./Cart.scss";
import img from "../../assets/home-cat.png";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { useFetch } from "../../hooks/api_hook";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const Cart = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [total, setTotal] = useState(1);
  const location = useLocation();
  const cartItems = location.state;
  const products = cartItems?.data?.products || [];
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?._id;

  let { data: cart, setData: setCart } = useFetch(
    `/api/getCartByUser/${user?._id}`
  );

  useEffect(() => {
    if (cart) {
      let totalPrice = 0;
      for (let i = 0; i < cart.products.length; i++) {
        console.log(cart.products[i]?.ModelPart);
        cart?.products[i]?.ModelPart?.map((Item, index) => {
          console.log("->>>>>>>..", Item);
          totalPrice += cart.products[i]?.productId?.sellprice * Item?.units;
        });
      }

      setTotal(totalPrice);
    }
  }, [cart]);

  const increaseValueHandler = async (
    index,
    e,
    units,
    polish,
    color,
    size,
    ind
  ) => {
    try {
      const { data } = await axios.put(`https://kanha-art-jewellery-drtc.onrender.com/api/addToCart`, {
        userId: userId,
        productId: cart?.products[index].productId?._id,
        units: 1,
        polish: polish,
        color: color,
        size: size,
      });
      if (data.success) {
        const updatedCart = { ...cart };
        console.log(updatedCart);
        updatedCart.products[index].ModelPart[ind].units += 1;
        setCart(updatedCart);
      } else {
        toast.error(`${("increase data", data.message)}`, {
          position: "bottom-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`${("erroor in increase", error.message)}`, {
        position: "bottom-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const decreaseValueHandler = async (index, e, modelPartId, units, ind) => {
    try {
      const { data } = await axios.delete(
        `https://kanha-art-jewellery-drtc.onrender.com/api/dropFromCart/${userId}/${cart.products[index].productId._id}/${modelPartId}/${units}`
      );

      if (data.success) {
        const updatedCart = { ...cart };
        updatedCart.products[index].ModelPart[ind].units -= 1;
        setCart(updatedCart);
      } else {
        toast.error(`${("decrease error", data.message)}`, {
          position: "bottom-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`${("decsrea api error", error.message)}`, {
        position: "bottom-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    decreaseValueHandler();
    increaseValueHandler();
  }, []);

  // to delete cart items
  const handleDeleteProduct = async (
    productId,
    index,
    e,
    modelPartId,
    units,
    ind
  ) => {
    // Update cart before making API call
    const updatedCart = cart.products.filter(
      (product) =>
        !product.ModelPart.some((subItem) => subItem._id === productId)
    );

    setCart({
      ...cart,
      products: updatedCart,
    });

    // Delete product from backend
    try {
      const { data } = await axios.delete(
        `http://localhost:8008/api/dropFromCart/${userId}/${cart.products[index].productId._id}/${modelPartId}/${units}`
      );

      if (data.success) {
        // Update cart after successful deletion
        const updatedCart = { ...cart };
        updatedCart.products[index].ModelPart[ind].units -= 1;
        setCart(updatedCart);
      } else {
        toast.error(`Decrease error: ${data.message}`, {
          position: "bottom-right",
          autoClose: 3000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(`API error: ${error.message}`, {
        position: "bottom-right",
        autoClose: 3000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <div className="Parent-element">
      <div className="top-card">
        <div className="card">
          <div className="card-body">
            {cart ? (
              <>
                <div className="header">
                  <h5 className="card-title">Shopping Cart</h5>
                  <p className="card-price">Price</p>
                </div>

                <hr className="horizontal-line"></hr>

                <div className="cart-outer">
                  {cart?.products?.map((item, index) =>
                    item.ModelPart.map((SubItem, ind) => (
                      <div key={SubItem._id} className="cart-box">
                        {/* <div className="item-image"> */}
                        <img
                          src={item?.productId?.image[0]}
                          alt="Product Image"
                          className="item-image"
                        />
                        {/* </div> */}
                        <div className="side-block">
                          <div className="product-heading-price">
                            <p className="product-heading">
                              {item?.productId?.title}
                            </p>
                            {/* <span className="price-text">
                              ₹
                              {cart?.products[index]?.productId?.sellprice *
                                SubItem?.units}
                              /-
                            </span> */}

                            {/* Delete Button */}
                            <button
                              className="delete-btn border-0"
                              onClick={() => handleDeleteProduct(SubItem._id)}
                            >
                              <i className="bi bi-trash"></i> {/* Trash Icon */}
                            </button>
                          </div>

                          <p className="product-text">
                            {item?.productId?.description}
                          </p>
                          <p className="product-para">
                            Eligible for{" "}
                            <span className="text-color">FREE Shipping</span>
                          </p>
                          <div className="style-para">
                            {SubItem?.size && (
                              <div>
                                Price:{" "}
                                <span className="style-color-text">
                                  ₹{" "}
                                  {cart?.products[index]?.productId?.sellprice *
                                    SubItem?.units}
                                  /-
                                </span>
                              </div>
                            )}
                            {SubItem?.size && (
                              <div>
                                Size:{" "}
                                <span className="style-color-text">
                                  {SubItem?.size}
                                </span>
                              </div>
                            )}
                            {SubItem?.color && (
                              <div>
                                Color:{" "}
                                <span className="style-color-text">
                                  {SubItem?.color}
                                </span>
                              </div>
                            )}
                            {SubItem?.polish && (
                              <div>
                                Polish:{" "}
                                <span className="style-color-text">
                                  {SubItem?.polish}
                                </span>
                              </div>
                            )}
                          </div>

                          {/* increase cart */}
                          <div className="count-part">
                            <div className="item-count-part">
                              <div className="qty-text">Qty:</div>
                              <button
                                onClick={(e) => {
                                  decreaseValueHandler(
                                    index,
                                    e,
                                    SubItem?._id,
                                    SubItem?.units,
                                    ind
                                  );
                                }}
                                disabled={SubItem?.units <= 1}
                              >
                                <i class="bi bi-dash"></i>
                              </button>
                              <span>{SubItem?.units}</span>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  increaseValueHandler(
                                    index,
                                    e,
                                    SubItem?.units + 1,
                                    SubItem?.polish,
                                    SubItem?.color,
                                    SubItem?.size,
                                    ind
                                  );
                                }}
                              >
                                <i class="bi bi-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <hr className="horizontal-line"></hr>
                <div className="bottom-para">
                  Subtotal ({cart.length} items): ₹ {total.toFixed(2)}/-
                </div>
              </>
            ) : (
              <div className="empty-cart-message">
                <p>Your cart is empty.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bottom-card">
        <div className="card">
          <div className="card-body">
            <p className="card-text">
              The price and availability of items at Amazon.in are subject to
              change. The shopping cart is a temporary place to store a list of
              your items and reflects each item's most recent price. Do you have
              a promotional code? We'll ask you to enter your claim code when
              it's time to pay.
            </p>
          </div>
        </div>
        <div className="cart-buttons row">
          <div className="button-outer col-md-6">
            <button
              onClick={() => {
                navigate("/shop");
                window.scrollTo(0, 0);
              }}
            >
              Continue Shopping
            </button>
          </div>

          {cart && cart.products.length > 0 && (
            <div className="button-outer col-md-6">
              <button
                onClick={() => {
                  navigate(`/checkout/${null}`);
                  window.scrollTo(0, 0);
                }}
              >
                Proceed To Checkout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
