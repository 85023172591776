import React from "react";
import "./TermsAndCondition.scss";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  return (
    <div>
      <div className="TC-main">
        <div className="TC-outer">
          <div className="TC-inner">
            <div className="head">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="body">
              <div className="part">
                <h5>OVERVIEW</h5>
                <p>
                  Welcome to Kanha Art Jewellery. By accessing or using our
                  website, you agree to comply with these Terms of Service. If
                  you disagree with any part of the terms, please refrain from
                  using our site.
                </p>
              </div>
              <div className="part">
                <h5>GENERAL CONDITIONS</h5>
                <p>
                  We reserve the right to refuse service to anyone for any
                  reason at any time.
                </p>
                <p>
                  All content on this site, except credit card details, may be
                  transferred unencrypted. Credit card information is always
                  encrypted.
                </p>
                <p>
                  You must not reproduce, duplicate, or exploit any part of the
                  Service without our express permission.
                </p>
              </div>
              <div className="part">
                <h5>ACCURACY OF INFORMATION</h5>
                <p>
                  We strive for accuracy but do not guarantee the completeness
                  or timeliness of information on this site.
                </p>
                <p>
                  Prices and product descriptions are subject to change without
                  notice.
                </p>
              </div>
              <div className="part">
                <h5>PRODUCTS AND SERVICES</h5>
                <p>
                  Products may be available exclusively online with limited
                  quantities.
                </p>
                <p>
                  We reserve the right to limit sales and discontinue products
                  at our discretion.
                </p>
              </div>
              <div className="part">
                <h5>BILLING AND ACCOUNT INFORMATION</h5>
                <p>We reserve the right to refuse or limit any orders.</p>
                <p>
                  Ensure your account and purchase details are current and
                  accurate for smooth transactions.
                </p>
              </div>
              <div className="part">
                <h5>OPTIONAL TOOLS</h5>
                <p>
                  We may offer third-party tools on our site, which are provided
                  “as is” without any warranties.
                </p>
                <p>Use of these tools is at your own risk.</p>
              </div>
              <div className="part">
                <h5>THIRD-PARTY LINKS</h5>
                <p>
                  We are not responsible for third-party content or sites linked
                  from our platform.
                </p>
                <p>
                  Any complaints about third-party products should be directed
                  to the third-party.
                </p>
              </div>
              <div className="part">
                <h5>USER COMMENTS AND SUBMISSIONS</h5>
                <p>We may use or edit your submissions without restriction.</p>
                <p>
                  Ensure your comments do not violate third-party rights or
                  contain unlawful material.
                </p>
              </div>
              <div className="part">
                <h5>PERSONAL INFORMATION</h5>
                <p>
                  Your privacy is important to us. Personal information is
                  governed by our Privacy Policy.
                </p>
              </div>{" "}
              <div className="part">
                <h5>DISCLAIMER AND LIMITATION OF LIABILITY</h5>
                <p>Use of our service is at your own risk.</p>
                <p>
                  We are not liable for any damages, except as required by law.
                </p>
              </div>{" "}
              <div className="part">
                <h5>INDEMNIFICATION</h5>
                <p>
                  You agree to indemnify us from any claims arising from your
                  breach of these terms or violation of any laws.
                </p>
              </div>{" "}
              <div className="part">
                <h5>SEVERABILITY AND TERMINATION</h5>
                <p>
                  If any term is deemed unenforceable, it shall not affect the
                  validity of other terms.
                </p>
                <p>
                  We may terminate these terms or your access to our service at
                  any time.
                </p>
              </div>
              <div className="part">
                <h5>ENTIRE AGREEMENT</h5>
                <p>
                  These Terms of Service constitute the entire agreement between
                  you and Kanha Art Jewellery regarding our service, superseding
                  any prior agreements.
                </p>
              </div>
              <div className="part">
                <h5>GOVERNING LAW</h5>
                <p>
                  These terms are governed by the laws of Kanha Art Jewellery,
                  Bangalore, India.
                </p>
              </div>
              <div className="part">
                <h5>CHANGES TO TERMS</h5>
                <p>
                  We may update these terms occasionally. Check this page for
                  updates. Your continued use of our service after changes
                  signifies acceptance of the updated terms.
                </p>
              </div>
              <div className="part">
                <h5>CONTACT</h5>
                <p>
                  For questions about these terms, contact us at 
                  <Link> info@kanhaartjewellery.com.</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
