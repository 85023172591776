import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./UpdateUser.scss";

const UpdateUser = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [inputHandler, setInputHandler] = useState({
    id: id,
    fullName:"",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    street: "",
    landMark: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
  });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [maxDate, setMaxDate] = useState("");
  useEffect(() => {
    if (user) {
      if (user.role.role !== "User") {
        history("/auth");
      } else {
        history(`/updateUser/${id}`);
      }
    } else {
      history("login");
    }
  }, []);

  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData._id;
  console.log(userId);

  const getOneUser = async () => {
    try {
      const { data: user } = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/auth/user/${userId}`
      );
      const { data: userAddress } = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/user/getUserAddress/${id}`
      );
      let date = new Date(user.data.dateOfBirth);
      let day = date.getDay();
      if (day < 10) {
        day = "0" + day;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let year = date.getFullYear();
      setInputHandler({
        ...inputHandler,
        ["fullName"]:userAddress.data.fullName,
        ["street"]: userAddress.data.street,
        ["city"]: userAddress.data.city,
        ["landMark"]: userAddress.data.landmark,
        ["zipCode"]: userAddress.data.zipCode,
        ["country"]: userAddress.data.country,
        ["state"]: userAddress.data.state,
        ["phoneNumber"]: user.data.phoneNumber,
        ["email"]: userAddress.data.email,
        ["dateOfBirth"]: `${year}-${month}-${day}`,
      });
    } catch (err) {}
  };

  useEffect(() => {
    getOneUser();
  }, []);

  const onChangeInputHandler = (e) => {
    if (e.target.name === "phone") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
    const { name, value } = e.target;

    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };
  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    setMaxDate(today);
  }, []);

  const backToDashboard = () => {
    history(`/myaccount/${id}`);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // if(inputHandler.email === ""){
    //   alert("Enter Email")
    // }else if(inputHandler.userName===""){
    //   alert("Enter the UserName")
    // }else if(inputHandler.street===""){
    //   alert("Enter the Street")
    // }else if(inputHandler.zipCode===""){
    //   alert("Enter the zipcode")
    // }else if(inputHandler.landMark===""){
    //   alert("Enter the landmark")
    // }else if(inputHandler.city===""){
    //   alert("Enter city")
    // }else if(inputHandler.country===""){
    //   alert("Enter country")
    // }else if(inputHandler.state===""){
    //   alert("Enter State")
    // }else{
    const { data } = await axios.post(
      `https://kanha-art-jewellery-drtc.onrender.com/user/putUserAddress`,
      {
        userId: id,
        fullName:inputHandler.fullName,
        street: inputHandler.street,
        landmark: inputHandler.landMark,
        city: inputHandler.city,
        country: inputHandler.country,
        phoneNumber: inputHandler.phoneNumber,

        dateOfBirth: inputHandler.dateOfBirth,
        state: inputHandler.state,
        zipCode: inputHandler.zipCode,

        email: inputHandler.email,


      }
    );
    if (data.success) {
      // localStorage.setItem('user', data.userName )
    }
    backToDashboard();
  };

  // };

  return (
    <>
       
      <div className="updateUser">
        <section>
          <div className="form_data">
            <div className="cross" onClick={backToDashboard}>
              <i class="bi bi-file-x-fill"></i>
            </div>
            <div className="form_heading">
              <h1>Update User</h1>
            </div>

            <form>
            <div className="form_input">
              <label htmlFor="title">User Name</label>
              <input
                type="text"
                onChange={onChangeInputHandler}
                id="fullName"
                name="fullName"
                value={inputHandler.fullName}
                placeholder="fullName"
              />
            </div>
              <div className="form_input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  onChange={onChangeInputHandler}
                  id="email"
                  name="email"
                  value={inputHandler.email}
                  placeholder="Email ..."
                />
              </div>
              
              <div className="form_input">
                <label htmlFor="phone">Street</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="street"
                  name="street"
                  value={inputHandler.street}
                  placeholder="Street..."
                />
              </div>
              <div className="form_input">
                <label htmlFor="landMark">LandMark</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="landMark"
                  name="landMark"
                  value={inputHandler.landMark}
                  placeholder="Land Mark..."
                />
              </div>
              <div className="form_input">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="city"
                  name="city"
                  value={inputHandler.city}
                  placeholder="City..."
                />
              </div>
              <div className="form_input">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="country"
                  name="country"
                  value={inputHandler.country}
                  placeholder="Country..."
                />
              </div>
              <div className="form_input">
                <label htmlFor="zipCode">zipCode</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="zipCode"
                  name="zipCode"
                  value={inputHandler.zipCode}
                  placeholder="zipCode..."
                />
              </div>
              <div className="form_input">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="state"
                  name="state"
                  value={inputHandler.state}
                  placeholder="State..."
                />
              </div>

              <button className="btn" onClick={onSubmitHandler}>
                Update User
              </button>
            </form>
          </div>
        </section>
      </div>

    </>
  );
};

export default UpdateUser;
