import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./HomeCard.scss";
import { addToLike } from "../../../redux/actions/likeActions";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomeCard = ({ isFilled: initialIsFilled, id, userId }) => {
  console.log({ initialIsFilled, id, userId });
  const [isFilledd, setIsFilledd] = useState(initialIsFilled);

  useEffect(() => {
    setIsFilledd(initialIsFilled);
  }, [initialIsFilled]);

  console.log(isFilledd);
  const dispatch = useDispatch();
  const buttonClass = isFilledd
    ? "round-btn round-btn--filled"
    : "round-btn round-btn--outlined";

  const handleClick = () => {
    setIsFilledd(!isFilledd);
    // addLikeInWishList();
    toggleHeartColor();
    if (id) dispatch(addToLike(id));
  };

  const toggleHeartColor = async () => {
    // const newIsHeartFilled = !isHeartFilled;
    // setIsHeartFilled(newIsHeartFilled);

    try {
      const data = {
        userId: userId,
        productId: id,
      };

      const apiEndpoint = "https://kanha-art-jewellery-drtc.onrender.com/wishlist/addWishlist";

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const updateResponse = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/product/updateProduct/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: id,
          }),
        }
      );

      if (!updateResponse.ok) {
        throw new Error("Failed to update product liked status");
      } else {
        setIsFilledd(!isFilledd);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const addLikeInWishList = async (e) => {
  //   // e.preventDefault();

  //   const { data } = await axios.post(
  //     "https://kanha-art-jewellery-drtc.onrender.com/wishlist/addWishlist",
  //     {
  //       productId: id,
  //       userId: userId,
  //     }
  //   );

  //   if (data.success === false) {
  //     toast.error("Like done", {
  //       position: "bottom-right",
  //       autoClose: 8000,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "dark",
  //     });
  //     // alert(data.message);
  //   } else if (data.success) {
  //   }
  // };
  // };

  return (
    <button className={buttonClass} onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
      </svg>
      <ToastContainer />
    </button>
  );
};

export default HomeCard;
