import React from "react";
import "./ReturnPolicy.scss";
import { Link } from "react-router-dom";

const ReturnPolicy = () => {
  return (
    <div>
      <div className="RP-main">
         
        <div className="RP-outer">
          <div className="RP-inner">
            <div className="head">
              <h1>Returns and Refund</h1>
            </div>
            <div className="body">
              <div className="body-head">
                <h3>Kanha Art Jewellery Returns</h3>
                <h4>Secure Shopping | Easy Returns | COD Available</h4>
              </div>
              <div className="part">
                <p>
                  We're committed to your satisfaction. If you need to return
                  your jewellery:
                </p>
                <p>
                  <span>15-Day Return:</span> Initiate a return within 15 days
                  of receipt.
                </p>
                <p>
                  <span> Exceptions:</span> Nose Pins (Naths) and Toe Rings are
                  non-returnable due to hygiene reasons.
                </p>
              </div>
              <div className="part">
                <h5>How to Return</h5>
                <p>
                  <span>Visit Return Centre: </span>
                  Fill the returns form and await email confirmation.
                </p>
                <p>
                  <span>Response: </span> We'll  confirm within 24 hours and
                  arrange a reverse pick-up.
                </p>
                <p>
                  <span>Return Steps:</span>
                </p>
                <ul>
                  <li>  Provide order details.</li>
                  <li>Select products to return.</li>
                  <li>Upload product photos.</li>
                  <li>Submit return request.</li>
                </ul>
              </div>
              <div className="part">
                <h5>Refund Process</h5>
                <p>
                  Refunds are processed to the original payment method after
                  product inspection.
                </p>
                <p>COD refunds require bank details provided via SMS.</p>
                <p>
                  Refunds take up to 24 working hours to process and 4-5 days to
                  reflect in your account.
                </p>
              </div>
              <div className="part">
                <h5>Exchange</h5>
                <p>For exchanges, return the item and place a new order.</p>
                <p>
                  Exchanges are also possible at our Retail Store within 15 days
                  of delivery.
                </p>
              </div>
              <div className="part">
                <h5>Order Cancellation</h5>
                <p>
                  Email us at <Link> hello@kanhaartjewellery.com </Link>for
                  cancellations.
                </p>
                <p>If shipped, kindly reject delivery upon arrival.</p>
              </div>
              <div className="part">
                <h5>International Orders</h5>
                <p>
                  Contact us at <Link>hello@kanhaartjewellery.com</Link> or call
                  9591321696 for international returns.
                </p>
              </div>
              <div className="part">
              <p>
                <span>Note: </span>
                Returned products must be unused, in original condition with
                packaging, tags, and bill.
              </p>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
