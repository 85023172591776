import React, { useState, useEffect } from "react";
import "./AdminProcess.scss";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFetch } from "../../hooks/api_hook";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

var amount;
const AdminProcess = () => {
  const [display, setDisplay] = useState({ div: "", showButtons: true });
  const { id, userid } = useParams();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const acceptHandler = () => setValue(1);
  const backHandler = () => setValue(0);
  const { data: order } = useFetch(`/api/getOrderById/${id}`);
  const products = order?.products;
  const user = JSON.parse(localStorage.getItem("user"));
  const userAddress = order?.userAddress;
  const [formData, setFormData] = useState({
    length: 0,
    breadth: 0,
    height: 0,
    weight: 0,
  });
  const [orderStatuses, setOrderStatuses] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { data: orders } = useFetch("/api/getAllOrders");
  const [address, setAddress] = useState([]);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/user/getAddressByUserId/${userid}`
        );
        if (response.data.success) {
          setAddress(response.data.data);
        } else {
          console.log("llllllllll", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAddress();
  }, []);

  useEffect(() => {
    if (orders) {
      orders.forEach((order) => {
        const savedStatus = getInitialStatus(order._id);
        if (savedStatus) {
          setOrderStatuses((prevStatuses) => ({
            ...prevStatuses,
            [order._id]: savedStatus,
          }));
        }
      });
    }
  }, [orders]);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handlechangeOrderStatus = (e, id) => {
    const newOrderStatus = e.target.value;
    setOrderStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: newOrderStatus,
    }));
    localStorage.setItem(`selectedOrderStatus-${id}`, newOrderStatus);
    orderStatusHandler(id, newOrderStatus);
  };
  // const orderStatus = order?.status;

  const getInitialStatus = (id) => {
    const savedStatus = localStorage.getItem(`selectedOrderStatus-${id}`);
    return savedStatus ? savedStatus : "";
  };

  const orderStatusHandler = (id, orderStatus) => {
    axios
      .patch(`https://kanha-art-jewellery-drtc.onrender.com/api/updateOrder/${id}`, {
        length: 1,
        orderStatus: orderStatus,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  const cancelShipment = async () => {
    try {
      const { data } = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/api/ship/cancelRequest",
        {
          orderId: id,
        }
      );
      if (data.success) {
        toast.success("Amount Refunded successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error(`Amount Refunded Successfully`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const dashboardHandler = async () => {
    try {
      if (
        formData.length == 0 ||
        formData.breadth == 0 ||
        formData.height == 0 ||
        formData.weight == 0
      ) {
        toast.error(`Please provide all details to accept the request`, {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
      } else {
        const { data } = await axios.post(
          "https://kanha-art-jewellery-drtc.onrender.com/api/ship/approveRequest",
          {
            orderId: id,
            length: formData.length,
            breadth: formData.breadth,
            height: formData.height,
            weight: formData.weight,
          }
        );
        if (data.success) {
          navigate("/dashboard");
        } else {
          console.log("fetched error");
          toast.error(`${data.message}`, {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      console.log("error isse");
      toast.error(`${error.message}`, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const [topping, setTopping] = useState("By ShipRocket");

  const onOptionChange = (e) => {
    setTopping(e.target.value);
  };

  const ShippingDeliveryHandler = () => {
    if (topping === "By ShipRocket") {
      axios
        .patch(`https://kanha-art-jewellery-drtc.onrender.com/api/updateOrder/${id}`, {
          status: "By ShipRocket",
        })
        .then((res) => {
          dashboardHandler();
          navigate("/dashboard");
        })
        .catch((err) => {});
    }
  };

  return (
    <div>
      <div className="AP-main">
    
        <div className="AP-outer">
          <div className="AP-inner">
            <div className="top row">
              <div className="top-part-1 col-6">
                <h5>Buyer Information</h5>
                {address?.map((item) => {
                  return (
                    <div>
                      <p>
                        <span>Name:</span> {item?.fullName}
                      </p>
                      <p>
                        <span>Email:</span> {item?.email}
                      </p>
                    </div>
                  );
                })}
                <p>
                  <span>Phone:</span> {order?.user?.phoneNumber}
                </p>
              </div>
              <div className="top-part-2 col-6">
                <h5>Billing To</h5>
                <p>{order?.userAddress?.fullName}</p>
                <p>
                  <span>Pin: </span> {order?.userAddress?.zipCode}
                </p>
                <p>
                  <span>city:</span> {order?.userAddress?.city}
                </p>
              </div>
            </div>
            <div className="mid">
              <div className="mid-outer">
                <div className="mid-inner">
                  <div className="mid-head row">
                    <div className="part-head col-1">
                      <p>Serial No. </p>
                    </div>
                    <div className="part-head col-2">
                      <p>Product Name </p>
                    </div>
                    <div className="part-head col-1">
                      <p>Polish </p>
                    </div>
                    <div className="part-head col-1">
                      <p>Color </p>
                    </div>
                    <div className="part-head col-1">
                      <p>Size </p>
                    </div>
                    <div className="part-head col-2">
                      <p>Selling Price </p>
                    </div>
                    <div className="part-head col-1">
                      <p>Quantity </p>
                    </div>
                    <div className="part-head col-1">
                      <p>Price (INR)</p>
                    </div>
                    <div className="part-head col-2">
                      <p>Total Amount</p>
                    </div>
                  </div>
                  <div className="mid-body">
                    <div className="mid-body-inner-1 ">
                      {order?.products?.map((product, index) => {
                        return (
                          <div className="mid-body-inner-1 row">
                            <div className="part-body col-1">
                              <p>{index + 1}</p>
                            </div>
                            <div className="part-body col-2">
                              <p>{product?.productId?.title} </p>
                            </div>
                            <div className="part-body col-1">
                              <p>{product?.polish} </p>
                            </div>
                            <div className="part-body col-1">
                              <p>{product?.color} </p>
                            </div>
                            <div className="part-body col-1">
                              <p>{product?.size} </p>
                            </div>
                            <div className="part-body col-2">
                              <p>{product?.productId?.price} </p>
                            </div>
                            <div className="part-body col-1">
                              <p>{product?.units}</p>
                            </div>
                            <div className="part-body col-1">
                              <p>{product?.productId?.sellprice} </p>
                            </div>
                            <div className="part-body col-2">
                              <p style={{ display: "none" }}>
                                {
                                  (amount =
                                    product?.productId?.sellprice *
                                    product?.units)
                                }
                              </p>
                              <p>
                                {" "}
                                {product?.productId?.sellprice *
                                  product?.units}{" "}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="mid-body-inner row">
                        <div className="part-body col-1">
                          <p>Shipment Charge </p>
                        </div>
                        <div className="part-body col-2">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p></p>
                        </div>
                        <div className="part-body col-1">
                          <p></p>
                        </div>
                        <div className="part-body col-2">
                          <p></p>
                        </div>
                        <div className="part-body col-1">
                          <p></p>
                        </div>
                        <div className="part-body col-1">
                          <p></p>
                        </div>
                        <div className="part-body col-2">
                          <p>0.00</p>
                        </div>
                      </div>
                      <div className="mid-body-inner row">
                        <div className="part-body col-1">
                          <p>Total Gross </p>
                        </div>
                        <div className="part-body col-2">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p></p>
                        </div>
                        <div className="part-body col-1">
                          <p> </p>
                        </div>
                        <div className="part-body col-2">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p> </p>
                        </div>
                        <div className="part-body col-1">
                          <p> </p>
                        </div>
                        <div className="part-body col-2">
                          <p>{order?.shipment_charge + amount} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="bottom-outer">
                <div className="bottom-inner">
                  <div className="head">
                    <h5>Shipping From:</h5>
                  </div>
                  <div className="body">
                    <p>
                      LG-43, APOLLO SQUARE, 7/2 R.S.BHANDARI MARG OPP. NARAYAN
                      KOTHI, INDORE, Indore, Madhya Pradesh, 452001, Indore,
                      Madhya Pradesh, India, PIN: 452001
                    </p>
                    <p>
                      <span>Phone: </span>+91 9424819212
                    </p>
                    <p>
                      <span>Email: </span>pulkitno2@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-bottom">
              {value == 1 && (
                <div className="table-for-input">
                  <hr />
                  <h5 className="mt-2">
                    Please enter below details to process shipment
                  </h5>
                  <div className="input-table">
                    <div className="accept">
                      <div className="head">
                        <h3>Fill the form</h3>
                      </div>
                      <div className="accept-outer">
                        <label>
                          Weight <p> (in g)</p>
                        </label>
                        <input
                          type="tel"
                          name="weight"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="accept-outer">
                        <label>
                          Height <p> (in cm)</p>
                        </label>
                        <input
                          type="tel"
                          name="height"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="accept-outer">
                        <label>
                          Length <p> (in cm)</p>
                        </label>
                        <input
                          type="tel"
                          name="length"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="accept-outer">
                        <label>
                          Breadth <p> (in cm)</p>
                        </label>
                        <input
                          type="tel"
                          name="breadth"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="shipment-buttons">
                        <button
                          className="confirm-button btn btn-primary"
                          onClick={handleOpen}
                        >
                          Confirm Shipment
                        </button>
                        <button
                          onClick={backHandler}
                          className="back-button btn btn-secondary"
                          style={{ marginLeft: "10px" }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {order?.orderStatus === "REJECTED" ? (
                <div className="accept-reject-button-rejection">
                  <span>
                    The order was Rejected , and Refund was Initiated{" "}
                  </span>
                </div>
              ) : (
                <div>
                  {value == 0 && (
                    <div className="accept-reject-button">
                      {order?.orderStatus === "COMPLETED" ? (
                        <div className="accept-reject-button-rejection">
                          <span>
                            The order is completed , and Pickup is scheduled
                          </span>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <button
                            type="button"
                            class="btn btn-primary accept-button m-2"
                            onClick={acceptHandler}
                          >
                            Accept
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary reject-button m-2"
                            onClick={cancelShipment}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="model-data">
              <h3>Delivery Option</h3>
              <hr />
              <div>
                <input
                  type="radio"
                  name="topping"
                  value="By ShipRocket"
                  id="medium"
                  checked={topping === "By ShipRocket"}
                  onChange={onOptionChange}
                />
                <label htmlFor="medium" className="selected-option-admin">
                  By ShipRocket
                </label>
              </div>
              <hr />
              <p>
                Selected option <strong>{topping}</strong>
              </p>
              <div className="popup-button">
                <div>
                  <button
                    className="save-button-a"
                    onClick={ShippingDeliveryHandler}
                  >
                    save
                  </button>
                </div>
                <div>
                  <button className="save-button-a" onClick={handleClose}>
                    close
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AdminProcess;
