import * as actionTypes from "../constants/likeConstants";

export const addToLike = (id) => async (dispatch) => {
  try {
    //console.log("->>>>>>>>>>>", id);
    dispatch({ type: actionTypes.ADD_TO_LIKE, payload: { id } });
  } catch (error) {
    console.log("Error while calling Like API");
    console.log("kjdhflkd");
  }
};

export const removeFromLike = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_FROM_LIKE,
    payload: id,
  });
};
