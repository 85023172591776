import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Contact.scss";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
const ContactUs = () => {
  const [inputHandler, setInputHandler] = useState({
    name: "",
    email: "",
    message: "",
  });

  const onChangeHandler = (event) => {
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };
  const onSubmitHandler = () => {
    const data = axios
      .post(`https://kanha-art-jewellery-drtc.onrender.com/contact/createContact`, inputHandler)
      .then((response) => {
        toast.success("Response Send Successfully", {
          position: "bottom-right",
          autoClose: 8000,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        setInputHandler({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="contact-main">
         
        <div className="contact-outer">
          <div className="contact-inner">
            <div className="head">
              <h1>Contact Us</h1>
              <p>Please fill the form</p>
            </div>
            <ToastContainer />
            <div className="body row">
              <div className="section-1 col-md-7">
                <div className="sec-inner">
                  <div className="question">
                    <p>Have a question?</p>
                  </div>
                  <div className="below-que">
                    <p>
                      Horem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum.
                    </p>
                  </div>

                  <div class="row form-part">
                    <div class="col-xs-4 col-xs-offset-4">
                      <div class="floating-label-group">
                        <input
                          type="text"
                          id="username"
                          class="form-control"
                          autocomplete="off"
                          autofocus
                          name="name"
                          value={inputHandler.name}
                          onChange={onChangeHandler}
                          required
                        />
                        <label class="floating-label">Full Name</label>
                      </div>

                      <div class="floating-label-group">
                        <input
                          type="text"
                          id="email"
                          class="form-control"
                          autocomplete="off"
                          name="email"
                          value={inputHandler.email}
                          onChange={onChangeHandler}
                          required
                        />
                        <label class="floating-label">Email</label>
                      </div>
                      <div class="floating-label-group">
                        <textarea
                          type="password"
                          id="password"
                          class="form-control"
                          autocomplete="off"
                          name="message"
                          value={inputHandler.message}
                          onChange={onChangeHandler}
                          required
                        />
                        <label class="floating-label">Message</label>
                      </div>
                      <div className="bottom">
                        <div className="sub-button">
                          <button onClick={onSubmitHandler}>
                            Submit Enquiry
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-2 col-md-5">
                <div className="sec-2-outer">
                  <div className="top">
                    <div className="sec2-head">
                      <p>Get in touch</p>
                    </div>
                    <div className="desc">
                      <p>
                        Horem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                    <div className="content">
                      <div className="part row">
                        <div className="content-logo col-2">
                          <i class="bi bi-geo-alt-fill"></i>
                        </div>
                        <div className="content-links col-10">
                          <p>Address</p>
                          <Link to="#">
                            Forem ipsum dolor sit amet, consectetur.
                          </Link>
                        </div>
                      </div>
                      <div className="part row">
                        <div className="content-logo col-2">
                          <i class="bi bi-telephone-fill"></i>{" "}
                        </div>
                        <div className="content-links col-10">
                          <p>Phone</p>
                          <Link to="tel:919876543210">+91 98765 43210</Link>
                        </div>
                      </div>
                      <div className="part row">
                        <div className="content-logo col-2">
                          <i class="bi bi-envelope-fill"></i>{" "}
                        </div>
                        <div className="content-links col-10">
                          <p>Email</p>
                          <Link to="mailto:support@gmail.com">
                            support@gmail.com
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="bottom-logo">
                      <link
                        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                        rel="stylesheet"
                      />

                      <ul>
                        <li>
                          <a href="https://www.facebook.com/kanhaArtJewellery/">
                            <span></span>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/kanhajewellery">
                            <span></span>
                          </a>
                        </li>
                       
                        <li>
                          <a href="https://www.instagram.com/kanhaartjewellery/w">
                            <span></span>
                          </a>
                        </li>
                      </ul>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
