import React from "react";
import img1 from "../../assets/about-first-image.jpg";
import img2 from "../../assets/about-second-image.jpg";
import img3 from "../../assets/about-third-image.jpg";
import store1 from "../../assets/store-location-1.jpg";
import store2 from "../../assets/store-location-2.jpg";
import store3 from "../../assets/store-location-3.jpg";
import store4 from "../../assets/store-location-4.jpg";
import "./About.scss";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="about-parent">
          <h1 className="heading">About Us</h1>
          <div className="paragraph">
            <p className="text-para">
              Qorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
            <p className="text-para">
              Qorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
          </div>
          <h1 className="heading-2">Our Journey</h1>

          <div className="three-card row">
            <div className="first-card col-md-4">
              <div className="card">
                <div className="card-body">
                  <img
                    src={img1}
                    alt="loading image..."
                    className="circle-image"
                  />
                  <p className="card-text">
                    Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget condimentum velit, sit amet feugiat lectus.
                  </p>
                </div>
              </div>
            </div>

            <div className="second-card col-md-4">
              <div className="card">
                <div className="card-body">
                  <img src={img2} alt="photo" className="circle-image" />
                  <p className="card-text">
                    Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget condimentum velit, sit amet feugiat lectus.
                  </p>
                </div>
              </div>
            </div>

            <div className="third-card col-md-4">
              <div className="card">
                <div className="card-body">
                  <img src={img3} alt="photo" className="circle-image" />
                  <p className="card-text">
                    Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget condimentum velit, sit amet feugiat lectus.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h1 className="heading-3">Find Us At</h1>

          <div className="store-image row">
            <div className="store-img  col-sm-12 col-6 ">
              <img src={store1} alt="photo" className="image" />
              <p className="store-text">Store Location 1</p>
            </div>

            <div className="store-img col-sm-12 col-md-6 ">
              <img src={store2} alt="photo" className="image" />
              <p className="store-text">Store Location 2</p>
            </div>

            <div className="store-img col-sm-12 col-md-6 ">
              <img src={store3} alt="photo" className="image" />
              <p className="store-text">Store Location 3</p>
            </div>
            <div className="store-img col-sm-12 col-md-6  ">
              <img src={store4} alt="photo" className="image" />
              <p className="store-text">Store Location 4</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
