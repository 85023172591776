import React, { useState, useEffect, useRef } from "react";
import "./Header.scss";
import { useSelector, useDispatch } from "react-redux";
import Logo from "./../../assets/kanha-log.png";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFetch } from "../../hooks/api_hook";
import { addToCart } from "../../../redux/actions/cartActions";
import { addToLike } from "../../../redux/actions/likeActions";
import { toast } from "react-toastify";

var userId;
const Header = () => {
  const searchBarRef = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  userId = user ? user?._id : null;

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [totalTopProducts, setTotalTopProducts] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [wishlist, setWishlist] = useState([]);

  const cartDetails = useSelector((state) => state.cart);
  const { cartItems } = cartDetails;
  const LikeDetails = useSelector((state) => state.like);
  const { likeItems } = LikeDetails;
  let { data: cart } = useFetch(`/api/getCartByUser/${userId}`);
  const dispatch = useDispatch();
  const [relativeSearchProduct, setRelativeSearchProduct] = useState([]);

  const handleClickOutside = (event) => {
    const categoryDropdown = document.querySelector(".category-dropdown");
    const categoryLinks = document.querySelectorAll(".nav-link");
    if (
      categoryDropdown &&
      !categoryDropdown.contains(event.target) &&
      !Array.from(categoryLinks).some((link) => link.contains(event.target))
    ) {
      setIsCategoryVisible(false);
    }
  };

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/wishlist/getWishlist?userId=${user._id}`
      );
      setWishlist(response.data.data);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  const addAllIdForLike = () => {
    wishlist[0]?.products.map((item) => {
      if (item?._id) dispatch(addToLike(item?._id));
    });
  };

  const addAllIdForCart = () => {
    cart?.products.map((item) => {
      console.log(item);
      item?.ModelPart.map((Product) => {
        if (Product)
          dispatch(
            addToCart(
              item?.productId?._id +
                Product?.color +
                Product?.polish +
                Product?.size
            )
          );
      });
      console.log(item);
    });
  };

  const fetchCategories = async () => {
    const response = await fetch(`https://kanha-art-jewellery-drtc.onrender.com/category/allCategory`);
    const data = await response.json();
    setCategories(data.data);

    return data;
  };

  addAllIdForCart();
  useEffect(() => {
    if (wishlist.length > 0) {
      addAllIdForLike();
    }
  }, [wishlist]);

  const handlexyz = async (categoryName) => {
    try {
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getCategoryByCategoryName/${categoryName}`
      );
      const data = await response.json();
      window.scrollTo(0, 0);

      navigate(`/shop/${categoryName}`, { state: { idData: data } });
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  const handleGetAllRelatedProductData = async (itemName) => {
    try {
      console.log(itemName);
      const response = await fetch(
        `https://kanha-art-jewellery-drtc.onrender.com/category/getListInsideCategoryListbyItemName/${itemName}`
      );
      const data = await response.json();
      window.scrollTo(0, 0);

      navigate(`/shop/${itemName}`, {
        state: { list: data.list, products: data.products },
      });
    } catch (error) {
      console.error("Error fetching related products:", error);
    }
  };

  const handleCategoryClick = async (id) => {
    setSelectedCategory(id);
    const data = await fetchCategoryData(id);
    setCategoryData(data.data);
  };

  const fetchCategoryData = async (id) => {
    const response = await fetch(
      `https://kanha-art-jewellery-drtc.onrender.com/category/findCategoryByCategoryId/${id}`
    );
    const data = await response.json();
    return data;
  };

  const productHandler = (event, id) => {
    navigate(`/product/${id}`);
    window.scrollTo(0, 0);
  };

  const handleSearchSubmit = (event) => {};

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.scrollTo(0, 0);
    fetchCategories();
    fetchWishlist();
    addAllIdForLike();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsSearchBarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/");
      window.scrollTo(0, 0);
    } else {
      userId = user?._id;
    }
  }, [user]);

  const getRelativeSearchProduct = () => {
    const config = {
      url: `https://kanha-art-jewellery-drtc.onrender.com/product/relativeSearch?search=${searchQuery}`,
      method: "get",
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == true) {
          setRelativeSearchProduct(response?.data?.data);
        }
        if (response?.data?.status == false) {
          if (response?.data?.response_code == 500) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          } else if (response?.data?.response_code == 404) {
            toast.error("Problem With Data Fetching", {
              position: "bottom-right",
              autoClose: 4000,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.response_code == 401) {
          toast.error("Your token is expired. Please login again.", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.warn("Problem With Data Fetching", {
            position: "bottom-right",
            autoClose: 4000,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    getRelativeSearchProduct();
  }, [searchQuery]);

  return (
    // <div>
    <div className="header-main">
      <div className=" header-outer">
        <nav className="header-inner navbar navbar-expand-lg  bg-body-tertiary">
          {/*   Kanha logo */}
          <div className="logo col-2" style={{ cursor: "pointer" }}>
            <div className="logo">
              <img
                src={Logo}
                style={{ width: "50%" }}
                alt=""
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </div>

          {/* Responsive button */}
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          {/* <div className=" navbar-toggler">
              <button
                class=""
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
                </div> */}

          {/* search form and icon */}

          <div
            className="col-10 mt-2 main-navigator offcanvas navbar-offcanvas offcanvas-end"
            id="navbarSupportedContent"
          >
            <div class="offcanvas-body ">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
              <div className=" offcanvas-navigator top-search h-100 w-100 ">
                <div className="below">
                  <div className="row search-bar-container">
                    {/* search bar */}
                    <div className="search col-8  position-relative">
                      <div className="form" id="searchbar">
                        <form
                          className="d-flex form-search justify-content-end"
                          role="search"
                          onSubmit={handleSearchSubmit}
                          onClick={() => {
                            setIsSearchBarVisible(true);
                          }}
                          onMouseLeave={() => {
                            setIsSearchBarVisible(false);
                          }}
                        >
                          <input
                            className="form-control w-75  "
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <button
                            className="position-absolute mx-2 mt-2 bi-sch-icon"
                            style={{
                              background: "white",
                              border: "none",
                            }}
                          >
                            <i class="bi bi-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>

                    {/* RHS Icons */}
                    <div className="icon-navs col-3 ">
                      <div className="d-flex  icon-outer gap-3">
                        {user !== null ? (
                          <div className="ul-list">
                            {user?.role?.role === "Admin" ? null : (
                              <div className="d-flex icon-inner gap-3">
                                {/* wishlist icon */}
                                <div
                                  className="link-item"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    onClick={() => {
                                      navigate(`/wishlist/${userId}`);
                                    }}
                                  >
                                    <i class="bi bi-heart fs-4"></i>
                                    {/* <div className="item-outer">
                                        {likeItems?.length}
                                      </div> */}
                                  </div>
                                </div>

                                {/* cart icon */}
                                <div
                                  className="link-item"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    class="d-flex "
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                      navigate(`/Cart/${userId}`);
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    <i class="bi bi-cart fs-4"></i>
                                    {/* <div style={{color:"red", fontWeight:"bold",border:"1px solid #aaa", width:"20px", height:"24px", borderRadius:"30px", textAlign:"center", backgroundColor:"white" }} className="item-outer">
                                        {cartItems?.length}
                                      </div> */}
                                    <span class="position-absolute start-100 translate-middle badge rounded-pill bg-danger">
                                      {cartItems?.length}
                                    </span>
                                  </div>
                                </div>

                                {/* user icon */}
                                <div
                                  className="link-item "
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link to={`/myAccount/${userId}`}>
                                    <i class="bi bi-person fs-4"></i>
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {/* person icon */}
                            <div
                              className="link-item"
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                onClick={() => {
                                  navigate(`/auth`);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <i class="bi bi-person fs-4"></i>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* House Icon */}
                        <div className="link-item">
                          <Link to="/">
                            <i class="bi bi-house fs-4 text-dark"></i>
                          </Link>
                        </div>
                        {/* bag-icon */}
                        <div className="link-item">
                          <div
                            onClick={() => {
                              navigate(`/shop`);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <i
                              class="bi bi-bag fs-4"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>

                        {user && user?.role?.role === "Admin" ? (
                          <div className="link-item">
                            <Link to="/dashboard">
                              <button>Dashboard</button>
                            </Link>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nav-primary col-12">
                  <div className="w-100">
                    <ul className="navbar-nav me-auto mb-md-0 ">
                      {categories?.map((category) => (
                        <li
                          className="nav-item"
                          key={category?._id}
                          onMouseEnter={() => {
                            handleCategoryClick(category?._id);
                            setIsCategoryVisible(true);
                          }}
                          onMouseLeave={() => {
                            setIsCategoryVisible(false);
                          }}
                        >
                          <Link
                            aria-current="page"
                            to="/"
                            onClick={() => handlexyz(category?.categoryName)}
                            class="nav-link"
                          >
                            {category?.categoryName}
                          </Link>
                        </li>
                      ))}
                    </ul>

                    {isCategoryVisible && selectedCategory && (
                      <div
                        className="category-dropdown"
                        onMouseEnter={() => {
                          setIsCategoryVisible(true);
                        }}
                        onMouseLeave={() => {
                          setIsCategoryVisible(false);
                        }}
                      >
                        {categoryData?.categoryList?.map((item) => (
                          <div className="cate-drop-out" key={item.title}>
                            <h3
                              onClick={() =>
                                handlexyz(categoryData?.categoryName)
                              }
                            >
                              {item.title}
                            </h3>
                            {item.list.map((value) => (
                              <div
                                key={value.name}
                                className="cate-outer"
                                onClick={() =>
                                  handleGetAllRelatedProductData(value?.name)
                                }
                              >
                                <p> {value?.name}</p>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {isSearchBarVisible && (
          <div
            className="search-bar"
            onMouseEnter={() => {
              setIsSearchBarVisible(true);
            }}
            onMouseLeave={() => {
              setIsSearchBarVisible(false);
            }}
          >
            <div
              className="search-bar-outer"
              onMouseEnter={() => {
                setIsSearchBarVisible(true);
              }}
              onMouseLeave={() => {
                setIsSearchBarVisible(false);
              }}
            >
              <div className="search-bar-inner row">
                <div className="section-1 col-4 col-sm-12 ">
                  <div className="head">
                    <h6>TRENDING SEARCHES</h6>
                  </div>
                  <div className="body ">
                    {categories?.map((category) => (
                      <li
                        className="item-category"
                        style={{ listStyle: "none", padding: "2px" }}
                        key={category?._id}
                        onMouseEnter={() => {
                          setIsSearchBarVisible(true);
                        }}
                        onMouseLeave={() => {
                          setIsSearchBarVisible(false);
                        }}
                      >
                        <Link
                          aria-current="page"
                          to="/"
                          onMouseEnter={() => {
                            setIsSearchBarVisible(true);
                          }}
                          onMouseLeave={() => {
                            setIsSearchBarVisible(false);
                          }}
                          onClick={() => handlexyz(category?.categoryName)}
                          class="nav-link"
                        >
                          {category?.categoryName}
                        </Link>
                      </li>
                    ))}
                  </div>
                </div>
                <div className="section-2 col-8 col-sm-12">
                  <div className="head">
                    <h6>Relative Search Product</h6>
                  </div>
                  <div className="body">
                    <div className="part row">
                      {relativeSearchProduct?.length > 0 &&
                        (() => {
                          let displayedProducts = 0;
                          return relativeSearchProduct.flatMap((item) =>
                            item?.products?.map((produ, ind) => {
                              if (displayedProducts < 6) {
                                displayedProducts++;
                                return (
                                  <div
                                    key={produ._id}
                                    className="part-outer col-4"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      productHandler(event, produ._id);
                                    }}
                                  >
                                    <div className="image-outer">
                                      <img
                                        src={produ?.image[0]}
                                        alt={produ?.title}
                                      />
                                    </div>
                                    <div className="content-outer">
                                      <p className="name">{produ?.title}</p>
                                      <p className="price-search">
                                        ₹ {produ?.sellprice}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          );
                        })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    // </div>
  );
};

export default Header;
