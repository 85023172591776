import React, { useState, useEffect } from "react";
import "./MyAccount.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
var totalPayAmount;

const MyAccount = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [userOrder, setUserOrder] = useState([]);
  const [activeButton, setActiveButton] = useState("account");
  const [inputHandler, setInputHandler] = useState({
    amount: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [walletTransaction, setWalletTransaction] = useState([]);
  const [walletAmount, setWalletAmount] = useState();
  const [showPart, setShowPart] = useState("");

  const onChangeInputHandler = (e) => {
    setInputHandler({ ...inputHandler, [e.target.name]: e.target.value });
  };

  console.log(inputHandler.amount);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData?._id;
  console.log(userId);

  const onLogOut = () => {
    confirmAlert({
      title: "Logout",
      message: `Leaving so soon? Would hate to see you go!`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            localStorage.clear();
            window.location.href = "/auth";
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            navigate("/");
            // Do nothing if the user clicks 'Cancel'
          },
        },
      ],
    });
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/auth/user/${userId}`
      );
      if (response.data.success) {
        setUser(response.data.data);
      } else {
        // console.log("dfghjk", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserAddress = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/user/getUserAddress/${userId}`
      );
      if (response.data.success) {
        setUserAddress(response.data.data);
      } else {
        // console.log("jhgfd", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserOrder = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/api/getAllOrderByUser/${userId}`
      );
      if (response.data.success) {
        console.log(response.data);
        setUserOrder(response.data.data);
      } else {
        // console.log("jhgfd", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Step 3: Update state on button click
  const handleWalletClick = () => {
    setShowPart("wallet");
  };

  const addAmountHandler = async (event) => {
    event.preventDefault();
    try {
      totalPayAmount = inputHandler.amount;
      const { data } = await axios.post(
        "https://kanha-art-jewellery-drtc.onrender.com/wallet/createWallet",
        {
          userId: userId,
          amount: totalPayAmount,
        }
      );
      if (data.success) {
        if (data.success === true) {
          toast.success("Amount Added Successfully", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
          console.log("data success", data);
          window.location.href = data.data.paymentUrl;
          setWalletAmount(totalPayAmount);
        } else {
          console.log("data error", data);
        }
        window.location.replace(data.data);
      }
    } catch (error) {
      console.log("Failed to submit form", error);
    }
  };

  const handleTransactionsClick = () => {
    setShowPart("transactions");
  };

  const fetchWallet = async () => {
    try {
      const response = await axios.get(
        `https://kanha-art-jewellery-drtc.onrender.com/wallet/getWalletById/${userId}`
      );
      if (response.data) {
        setWalletTransaction(response?.data?.transaction);
        const total = walletTransaction?.reduce(
          (acc, item) => acc + (item?.amount || 0),
          0
        );
        setTotalAmount(total);
      } else {
        console.log("transaction failed");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserAddress();
    fetchUser();
    fetchUserOrder();
    fetchWallet();
  }, []);

  console.log(walletTransaction);
  console.log(totalAmount);

  return (
    <div>
      <div className="MA-main">
        <div className="MA-outer">
          <div className="MA-inner navbar navbar-expand-lg bg-body-tertiary row">
            <div className="section-1 col-md-3 container-fluid">
              <button
                class="navbar-toggler w-100"
                type="button"
                // data-bs-toggle="collapse"
                // data-bs-target="#navbarNav"
                // aria-controls="navbarNav"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <p className="m-0 w-100">View</p>
              </button>
              <div
                className="sec-1-top collapse navbar-collapse"
                id="navbarNav"
              >
                <div className="part nav-item w-100">
                  <button
                    class="nav-link active"
                    aria-current="page"
                    name="account"
                    className={`part-outer ${
                      activeButton === "account" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("account")}
                  >
                    <i class="bi bi-person-circle"></i> <p>Account</p>
                  </button>
                </div>
                <div className="part nav-item w-100">
                  <button
                    class="nav-link active"
                    aria-current="page"
                    name="order"
                    className={`part-outer ${
                      activeButton === "orders" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("orders")}
                  >
                    <i class="bi bi-bag-check"></i> <p>Orders</p>
                  </button>
                </div>
                <div className="part nav-item w-100">
                  <button
                    class="nav-link active"
                    aria-current="page"
                    name="wallet"
                    className={`part-outer ${
                      activeButton === "wallet" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("wallet")}
                  >
                    <i class="bi bi-wallet2"></i> <p>Wallet</p>
                  </button>
                </div>
                <div className="part nav-item w-100">
                  <button
                    class="nav-link active"
                    aria-current="page"
                    className={`part-outer ${
                      activeButton === "logout" ? "active" : ""
                    }`}
                    onClick={onLogOut}
                  >
                    <i className="bi bi-box-arrow-left"></i> <p>Logout</p>
                  </button>
                </div>
              </div>

              {/* <div
                className="sec-1-bottom collapse navbar-collapse"
                id="navbarNav"
              >
                <div className="part nav-item w-100">
                  <button
                    class="nav-link active"
                    aria-current="page"
                    className={`part-outer ${
                      activeButton === "logout" ? "active" : ""
                    }`}
                    onClick={onLogOut}
                  >
                    <i className="bi bi-box-arrow-left"></i> <p>Logout</p>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="section-2 col-md-9">
              {activeButton === "account" ? (
                <div className="sec-2-outer-account">
                  <div className="sec-2-inner">
                    <div className="sec-2-head">
                      <h2>Profile Details</h2>
                    </div>
                    <div className="sec-2-body row">
                      <div className="body-bold col-6">
                        <p>Name </p>
                        <p>Mobile Number </p>
                        <p>Email ID </p>
                        <p>Location </p>
                      </div>
                      <div className="body-normal col-6">
                        <p>-{userAddress?.fullName}</p>
                        <p>-{user?.phoneNumber}</p>
                        <p>-{userAddress?.email}</p>
                        <p>-{userAddress?.city}</p>
                      </div>
                    </div>
                    <div className="sec-2-bottom">
                      <div className="button-outer">
                        <Link to={`/updateUser/${userId}`}>EDIT</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeButton === "orders" ? (
                <div className="sec-2-outer-orders">
                  <div className="sec-2-inner">
                    <div className="sec-2-head">
                      <h2>Orders</h2>
                    </div>
                    <div className="sec-2-body row">
                      <div className="sec-2-body-inner">
                        <div className="headings row">
                          <div className="part col-1">
                            <p>S. No.</p>
                          </div>
                          <div className="part col-3">
                            <p>Order ID</p>
                          </div>
                          <div className="part col-2">
                            <p>Price</p>
                          </div>
                          <div className="part col-3">
                            <p>Status</p>
                          </div>
                          <div className="part col-3">
                            <p>Date</p>
                          </div>
                        </div>

                        <div>
                          {userOrder.map((order, index) => (
                            <div key={index} className="body">
                              <div className="body-inner row">
                                <div className="part col-1">
                                  <p>{index + 1}</p>
                                </div>
                                <div className="part col-3">
                                  <Link to={`/orderConfirmation/${order?._id}`}>
                                    ...{order?._id.substring(18, 24)}
                                  </Link>
                                </div>
                                <div className="part col-2">
                                  <p>
                                    ₹{" "}
                                    {order?.payBywallet
                                      ? order?.amount + order?.payBywallet
                                      : order?.amount}
                                  </p>
                                </div>
                                <div className="part col-3">
                                  <p>{order.orderStatus}</p>
                                </div>
                                <div className="part col-3">
                                  <p>
                                    {new Date(
                                      order.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeButton === "wallet" ? (
                <div className="sec-2-outer-wallet">
                  <div className="sec-2-inner">
                    <div className="sec-2-head">
                      <h2>Wallet</h2>
                    </div>
                    <div className="sec-2-body row">
                      <div className="sec-2-body-inner">
                        <div className="headings row">
                          <div className="part col-6">
                            <div className="part-outer1">
                              <button
                                className="part-inner"
                                onClick={handleWalletClick}
                              >
                                <i class="bi bi-plus-circle"></i>
                                <p>Wallet Topup</p>
                              </button>
                            </div>
                          </div>
                          <div className="part col-6">
                            <div className="part-outer2">
                              <button
                                className="part-inner"
                                onClick={handleTransactionsClick}
                              >
                                <i class="bi bi-list-columns-reverse"></i>
                                <p>Transactions</p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="body">
                            {showPart === "wallet" && (
                              <div className="body-inner-1">
                                <div className="part row">
                                  <p>Add Amount</p>
                                  <input
                                    type="tel"
                                    className="col-10"
                                    placeholder="1000"
                                    name="amount"
                                    value={inputHandler.amount}
                                    onChange={onChangeInputHandler}
                                  />
                                  <button
                                    className="col-2"
                                    onClick={addAmountHandler}
                                  >
                                    Pay
                                  </button>
                                </div>
                              </div>
                            )}
                            {showPart === "transactions" && (
                              <div className="body-inner-2">
                                <div className="part row">
                                  <p className="amount">Transactions</p>
                                  <div className="trans-outer">
                                    <div className="trans-inner">
                                      <div className="top-part">
                                        <p>Date</p>
                                        <p>Amount</p>
                                      </div>
                                      {walletTransaction?.map((item) => {
                                        return (
                                          <div className="sub-part">
                                            <p>
                                              {item?.createAt
                                                ? new Date(
                                                    item?.createAt
                                                  ).toLocaleDateString(
                                                    "en-GB",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                      day: "numeric",
                                                    }
                                                  )
                                                : "Date not available"}
                                            </p>
                                            <div className="amount">
                                              <p className="credit">
                                                +{item?.amount}
                                              </p>
                                              {/* <p className="debit">-200</p> */}
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <hr />
                                      <div className="total-amount">
                                        <p>Total Amount</p>
                                        <p>{totalAmount}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
