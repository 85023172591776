import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./orderFailedPage.scss";
import pendel from "../../assets/home-cat-1.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import axios from "axios";

const OrderFailed = () => {
  const { id } = useParams();
  console.log(id);
  const [order, setOrder] = useState([]);
const [address , setAddress] = useState([]);
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/api/getOrderById/${id}`
        );
        if (response.data.success) {
          setOrder(response.data.data);
        } else {
          console.log("llllllllll", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrder();
  }, [id]);
  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData?._id;
  
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/user/getAddressByUserId/${userId}`
        );
        if (response.data.success) {
          setAddress(response.data.data);
        } else {
          console.log("llllllllll", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAddress();
  }, []);


  return (
    <div>
      <div className="OC-main">
         
        <div className="OC-outer">
          <div className="OC-inner">
            <div className="section-1 row">
              <div className="sec-1-part-1 col-8">
                <h5>Sorry, your order have been Failed </h5>
                {/* <p>
                  The order confirmation has been sent to your email address
                </p> */}
              </div>
              <div className="sec-1-part-2 col-4">
                <div className="button-outer">
                  <Link to="/shop">Continue Shopping</Link>
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="sec-2-outer">
                <h6>Hello {userData?.fullName},</h6>
                <p>
                  Your order has been failed , you can try again .
                </p>
              </div>
            </div>
            <div className="section-3">
              <div className="sec-3-outer">
                <div className="sec-3-inner">
                  <div className="head">
                    <h6>Order Details:</h6>
                  </div>
                  <div className="mid row">
                    <div className="part-1 col-6">
                      <div className="part-head">
                        <p>Order ID:</p>
                        <p>Order Total:</p>
                      </div>
                      <div className="part">
                        <p>{order._id}</p>
                        <p>₹ {order.amount}</p>
                      </div>
                    </div>
                    <div className="part-2 col-6">
                      <div className="part-head">
                        <p>Transaction ID :</p>
                        <p>Date:</p>
                      </div>
                      <div className="part">
                        <p>
                           {order?.transactionId}
                        </p>
                        <p>{new Date(order.createdAt).toLocaleDateString()}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="body">
                    <div className="body-outer">
                      {order?.products?.map((product, index) => (
                        <div key={index} className="body-inner row">
                          <div className="image-outer col-2">
                            <img
                              src={product.productId.image[0]}
                              alt={product.productId.title}
                            />
                          </div>
                          <div className="content-outer col-8">
                            <div className="content">
                              <p>{product.productId.title}</p>
                              <div className="but-outer">
                                <p>Qnt:</p>
                                <p>{product.units}</p>
                              </div>
                            </div>
                          </div>
                          <div className="price-outer col-2">
                            <div className="price">
                              <p>₹ {product.productId.sellprice}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-4">
              <div className="sec-4-outer row">
                {/* <div className="part-1 col-6">
                  <div className="part-1-outer">
                    <div className="head">
                      <h6>Shipping Details:</h6>
                    </div>
                    <div className="body">
                      <div className="part-1-body">
                        <p>Name:</p>
                        <p>Address:</p>
                        <p>Pin:</p>
                        <p>Contact No:</p>
                      </div>
                      <div className="part-2-body">
                        <p>Rishika Kothari</p>
                        <p>Sadar Road Balod</p>
                        <p>452010</p>
                        <p>9993720520</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="part-2 col-12">
                  <div className="part-2-outer">
                    <div className="head">
                      <h6>Billing Details:</h6>
                    </div>
                    <div className="body">
                      <div className="part-1-body ">
                        <p>Name:</p>
                        <p>Address:</p>
                        <p>Pin:</p>
                        <p>Contact No.:</p>
                        <p>Amount:</p>
                      </div>
                      <div className="part-2-body">

                        {address?.map((item)=>{
                          return (
                          <p>{item?.fullName}</p>
                        )}) }
                        <p>
                          {order?.userAddress?.street},
                          {order?.userAddress?.landmark},
                          {order?.userAddress?.city},
                          {order?.userAddress?.state},
                          {order.userAddress?.country}
                        </p>
                        <p>{order?.userAddress?.zipCode}</p>
                        <p>{userData?.phoneNumber}</p>
                        <p>₹ {order?.amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-5">
              <div className="button-outer">
                <Link to={`/shop}`}>Continue Shopping</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFailed;
