import * as actionTypes from "../constants/likeConstants";

// Load likeItems from local storage if available, otherwise initialize with an empty array
const initialState = {
  likeItems: JSON.parse(localStorage.getItem("likeItems")) || [],
};

export const likeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_LIKE:
      //console.log("->>>>>>>>>>>>>>>..", action);
      const newId = action.payload.id;
      const existItemIndex = state.likeItems.findIndex(
        (product) => product.id === newId
      );

      if (existItemIndex !== -1) {
        // Retrieve the current array from localStorage
        const likeItems = JSON.parse(localStorage.getItem("likeItems")) || [];

        // ID of the item you want to remove
        const idToRemove = newId;

        // Find the index of the item with the specified ID in the array
        const indexToRemove = likeItems.findIndex(
          (item) => item.id === idToRemove
        );

        // If the item exists in the array, remove it
        if (indexToRemove !== -1) {
          likeItems.splice(indexToRemove, 1);

          // Update the array in localStorage
          localStorage.setItem("likeItems", JSON.stringify(likeItems));
        }

        // If the item exists, remove it from the like store
        return {
          ...state,
          likeItems: state.likeItems.filter((product) => product.id !== newId),
        };
      } else {
        // If the item does not exist, add it to the like store
        const updatedLikeItems = [...state.likeItems, { id: newId }];
        // Save updated likeItems to local storage
        localStorage.setItem("likeItems", JSON.stringify(updatedLikeItems));
        return {
          ...state,
          likeItems: updatedLikeItems,
        };
      }
    default:
      return state;
  }
};
