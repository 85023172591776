import React, { useState, useEffect, useRef } from "react";
import "./Invoice.scss";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import { useNavigate , useParams } from "react-router-dom";
import axios from "axios";
var amount
const Invoice = () => {
  const {id} = useParams()
  const pdfRef = useRef();
  const [address, setAddress] = useState([]);
  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };

  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData._id;
  const [userAddress, setUserAddress] = useState([]);
  const [order, setOrder] = useState([]);
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/api/getOrderById/${id}`
        );
        if (response.data.success) {
          setOrder(response.data.data);
        } else {
          console.log("llllllllll", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrder();
  }, [id]);

  console.log("))))))))))))))))))))))",order)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/auth/user/${userId}`
        );
        if (response.data.success) {
          setUser(response.data.data);
        } else {
          console.log("dfghjk", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/user/getUserAddress/${userId}`
        );
        if (response.data.success) {
          setUserAddress(response.data.data);
        } else {
          // console.log("jhgfd", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserAddress();
  }, []);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://kanha-art-jewellery-drtc.onrender.com/user/getAddressByUserId/${userId}`
        );
        if (response.data.success) {
          setAddress(response.data.data);
        } else {
          console.log("llllllllll", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAddress();
  }, []);

  return (
    <div>
      <div className="I-main">
        <div className="I-outer " ref={pdfRef}>
          <div className="I-inner">
            <div className="top row">
              <div className="top-part-1 col-6">
                <h5>Buyer Information</h5>
                {address.map((item) => {
                  return (
                    <div>
                      <p>
                        <span>Name:</span> {item?.fullName}
                      </p>
                      <p>
                        <span>Email:</span> {item?.email}
                      </p>
                    </div>
                  );
                })}
                <p>
                  <span>Phone:</span> {user?.phoneNumber}
                </p>
              </div>
              <div className="top-part-2 col-6">
                <h5>Billing To</h5>
                <p> {user?.fullName}</p>
                <p>
                  <span>Pin: </span> {userAddress?.zipCode}
                </p>
                <p>
                  <span>City:</span> {userAddress?.city}
                </p>
              </div>
            </div>
            <div className="mid">
              <div className="mid-outer">
                <div className="mid-inner">
                  <div className="mid-head row">
                    <div className="part-head col-2">
                      <p>Serial No. </p>
                    </div>
                    <div className="part-head col-2">
                      <p>Product Name </p>
                    </div>{" "}
                    <div className="part-head col-2">
                      <p>HSN/SAC </p>
                    </div>{" "}
                    <div className="part-head col-2">
                      <p>Quantity </p>
                    </div>{" "}
                    <div className="part-head col-2">
                      <p>Price (INR)</p>
                    </div>{" "}
                    <div className="part-head col-2">
                      <p>Total Amount</p>
                    </div>
                  </div>
                  <div className="mid-body">
                    {order?.products?.map((product, index) => {
                      
                      return (
                        <div className="mid-body-inner-1 row">
                          <div className="part-body col-2">
                            <p>{index+1}</p>
                          </div>
                          <div className="part-body col-2">
                            <p>{product?.productId?.title} </p>
                          </div>
                          <div className="part-body col-2">
                            <p>1 </p>
                          </div>
                          <div className="part-body col-2">
                            <p>{product?.units}</p>
                          </div>
                          <div className="part-body col-2">
                            <p>{product?.productId?.sellprice} </p>
                          </div>
                          <div className="part-body col-2">
                            <p style={{display:'none'}}>{amount =product?.productId?.sellprice * product?.units }</p>
                          
                            <p> {product?.productId?.sellprice * product?.units} </p>
                          </div>
                        </div>
                      );
                    })}
                    <div className="mid-body-inner row">
                      <div className="part-body col-2">
                        <p>Shipment Charge </p>
                      </div>
                      <div className="part-body col-2">
                        <p> </p>
                      </div>
                      <div className="part-body col-2">
                        <p> </p>
                      </div>
                      <div className="part-body col-2">
                        <p></p>
                      </div>
                      <div className="part-body col-2">
                        <p></p>
                      </div>
                      <div className="part-body col-2">
                        <p>{order?.shipment_charge}</p>
                      </div>
                    </div>
                    <div className="mid-body-inner row">
                      <div className="part-body col-2">
                        <p>Total Gross </p>
                      </div>
                      <div className="part-body col-2">
                        <p> </p>
                      </div>
                      <div className="part-body col-2">
                        <p> </p>
                      </div>
                      <div className="part-body col-2">
                        <p></p>
                      </div>
                      <div className="part-body col-2">
                        <p> </p>
                      </div>
                      <div className="part-body col-2">
                        <p>{ order?.shipment_charge + amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="bottom-outer">
                <div className="bottom-inner">
                  <div className="head">
                    <h5>Shipping From:</h5>
                  </div>
                  <div className="body">
                    <p>
                      204, Princess Business SkyPark, Opp. Orbito Mall, A.B.
                      Road, Indore
                    </p>
                    <p>
                      <span>Phone: </span>+91 81200 00506
                    </p>
                    <p>
                      <span>Email: </span>support@kanha.in
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="download-button d-flex align-items-center justify-content-center ">
              <button class="btn btn-primary" onClick={downloadPDF}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
